import $ from 'jquery';
import { Load, LoadInto, Modal } from 'laya-js';

export const initPopover = (() => {
  $(() => {
    Load.on('componentPrepared', removePopover);
    LoadInto.on('componentPrepared', removePopover);
    Modal.on('componentPrepared', removePopover);
    $(document).on('click','[data-toggle="popover"]',(e)=>{
      $(e.currentTarget).popover({
        container: 'body',
        sanitize: false,
        trigger: 'manual'
      }).popover('toggle');

    });
  });
  let removePopover = () => {
    $("[data-toggle='popover']").popover('dispose');
  };
})();
