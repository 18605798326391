import { Load, SaveAndLoad, Container, LoadInto } from 'laya-js';
import _ from 'lodash';
import Navigo from 'navigo';
import React from 'react';
import ReactDOM from 'react-dom';

const transEndEventNames = {
	WebkitTransition: 'webkitTransitionEnd',
	MozTransition: 'transitionend',
	transition: 'transitionend',
};
class Ajax {
	constructor() {
		this.timeout = null;
		this.activeConnection = null;
		Load.on('componentPrepared', this.prepareLoad.bind(this));
		Load.on('componentRendered', this.renderLoad.bind(this));
		LoadInto.on('componentPrepared', this.prepareLoadInto.bind(this));
		LoadInto.on('componentRendered', this.renderLoadInto.bind(this));
		LoadInto.on('componentDestroyed', this.destroyedLoadInto.bind(this));
		SaveAndLoad.on('componentPrepared', this.prepareSaveAndLoad.bind(this));
		SaveAndLoad.on('componentRendered', this.renderSaveAndLoad.bind(this));

		let AjaxController = Container.get('AjaxController');
		AjaxController.on('beforeSend', this.showLoader.bind(this));
		AjaxController.on('success', this.hideLoader.bind(this));
		AjaxController.on('error', this.hideLoader.bind(this));
	}
	formatParam(parameters, saveAndLoad = false) {
		let params = _.clone(parameters);
		let param = '';
		if (_.isPlainObject(params)) {
			param = params.load;
		} else {
			if (saveAndLoad === false) {
				param = params[0];
			} else {
				param = params[1];
			}
		}
		return param;
	}
	generateUrl(url, param) {
		return url + '/?_action=load&_param0=' + param;
	}
	prepareLoadInto(methods) {
		let param = this.formatParam(methods._actions[0].params);
		if(methods._actions[0].rawUrl.indexOf('doNotRefresh') != -1) return;
		$(`#${param}`)
			.children()
			.each((key, el) => {
				$(el)
					.addClass('loadAnimation')
					.removeClass('loadAnimation-show')
					.addClass('loadAnimation-hide');
				var transEndEventName = transEndEventNames[Modernizr.prefixed('transition')];
				$(el).one(transEndEventName, function(e) {
					$(this).hide();
				});
			});
	}
	renderLoadInto(methods) {
		if(methods._actions[0].rawUrl.indexOf('doNotRefresh') != -1) return;
		if ($(methods.el).hasClass('loadAnimation')) {
			$(methods.el)
				.removeClass('loadAnimation-hide')
				.addClass('loadAnimation-show');
		} else {
			$(methods.el)
				.addClass('loadAnimation')
				.removeClass('loadAnimation-hide')
				.addClass('loadAnimation-show');
		}
	}
	destroyedLoadInto(methods, def, domevent, instance) {
		$(instance.el)
			.removeClass('loadAnimation-show')
			.addClass('loadAnimation-hide');

		var transEndEventName = transEndEventNames[Modernizr.prefixed('transition')];
		let param = this.formatParam(instance.params);
		$(`#${param}`)
			.children()
			.each((key, el) => {
				if (!$(instance.el).is(el)) {
					let element = $(el)[0];
					element.classList.remove('loadAnimation');
					element.classList.remove('loadAnimation-hide');
					element.offsetWidth;
					element.classList.add('loadAnimation-show');
					element.classList.add('loadAnimation');

					//Hack
					setTimeout(() => {
						$(el).show();
					}, 500);
				}
			});
	}
	prepareLoad(methods) {
		if(methods._actions[0].rawUrl.indexOf('doNotRefresh') != -1) return;
		let param = this.formatParam(methods._actions[0].params);

		if ($(`#${param}`).hasClass('loadAnimation')) {
			$(`#${param}`)
				.removeClass('loadAnimation-show')
				.addClass('loadAnimation-hide');
		} else {
			$(`#${param}`)
				.addClass('loadAnimation')
				.removeClass('loadAnimation-show')
				.addClass('loadAnimation-hide');
		}
	}
	renderLoad(methods) {
		if(methods.hasOwnProperty('rawUrl') && methods.rawUrl.indexOf('doNotRefresh') != -1) return;
		let param = this.formatParam(methods.params);
		if ($(`#${param}`).hasClass('loadAnimation')) {
			$(`#${param}`)
				.removeClass('loadAnimation-hide')
				.addClass('loadAnimation-show');
		}

	}
	prepareSaveAndLoad(methods) {
		let param = this.formatParam(methods._actions[0].params, true);

		if ($(`#${param}`).hasClass('loadAnimation')) {
      $(`#${param}`)
				.removeClass('loadAnimation-show')
				.addClass('loadAnimation-hide');
		} else {
			$(`#${param}`)
				.addClass('loadAnimation')
				.removeClass('loadAnimation-show')
				.addClass('loadAnimation-hide');
		}
	}
	renderSaveAndLoad(methods) {
		let param = this.formatParam(methods.params, true);
		if ($(`#${param}`).hasClass('loadAnimation')) {
			$(`#${param}`)
				.removeClass('loadAnimation-hide')
				.addClass('loadAnimation-show');
		}
	}
	showLoader(xhr, settings, methods) {
		let actionUrl = methods._actions[0].rawUrl;
		//Dont add loader when param has disableLoader
		if (actionUrl.indexOf('disableLoader') != -1) return false;
		$('.appLoader').addClass('appLoader-active');
		if(this.activeConnection == null) {
			this.activeConnection = methods._actions[0].rawUrl;
		}
	}
	hideLoader(methods) {
		let canRemove = false;
		if(methods.hasOwnProperty('_actions') && this.activeConnection != null) {
			_.each(methods._actions, (action,key) => {
				if(action.hasOwnProperty('rawUrl') && action.rawUrl == this.activeConnection) {
					canRemove = true;
					return false;
				}
			});
		}
		else {
			canRemove = true;
		}
		if(canRemove) {
			$('.appLoader').removeClass('appLoader-active');
			this.activeConnection = null;
		}
		
	}
}

export default Ajax;
