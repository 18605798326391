import $ from "jquery";

const EL = '[data-fill-customer-text="input"]';
const EL_LENGTH = '[data-fill-customer-text="length"]';

export const fillCustomer = (()=>{
    $(document).on('input', EL, (ev)=>{
        const el = $(ev.currentTarget);
        const currentLength = el.val().length;
        $(EL_LENGTH).text(currentLength);
    });
})();