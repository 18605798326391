import { Laya, Container } from 'laya-js';
import _ from "lodash";
import { data } from 'jquery';


/**
 * setSelectOptions - Sets options
 * @extends Laya.ActionAjax
 */

const INPUT_SLOT_ID = '[data-order-simple-slot-id="true"]';
const INPUT_SLOT_TEMPLATE_ID = '[data-order-simple-slot-template-id="true"]';
const INPUT_SLOT_HASHED_ID = '[data-order-simple-slot-hashed-id="true"]';
const INPUT_SERVICE_ID = '[data-order-simple-service-id="true"]';
const INPUT_CUSTOMER_ID = '[data-order-simple-customer-id="true"]';
const INPUT_START_DATETIME = '[data-order-simple-start-datetime="true"]';
const INPUT_MINUTES = '[data-order-simple-minutes="true"]';

const INPUT_DATE_REFRESH = '[data-order-simple-slot-date="refresh"]';
const INPUT_SELECT_REFRESH = '[data-order-simple-slot-select="refresh"]';
const INPUT_SUBMIT_REFRESH = '[data-order-simple-slot-submit="refresh"]';

const MAX_STEP = 4;

const GET_STEP = (type) => $(`[data-order-simple-step="${type}"]`);
const GET_RESULT_NAME = (type) => $(`[data-order-simple-result-name="${type}"]`);

class OrderSimpleHandler {
    
    constructor() {
        $(document).on("change",INPUT_DATE_REFRESH, this._handleDateCalendar.bind(this));
    }
    _handleDateCalendar(ev) {
        $(INPUT_SUBMIT_REFRESH).click();
        const select = $(INPUT_SELECT_REFRESH);
        if(select.length) {
            select.find("option:first").attr("selected","selected").trigger("change");
        }
    }
    _initElements() {
        if(!_.isUndefined(this.$slotId)) return;
        this.$slotId = $(INPUT_SLOT_ID);
        this.$slotTemplateId = $(INPUT_SLOT_TEMPLATE_ID);
        this.$slotHashedId = $(INPUT_SLOT_HASHED_ID);
        this.$serviceId = $(INPUT_SERVICE_ID);
        this.$customerId = $(INPUT_CUSTOMER_ID);
        this.$startDatetime = $(INPUT_START_DATETIME);
        this.$minutes = $(INPUT_MINUTES);
        return this;
    }
    _setResultName(type, name) {
        $(GET_RESULT_NAME(type)).html(name);
        return this;
    }
    _closeModal() {
        $("#laya-modal").modal("hide");
        return this;
    }
    _replaceUrl(url, params) {
        if(params.hasOwnProperty("slotHashedId")) {
            url = url.replace("__SLOT_HASHEDID__", params.slotHashedId);
        }
        if(params.hasOwnProperty("minutes")) {
            url = url.replace("__MINUTES__", params.minutes);
        }
        return url;
        
    }
    _nextStep(num, dataUrl, openNext=false) {
        
        const step = GET_STEP(num);
        let url = step.data("href");
        if(_.isObject(dataUrl)) {
            _.each(dataUrl, (val,key) => {
                url = url.replace(key, val);
            });
            
            step.attr("href",url);
        }
        step.removeClass("disabled");
        if(openNext) {
            step.click();
        }
    }
    _resetSteps(currentStep) {
        for(let i = currentStep+1;i<=MAX_STEP;i++) {
            const step = GET_STEP(i);
            if(!step.hasClass("disabled")) {
                step.addClass("disabled");
                switch (i) {
                    case 2:
                        this._resetService();
                        break;
                    case 3:
                        this._resetTime();
                        break;
                }
            }
        }
    }
    _resetService() {
        this._setResultName("service", "");
        this.$serviceId.val(0);
        this.$minutes.val(0);
    }
    _resetTime() {
        this._setResultName("time", "");
        this.$startDatetime.val(0);
    }
    _resetCustomer() {
        this._setResultName("customer", "");
        this.$customerId.val(0);
    }
    processData(type, data) {
        this._initElements();
        switch (type) {
            case "slot":
                this.handleSlot(type, data);
                break;
            case "service":
                this.handleService(type, data);
                break;
            case "time":
                this.handleTime(type, data);
                break;
            case "customer":
                this.handleCustomer(type, data);
                break;
            default:
                break;
        }
        return this;
    }
    handleSlot(type, data) {
        this._resetSteps(1);
        this._setResultName(type, data.name);
        
        this.$slotId.val(data.id);
        this.$slotTemplateId.val(data.templateId);
        this.$slotHashedId.val(data.hashedId);
        this._closeModal();
        //console.log(this.$slotId.val());
        let openNext = false;
        if(data.next == 1) {
            openNext = true;
        }
        this._nextStep(2, {"__SLOT_HASHEDID__":data.hashedId}, openNext);
        return this;
    }

   
    handleService(type, data) {
        this._resetSteps(2);
        this.$serviceId.val(data.id);
        let minutes = data.minutes;
        if(!_.isEmpty(data.customMinutes)) {
            minutes = data.customMinutes;
        }
        
        this.$minutes.val(minutes);
        this._setResultName(type, data.name + "/" + minutes + "min");
        this._closeModal();
        
        let openNext = false;
        if(data.next == 1) {
            openNext = true;
        }
        this._nextStep(3, {"__SLOT_HASHEDID__":this.$slotHashedId.val(), "__MINUTES__":minutes}, openNext);
        return this;
    }
    handleTime(type, data) {
        if(data.hasOwnProperty("refresh")) {
            this._setResultName(type, "");    
            this._closeModal();
            this._resetSteps(1);
            this._nextStep(1,null, true);
            return this;
        }
        else {
            this._setResultName(type, data.name);
            this.$startDatetime.val(data.id);
            this._closeModal();
            GET_STEP("last").collapse("show");
            return this;
        }
        
    }
    handleCustomer(type, data) {
        this._setResultName(type, data.name);
        this.$customerId.val(data.id);
        this._closeModal();
        let openNext = false;
        if(data.next == 1) {
            openNext = true;
        }
        this._nextStep(1, null, openNext);
        return this;
    }
 
}
let orderSimpleHandlerInstance = null;
$(()=>{
    orderSimpleHandlerInstance = new OrderSimpleHandler;
});

class OrderSimple extends Laya.ActionAjax {
	constructor(...args) {
        //console.log(args);
		super(...args);
    }
	render(requestResult) {
        //console.log(requestResult);
		if(requestResult.hasOwnProperty("data")) {
            
            _.each(requestResult.data, (process, key)=>{
                try {
                    
                    orderSimpleHandlerInstance.processData(key, process);    
                } catch (error) {
                    console.warn(error);
                }
                
            });
        }
        super.render(requestResult);
    }
    
}



Container.register('OrderSimple', OrderSimple);

export default OrderSimple;
