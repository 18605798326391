import "jquery-validation";
import $ from "jquery";
//https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo
export const ruleIc = (() => {
  $.validator.addMethod(
    "ic",
    function(value, element) {
      if (this.optional(element)) {
        return this.optional(element);
      } else {
        if (!/^\d{8}$/.test(value)) {
          return false;
        }

        let a = 0;
        value = value
          .toString()
          .split("")
          .map(Number);
        let c = 0;
        for (let i = 0; i < 7; i++) {
          a += parseInt(value[i]) * (8 - i);
        }

        a = a % 11;
        if (a === 0) {
          c = 1;
        } else if (a === 1) {
          c = 0;
        } else {
          c = 11 - a;
        }

        return parseInt(value[7]) === c;
      }
    },
    "Zadejte správný formát IČ"
  );
})();
//https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo
export const ruleBirthNumber = (() => {
  $.validator.addMethod(
    "birthnumber",
    function(value, element) {
      if (this.optional(element)) {
        return this.optional(element);
      } else {
        if(!$(element).hasClass("birthnumber-validate")) {
          return true;
        }
        value = value.toString();
        if (value.length == 0 || value.length < 9) {
          return false;
        }
        let year = parseInt(value.substr(0, 2), 10);
        let month = parseInt(value.substr(2, 2), 10);
        let day = parseInt(value.substr(4, 2), 10);
        if (value.length == 9 && year < 54) return true;
        let c = 0;
        if (value.length == 10) c = parseInt(value.substr(9, 1));
        let m = parseInt(value.substr(0, 9)) % 11;
        if (m == 10) m = 0;
        if (m != c) return false;
        year += year < 54 ? 2000 : 1900;
        if (day > 50) day -= 50;
        if (month > 70 && year > 2003) month -= 70;
        else if (month > 50) month -= 50;
        else if (month > 20 && year > 2003) month -= 20;
        let d = new Date();
        if (
          year > d.getFullYear() ||
          month == 0 ||
          month > 12 ||
          day == 0 ||
          day > 31
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    "Zadejte správný formát rodného čísla"
  );
})();
export const ruleZip = (() => {
  $.validator.addMethod(
    "zip",
    function(value, element) {
      return this.optional(element) || /^\d{5}$/.test(value);
    },
    "Zadejte správný formát PSČ (např. 10000)"
  );
})();
export const ruleFloat = (() => {
  $.validator.addMethod(
    "float",
    function(value, element) {
      return this.optional(element) || /^[0-9.]+$/.test(value);
    },
    "Zadejte správný formát čísla (desetinná čísla zadávejte pomocí tečky)"
  );
})();
export const rulePhone = (() => {
  $.validator.addMethod(
    "phone",
    function(value, element) {
      return this.optional(element) || /^\+[1-9]{1}[0-9]{6,14}$/.test(value);
    },
    "Zadejte správný formát telefonu bez mezer a s mezinárodní předvolbou (např +420777111333"
  );
})();
export const ruleBirthdate = (() => {
  $.validator.addMethod(
    "birthdate",
    function(value, element) {
      return (
        this.optional(element) ||
        /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)[0-9]{2}$/.test(
          value
        )
      );
    },
    "Zadejte správný formát data narození ve správném formátu, např. (31.01.1970)"
  );
})();
export const ruleDiagnosis = (() => {
  $.validator.addMethod(
    "diagnosis",
    function(value, element) {
      return value.length > 3;
    },
    "Vyberte specifikaci diagnózy"
  );
})();
export const ruleSelect = (() => {
  $.validator.addMethod(
    "select",
    function(value, element) {
      if(element.hasAttribute('required')) {
        if (value == "null" || value == "_NULL") {
          return false;
        } else {
          return true;
        }
      }
      else {
        return true;
      }
    },
    "Vyberte položku"
  );
})();

export const ruleNoDoubleSpaces = (() => {
  $.validator.addMethod(
    "nodoublespaces",
    function(value, element) {
      //Nornalize
      //https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return (
        this.optional(element) ||
        /^([a-zA-Z-\\.]+\s)*[a-zA-Z0-9-\\.]+$/.test(value)
      );
    },
    "Zadejte správný formát bez speciálních znaků a dvojitých mezer"
  );
})();
export const ruleNoDoubleSpacesWithSpecialCharacters = (() => {
  $.validator.addMethod(
    "nodoublespaceswithspecialcharacters",
    function(value, element) {
      //Nornalize
      //https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return (
        this.optional(element) ||
        /^(?![\s])(?!.*  ).+/.test(value)
      );
    },
    "Zadejte správný formát bez dvojitých mezer"
  );
})();
