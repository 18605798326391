import $ from 'jquery';
import _ from 'lodash';

const BTN = '[data-service-view-reason]';

const topBar = (() => {
  $(() => {
    const el = $('[data-service-view-reason="user-default"]');
    if (el.length == 0) return;
    let data = localStorage.getItem('activeService');
    if(data == "undefined") {
      localStorage.setItem('activeService', JSON.stringify(el.data()));
      data = localStorage.getItem('activeService');
    }
    
    const obj = JSON.parse(data);
    if (localStorage.getItem('activeService') && $("[data-service-id='" + obj.serviceId + "']").length > 0) {
      $('[data-service-id]').removeClass('btn-primary').addClass('btn-outline-primary');
      $("[data-service-id='" + obj.serviceId + "']")
        .removeClass('btn-outline-primary')
        .addClass('btn-primary');
    } else {
      if (!_.isNull(obj)) {
        $('[data-service-id]').removeClass('btn-primary').addClass('btn-outline-primary');
        el.removeClass('btn-outline-primary').addClass('btn-primary');
      }

      localStorage.setItem('activeService', JSON.stringify(el.data()));
    }
    
  });
  $(document).on('click', BTN, ev => {
    ev.preventDefault();
    const el = $(ev.currentTarget);
    $('[data-service-id]').removeClass('btn-primary').addClass('btn-outline-primary');
    el.removeClass('btn-outline-primary').addClass('btn-primary');
    localStorage.setItem('activeService', JSON.stringify(el.data()));
    if ($(window).width() < 1024) {
      $('#services-top-bar').hide();
    }
  });
})();

export default topBar;
