import $ from 'jquery';
import ProgresBar from 'progressbar.js';

const SELECTOR = '#progressBarHandlerComponent';
const SELECTOR_MODAL_BODY = '#progressBarResultsBody';
const BAR_HIDE_TIMEOUT = 2000;
const MODAL_RESULTS = `<div class="modal fade" id="progressBarResults" tabindex="-1" role="dialog">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Výsledek:</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" id="progressBarResultsBody">
    </div>
  </div>
</div>
</div>`;

const RELOAD_EL = '[data-reload-submission="true"]';

/**
 * Progressbar UI that is primarily called by socket class; updates progress and displays the final result list of the progress
 * 
 * @class ProgressBarHandler
 */
class ProgressBarHandler {
  constructor() {
    this.bar = null;
    this.activeBar = null;
    this.resultModal = null;
  }
  /**
   * creates instances of ProgressBar line
   *
   * @param {string} identifier
   * @returns {object} this
   * @memberof ProgressBarHandler
   */
  create(identifier) {
    if (this.activeBar === null) {
      this.bar = new ProgresBar.Line(SELECTOR, {
        strokeWidth: 2,
        color: '#601d8c',
        trailColor: '#f0e2f9',
        text: {
          className: identifier,
          style: {
            // Text color.
            // Default: same as stroke color (options.color)
            color: '#601d8c',
            position: 'absolute',
            borderBottom: '1px solid #601d8c',
            left: '0',
            padding: '10px 0',
            textAlign: 'center',
            width: '100%',
            background: '#fff',
            margin: 0,
          },
        },
      });
      this.activeBar = identifier;
    }
    return this;
  }
  /**
   * handles socket subcribe event and formats the result
   *
   * @param {object} argskw
   * @memberof ProgressBarHandler
   */
  handleSocketCall(argskw) {
    if (argskw.action == 'progressbarStatus') {
      let percent = parseInt(argskw.percent) / 100;
      let positions = '';
      let elapsedTime = ' / ' + argskw.elapsedTime;
      let remainingTime = ' / ' + argskw.remainingTime;
      if (_.isNumber(argskw.max) && _.isNumber(argskw.pos)) {
        positions = ' (' + argskw.pos + ' z ' + argskw.max + ')';
      }
      else {
        positions = ' (' + parseInt(argskw.percent) +'%)';
      }
      let text = argskw.text + positions + elapsedTime + remainingTime;

      this.update(argskw.identifier, percent, text);
    } else if (argskw.action == 'progressbarShowResult') {
      this.showResult(argskw.data);
    }
  }
  /**
   * Creates an instance of progressbar.js if no activeBar exists
   *
   * @param {string} identifier
   * @param {int} progress
   * @param {string} text
   * @memberof ProgressBarHandler
   */
  update(identifier, progress, text) {
    this.create(identifier);

    this.bar.setText(text);
    this.bar.animate(progress, {}, () => {
      if (progress == 1) {
        setTimeout(() => {
          this.bar.destroy();
          this.bar = null;
          this.activeBar = null;
        }, BAR_HIDE_TIMEOUT);
      }
    });
  }
  /**
   * Can display results of progress in modal box, used for patient import
   *
   * @param {string} data - html
   * @memberof ProgressBarHandler
   */
  showResult(data) {
    if (this.resultModal === null) {
      this.resultModal = $(MODAL_RESULTS).appendTo('body');
    }
    this.resultModal.find(SELECTOR_MODAL_BODY).html(data);
    this.resultModal.modal('show');
    this.resultModal.on('hidden.bs.modal', () => {
      this.resultModal.modal('dispose');
      this.resultModal == null;
      if($(RELOAD_EL).length) {
        location.reload();
      }
    });
  }
}

export default new ProgressBarHandler();
