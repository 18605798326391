import $ from 'jquery';
import { Container } from 'laya-js';

const ajaxController = Container.get('AjaxController');

export const bookingPatientAdd = (() => {
  ajaxController.on('success', (result, textStatus, jqXHR) => {
    const save = _.find(result._actions, { action: 'save' });

    if (
      save &&
      save.hasOwnProperty('rawUrl') &&
      save.rawUrl.indexOf('addCustomer/true') != -1 &&
      result.hasOwnProperty('customerUpdated')
    ) {
      const el = $('.patientPart-searchPatient');
      if (el.length) {
        el.find('.autoComplete-hidden').val(result.customerUpdated.data.id);
        el.find('.autoComplete-input-group').removeClass('d-none');
        el.find('.autoComplete-label').text(result.customerUpdated.data.fullNameUnique);
      }
      const modal = $('#modalCustomer');
      if (modal.length) {
        modal.modal('hide');
        $('#calendarResourcesOrderAddFromCalendar__form__searchPatient__customerIdautocomplete').val(
          result.customerUpdated.data.fullName,
        );
        $('#calendarResourcesOrderAddFromCalendar__form__searchPatient__customerId').val(
          result.customerUpdated.data.id,
        );
      }
    }
  });
})();
