import $ from "jquery";

export const changeCollapseButton = (()=>{
    $(()=>{
        $("[data-collapse-button-active]").each((key, el)=>{
            const button = $(el);
            const target = button.data("target");
            
            console.log(target);
            $(target).on("show.bs.collapse",()=>{
                button.find("span").html(button.data("collapse-button-active"));
            });
            $(target).on("hide.bs.collapse",()=>{
                button.find("span").html(button.data("collapse-button-inactive"));
            });
        });
    });
})();