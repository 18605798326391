import { Laya, Container } from 'laya-js';
import _ from "lodash";
import $ from "jquery";



const Router = Container.get("Router");

const orderListener = '[data-laya-order-listen]';
$(document).on("ajaxComplete", (event, request) => {
    $(document).trigger("b")
    let requestResult = null;
    if(request && request.hasOwnProperty("responseJSON")) {
        requestResult = request.responseJSON;
    }

    if(requestResult && requestResult.hasOwnProperty("serviceUpdated")) {
        
        const el = $(orderListener);
        $("#laya-modal").modal("hide");
        $("#laya-modal").on("hidden.bs.modal", ()=>{
            $("#laya-modal").remove();
        });
        let url = "";
        el.each((key, row)=>{
                let newUrl = $(row).data("laya-reload-url").replace(/&amp;/g,"&");
                if(url.length>0) {
                    url +=";;"+newUrl;
                }
                else {
                    url = newUrl;
                }

        });
        if(url.length) {
            Router.navigateManualy(url);
        }
    }
    if(requestResult && requestResult.hasOwnProperty("orderUpdated")) {
        const el = $(orderListener);
        $("#laya-modal").modal("hide");
        $("#laya-modal").on("hidden.bs.modal", ()=>{
            $("#laya-modal").remove();
        });
        let url = "";
        el.each((key, row)=>{
            if(requestResult.orderUpdated.data.hasOwnProperty("id")) {
                if(!_.isUndefined($(row).data("laya-order-listen-id")) && $(row).data("laya-order-listen-id") == requestResult.orderUpdated.data.id) {
                    let newUrl = $(row).data("laya-order-listen").replace(/&amp;/g,"&");
                    if(url.length>0) {
                        url +=";;"+newUrl;
                    }
                    else {
                        url = newUrl;
                    }
                    
                }
                else if(_.isUndefined($(row).data("laya-order-listen-id"))) {
                    let newUrl = $(row).data("laya-order-listen").replace(/&amp;/g,"&");
                    if(url.length>0) {
                        url +=";;"+newUrl;
                    }
                    else {
                        url = newUrl;
                    }
                }
            }

        });
        if(url.length) {
            Router.navigateManualy(url);
        }
    }
});