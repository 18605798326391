import React, {useReducer, useState, useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import {
    Eventcalendar,
    setOptions,
    Popup,
    Datepicker,
    Button,
    toast,
    confirm,
    SegmentedGroup,
    SegmentedItem,
    CalendarPrev,
    CalendarToday,
    CalendarNext,
    localeCs, snackbar,
} from '@mobiscroll/react';
import _ from 'lodash';
import $ from 'jquery';
import {Container, Save} from 'laya-js';

import moment from 'moment';

import './form';
import './topBar';
import './autocomplete/index';

const Router = Container.get('Router');

const getStatusBg = status => {
    switch (status) {
        case 'created':
            return 'bg-primary';
        case 'approved':
            return 'bg-success';
        case 'opened':
            return '';
        case 'closed':
            return 'bg-danger';
        default:
            return '';
    }
};

const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 700,
        fullScreen: false,
        touchUi: false,
    },
};
const responsivePopupSecondary = {
    medium: {
        display: 'anchored',
        width: 250,
        fullScreen: false,
        touchUi: false,
    },
};
const handleNotification = res => {
    if (res.hasOwnProperty('notification') && res.notification.hasOwnProperty('messages')) {
        _.each(res.notification.messages, (message, type) => {
            toast({
                message: message,
                color: type,
                display: 'center',
            });
        });
    }
};

setOptions({
    locale: localeCs,
    theme: 'ios',
    themeVariant: 'light',
    dragTimeStep: 1,
});

const debounce = (fn, ms) => {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
};

function reducer(state, action) {
    return {
        start: action.start,
        end: action.end,
    };
}

function App(props) {
    const [instance, setInstance] =  useState(null);
    const [step, setStep] =  useState(props.calendarGranularity);
    const [wholeDay, setWholeDay] =  useState(false);
    const [defaultStart, setDefaultStart] =  useState(props.defaultStart);
    const [defaultEnd, setDefaultEnd] =  useState(props.defaultEnd);
    const [defaultHasWeekends, setWeekends] =  useState(
        localStorage.getItem('hasWeekends') ? JSON.parse(localStorage.getItem('hasWeekends')) : props.defaultHasWeekends,
    );

    const dayShedule = (start, end, showWholeDay = false, step) => {
        return {
            schedule: {
                type: 'day',
                timeCellStep: step,
                allDay: false,
                timeLabelStep: step,
                startTime: showWholeDay == true ? '00:00' : start,
                endTime: showWholeDay == true ? '23:59' : end,
            },
        };
    };
    const showUserCalendar = event => {
        event.preventDefault();
        $('.calendarTabs').toggle();
    };
    const showServiceSetting = event => {
        event.preventDefault();
        $('#services-top-bar').toggle();
    };

    const monthCalendar = {
        calendar: {labels: true},
    };
    const weekSchedule = (start, end, hasWeekends, showWholeDay = false, step) => {
        return {
            schedule: {
                type: 'week',
                startDay: 1,
                startTime: showWholeDay == true ? '00:00' : start,
                endTime: showWholeDay == true ? '23:59' : end,
                endDay: hasWeekends == true ? 0 : 5,
                timeCellStep: step,
                allDay: false,
                timeLabelStep: step,
            },
        };
    };
    const responsiveDay = (start, end, showWholeDay = false, step) => {
        return {
            xsmall: {
                view: dayShedule(start, end, showWholeDay, step),
            },
            large: {
                view: dayShedule(start, end, showWholeDay, step),
            },
        };
    };
    const responsiveMonth = (start, end, showWholeDay = false) => {
        return {
            xsmall: {
                view: dayShedule(start, end, showWholeDay),
            },
            large: {
                view: monthCalendar,
            },
        };
    };

    const responsiveWeek = (start, end, hasWeekends, showWholeDay = false, step) => {
        return {
            xsmall: {
                view: dayShedule(start, end, showWholeDay, step),
            },
            large: {
                view: weekSchedule(start, end, hasWeekends, showWholeDay, step),
            },
        };
    };

    const initialDatesFromTo = {
        start: 0,
        end: 0,
    };

    const [view, setView] = _.isNull(localStorage.getItem('calView'))
        ?  useState('week')
        :  useState(localStorage.getItem('calView'));
    const [tempEvent, setTempEvent] =  useState(null);
    const [myHeight, setMyHeight] =  useState(null);
    const [openPicker, setOpenPicker] =  useState(false);
    const [myEvents, setMyEvents] =  useState([]);
    const [isOpen, setOpen] =  useState(false);
    const [confirmEventMove, setConfirmEventMove] =  useState(false);
    const [isOpenSecondary, setOpenSecondary] =  useState(false);
    const [anchor, setAnchor] =  useState(null);
    const [popupEventData, setPopupData] =  useState({});
    const start = _.isNull(localStorage.getItem('calendarStart'))
        ? moment()
        : moment(localStorage.getItem('calendarStart'));
    const [mySelectedDate, setSelectedDate] =  useState(start);
    const [datesFromTo, dispatch] = useReducer(reducer, initialDatesFromTo);

    const [slotColors, setSlotColors] = useState([])

    const loadPopup = React.useCallback(event => {
        event.start = moment(event.start);
        event.end = moment(event.end);
        setPopupData(event);
    }, []);

    const onClose = React.useCallback(() => {
        setOpen(false);
        setPopupData(null);
    }, []);
    const onCloseSecondary = React.useCallback(() => {
        setOpenSecondary(false);
        setPopupData(null);
    }, []);

    const [calView, setCalView] =  useState(weekSchedule);

    const [responsiveView, setResponsiveView] =  useState(
        responsiveWeek(defaultStart, defaultEnd, defaultHasWeekends, step),
    );

    // scheduler options

    const onSelectedDateChange = React.useCallback(event => {
        setSelectedDate(moment(event.date));
    }, []);

    const onEventClick = React.useCallback(
        args => {
            setOpen(true);
            loadPopup(args.event);
            setAnchor(args.domEvent.target);
            //const url = props.editEventUrl.replace("__ID__", args.event.id).replace("__USER_ID__", props.userId);
            //setTempEvent(args.event);
            //Router.navigateManualy(url);
            // fill popup form with event data
        },
        [loadPopup],
    );

    const onEventDeleted = React.useCallback(args => {
    });

    const onEventCreated = React.useCallback(args => {
        const activeService = JSON.parse(localStorage.getItem('activeService'));
        const start = moment(args.event.start);
        const end = moment(args.event.end);

        const url = props.newEventUrl
            .replace('__FROM__', start.unix())
            .replace('__TO__', end.unix())
            .replace('__USER_ID__', props.userId)
            .replace('__SERVICE_ID__', activeService.serviceId);
        setTempEvent(args.event);
        Router.navigateManualy(url);

        const checkCollision = props.checkColisionUrl
            .replace('__FROM__', start.unix())
            .replace('__TO__', end.unix())
            .replace('__USER_ID__', props.userId);
        $.getJSON(checkCollision, res => {
            if (res.hasOwnProperty('data') && _.size(res.data) > 0) {
                toast({
                    message: 'Vytváříte kolidující objednávku',
                    color: 'danger',
                    display: 'center',
                });
            }
            handleNotification(res);
        });
    });

    const onEventCreate = React.useCallback((args, inst) => {
        const activeService = JSON.parse(localStorage.getItem('activeService'));
        const startDay = args.event.start.getDay();
        const endDay = args.event.end.getDay();

        if (args.action == 'click') {
            let end = moment(args.event.start).add(activeService.serviceLength, 'minutes');
            args.event.end = end;
        } else {
            let end = moment(args.event.end);
        }

        if (inst.props.responsive.large.view.hasOwnProperty('schedule') == false) {
            setTimeout(() => {
                setView('day');
                setResponsiveView(responsiveDay(defaultStart, defaultEnd, wholeDay, step));
                localStorage.setItem('calView', 'day');
            }, 100);

            return false;
        }
        if (startDay != endDay) {
            toast({
                message: 'Není možné vytvářet objednávku na více dnů',
                color: 'danger',
            });
            return false;
        }
    });
    const onEventRightClick = React.useCallback(
        (args, inst) => {
            args.domEvent.preventDefault();
            setOpenSecondary(true);
            setAnchor(args.domEvent.target);
            loadPopup(args.event);
        },
        [loadPopup],
    );

    const onEventUpdate = React.useCallback(
        args => {
            const startDay = args.event.start.getDay();
            const endDay = args.event.end.getDay();
            if (startDay != endDay) {
                toast({
                    message: 'Není možné upravovat objednávku na více dnů',
                    color: 'danger',
                });
                return false;
            }
            setConfirmEventMove(args)
        },
        [myEvents],
    );
    const resetEvent = args => {
        const index = myEvents.findIndex(x => x.id === args.event.id);
        const newEventList = [...myEvents];
        newEventList.splice(index, 1, args.oldEvent);
        setMyEvents(newEventList);
    };
    const updateEvent = async args => {
        let start = moment(args.event.start);
        let end = moment(args.event.end);
        let url = props.changeDateEventUrl
            .replace('__ID__', args.event.id)
            .replace('__USER_ID__', props.userId)
            .replace('__FROM__', start.unix())
            .replace('__TO__', end.unix());
        if (args?.skipNotifications) {
            url = `${url}?skipNotifications=1`
        }
        $('.appLoader').addClass('appLoader-active');
        return $.post(
            url,
            {},
            res => {
                $('.appLoader').removeClass('appLoader-active');
                if (
                    res.hasOwnProperty('error') ||
                    (res.hasOwnProperty('notification') && res.notification.messages.hasOwnProperty('warning'))
                ) {
                    resetEvent(args);
                    toast({
                        message: 'Je nám líto, ale objednávku se nám nepodařilo upravit',
                        color: 'danger',
                        display: 'center',
                    });
                }
                handleNotification(res);
            },
            'json',
        );
    };

    const renderScheduleEvent = React.useCallback(data => {
        let small = false;
        let start = data.original.start instanceof moment ? data.original.start : moment(data.original.start);
        let end = data.original.end instanceof moment ? data.original.end : moment(data.original.end);
        if (data.hasOwnProperty('original') && data.original.hasOwnProperty('end')) {
            let duration = moment.duration(end.diff(start));
            if (duration.asMinutes() < 5) {
                small = true;
            }
        }
        return (
            <div className="md-custom-event-cont"
                 style={{borderColor: data.original.hasOwnProperty('optionalData') && data.original.optionalData.hasOwnProperty('color') ? data.original.optionalData.color.background : ''}}
                 data-event={JSON.stringify(data.original)}>
                <div className="md-custom-event-wrapper">
                    {small == false && data.original.hasOwnProperty('optionalData') && (
                        <div className="md-custom-event-category text-dark"
                             style={{background: data.original.hasOwnProperty('optionalData') && data.original.optionalData.hasOwnProperty('color') ? data.original.optionalData.color.backgroundService : ''}}>{data.original.optionalData.service.name}
                            {data.original.hasOwnProperty('optionalData') && data.original.optionalData.hasOwnProperty('color') && data.original.optionalData.color.star.map((row, index) =>
                                <span key={index} className={'p-1'}><i style={{color: row}}
                                                                       className="fa fa-star"/></span>)}
                            {data.original.hasOwnProperty('optionalData') && data.original.optionalData.hasOwnProperty('color') && data.original.optionalData.color.circle.map((row, index) =>
                                <span key={index} className={'p-1'}><i style={{color: row}}
                                                                       className="fa fa-circle"/></span>)}
                        </div>
                    )}
                    <div className="md-custom-event-details">
                        <div className="md-custom-event-title"
                             style={{background: data.original.hasOwnProperty('optionalData') && data.original.optionalData.hasOwnProperty('color') ? data.original.optionalData.color.backgroundCustomer : ''}}>{data.title}</div>
                        {small == false && (
                            <div className="md-custom-event-time">
                                {data.start} - {data.end}
                            </div>
                        )}
                        {small == false &&
                            data.original.hasOwnProperty('optionalData') &&
                            data.original.optionalData.hasOwnProperty('show') &&
                            data.original.optionalData.show.status == 'yes' && (
                                <div
                                    className={`${getStatusBg(data.original.optionalData.status.name)} md-custom-event-category`}>
                                    {data.original.optionalData.status.label}
                                </div>
                            )}
                        {small == false &&
                            data.original.hasOwnProperty('optionalData') &&
                            data.original.optionalData.hasOwnProperty('employee') && (
                                <div className='md-custom-event-category mt-1'>
                                    {data.original.optionalData.employee.name}
                                </div>
                            )}
                        {small == false &&
                            data.original.hasOwnProperty('optionalData') &&
                            data.original.optionalData.hasOwnProperty('show') &&
                            data.original.optionalData.show.phone == 'yes' && (
                                <div className="md-custom-event-time">{data.original.optionalData.customer.phone}</div>
                            )}
                        {small == false &&
                            data.original.hasOwnProperty('optionalData') &&
                            data.original.optionalData.hasOwnProperty('show') &&
                            data.original.optionalData.show.email == 'yes' && (
                                <div className="md-custom-event-time">{data.original.optionalData.customer.email}</div>
                            )}
                        {small == false &&
                            data.original.hasOwnProperty('optionalData') &&
                            data.original.optionalData.hasOwnProperty('show') &&
                            data.original.optionalData.show.customerIdentifier == 'yes' && (
                                <div
                                    className="md-custom-event-time">{data.original.optionalData.customer.customerIdentifier}</div>
                            )}
                        {small == false &&
                            data.original.hasOwnProperty('optionalData') &&
                            !_.isNull(data.original.optionalData.employeeComment) > 0 && (
                                <div className="md-custom-event-time">{data.original.optionalData.employeeComment}</div>
                            )}
                        <div className={'d-flex align-items-center'}>

                        </div>
                    </div>
                </div>
            </div>
        );
    });


    const onPageLoading = React.useCallback(
        (event, inst) => {
            const start = event.firstDay.getTime() / 1000;
            const end = event.lastDay.getTime() / 1000;
            if (inst.props.responsive.large.view.hasOwnProperty('schedule') == true) {
                localStorage.setItem('calendarStart', moment(event.viewStart));
            }
            dispatch({
                start: start,
                end: end,
            });
            const url = props.fetchEventsUrl
                .replace('__FROM__', start)
                .replace('__TO__', end)
                .replace('__USER_ID__', props.userId);
            loadEvents(url, inst);
            console.log(event.firstDay, event.lastDay)
            const slotsBackgroundUrl = props.slotsBackgroundUrl.replace('__FROM__', start).replace('__TO__', end).replace('__USER_ID__', props.userId);
            $.getJSON(slotsBackgroundUrl, res => {
                if (res.hasOwnProperty('data')) {
                    setSlotColors(res.data);
                }
            });
        },
        [myEvents],
    );
    /*
    const onPageLoaded = React.useCallback(
        (event, inst) => {
            const start = event.firstDay.getTime() / 1000;
            const end = event.lastDay.getTime() / 1000;
            dispatch({
                start: start,
                end: end,
            });
        },
        [],
    );
     */

    const loadEvents = (url, inst) => {
        $('.appLoader').addClass('appLoader-active');
        $.getJSON(url, result => {
            let arr = [];
            handleNotification(result);
            if (result.hasOwnProperty('data')) {
                let first = parseInt(defaultStart.split(':')[0]);
                let last = parseInt(defaultEnd.split(':')[0]);
                let tmpHasWeekends = _.isNull(localStorage.getItem('hasWeekends'))
                    ? false
                    : JSON.parse(localStorage.getItem('hasWeekends'));
                let tmpDefaultStart = defaultStart;
                let tmpDefaultEnd = defaultEnd;
                _.each(result.data, (item, key) => {
                    const start = moment(item.startDatetime, 'X');
                    const end = moment(item.endDatetime, 'X');
                    if (tmpHasWeekends == false && start.isoWeekday() > 5) {
                        tmpHasWeekends = true;
                    }
                    arr.push({
                        id: item.id,
                        start: start,
                        end: end,
                        title: item.customer.fName,
                        optionalData: item,
                    });
                    const checkFirst = parseInt(start.format('HH:mm').split(':')[0]);
                    const checkLast = parseInt(end.format('HH:mm').split(':')[0]);
                    if (checkFirst < first) {
                        first = checkFirst;
                        tmpDefaultStart = start.format('HH:mm');
                    }
                    if (checkLast > last) {
                        last = checkLast;
                        tmpDefaultEnd = end.format('HH:mm');
                    }
                });
                setDefaultStart(tmpDefaultStart);
                setDefaultEnd(tmpDefaultEnd);
                setWeekends(tmpHasWeekends);

                setMyEvents(arr);
            } else {
                setMyEvents([]);
            }
            $('.appLoader').removeClass('appLoader-active');
        });
    };
    const onClickPopupSecondary = ev => {
        ev.preventDefault();
        setOpenSecondary(false);
        setPopupData(null);
        const activeService = JSON.parse(localStorage.getItem('activeService'));
        const start = moment(popupEventData.start);
        const end = moment(popupEventData.end);
        const url = props.newEventUrl
            .replace('__FROM__', start.unix())
            .replace('__TO__', end.unix())
            .replace('__USER_ID__', props.userId)
            .replace('__SERVICE_ID__', activeService.serviceId);
        //setTempEvent(args.event);
        Router.navigateManualy(url);
    };
    const changeView = event => {
        let calView;
        let responsiveView;
        switch (event.target.value) {
            case 'month':
                //calView = monthCalendar
                responsiveView = responsiveMonth(defaultStart, defaultEnd, wholeDay);
                break;
            case 'week':
                //calView = weekSchedule
                responsiveView = responsiveWeek(defaultStart, defaultEnd, defaultHasWeekends, wholeDay, step);
                break;
            case 'day':
                //calView = dayShedule
                responsiveView = responsiveDay(defaultStart, defaultEnd, wholeDay, step);
                break;
        }

        setView(event.target.value);
        localStorage.setItem('calView', event.target.value);
        //setCalView(calView);
        setResponsiveView(responsiveView);
    };
    const showWeekend = () => {
        let hasWeekends = !_.clone(defaultHasWeekends);
        localStorage.setItem('hasWeekends', hasWeekends);
        setWeekends(hasWeekends);
    };

    const showWholeDay = event => {
        const changeWholeDay = !_.clone(wholeDay);
        setWholeDay(changeWholeDay);
    };
    const show = () => {
        setOpenPicker(true);
    };
    const onChangeDate = (event, inst) => {
        setSelectedDate(moment(event.value));
    };
    const onClosePicker = () => {
        setOpenPicker(false);
    };
    const nullComponent = (props, ref) => {
        return <input {...props} ref={ref} type="hidden"/>;
    };
    const customWithNavButtons = () => {
        const showWeekendText = defaultHasWeekends == true ? 'Skrýt víkendy' : 'Zobrazit víkendy';
        const showWholeDayText = wholeDay == true ? 'Skrýt všechny časy' : 'Zobrazit časy od 00:00 - 23:59';
        return (
            <React.Fragment>
                <Datepicker
                    calendarType="month"
                    pages={2}
                    showOnClick={false}
                    display="anchored"
                    showOnFocus={false}
                    isOpen={openPicker}
                    onChange={onChangeDate}
                    onClose={onClosePicker}
                    inputComponent="input"
                    inputProps={{className: 'd-none'}}
                />
                <Button className="btn btn-outline-primary" onClick={show}>
                    <i className="fas fa-calendar"></i> {mySelectedDate.format('MM')}/{mySelectedDate.format('YYYY')}
                </Button>
                <div className="cal-header-picker d-none d-lg-block">
                    <SegmentedGroup value={view} onChange={changeView}>
                        <SegmentedItem value="week">Týden</SegmentedItem>
                        <SegmentedItem value="day">Den</SegmentedItem>
                        <SegmentedItem value="month">Měsíc</SegmentedItem>
                    </SegmentedGroup>
                </div>

                <div className="dropdown">
                    <a
                        className="btn btn-outline-primary mr-2 dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false">
                        <i className="fas fa-cog"></i>
                    </a>

                    <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        {view == 'week' && (
                            <li>
                                <button onClick={showWeekend} className="dropdown-item">
                                    {defaultHasWeekends == true && <i className="fas fa-times mr-1"></i>}
                                    {showWeekendText}
                                </button>
                            </li>
                        )}
                        {(view == 'week' || view == 'day') && (
                            <Fragment>
                                <li>
                                    <button onClick={showWholeDay} className="dropdown-item">
                                        {wholeDay == true && <i className="fas fa-times mr-1"></i>}
                                        {showWholeDayText}
                                    </button>
                                </li>

                                <li>
                                    <button onClick={showServiceSetting} className="dropdown-item d-lg-none">
                                        Vybrat top službu
                                    </button>
                                </li>

                                <li>
                                    <button onClick={showUserCalendar} className="dropdown-item d-lg-none">
                                        Vybrat jiný kalendář
                                    </button>
                                </li>
                            </Fragment>
                        )}
                        {props.userId > 0 &&
                            <li>
                                <a className="dropdown-item" data-laya="ajax" href={props.calendarSettingUrl}>
                                    <i className="fas fa-calendar-day"></i> Nastavení kalendáře
                                </a>
                            </li>
                        }
                        <div className="dropdown-divider"></div>
                        <li>
                            <a href={props.slotCalendarLink} target="_blank" className="dropdown-item">
                                <i className="fas fa-link"></i> Přejít na přehledový kalendář
                            </a>
                        </li>
                    </ul>
                </div>
                <CalendarPrev className="cal-header-prev calendar-button"/>
                <CalendarNext className="cal-header-next calendar-button"/>
                <CalendarToday className="cal-header-today calendar-button"/>
            </React.Fragment>
        );
    };

    const calculateHeight = () => {
        if ($('#services-top-bar').is(':visible')) {
            return $(window).height() - $('#topBarWrap').height() - $('.calendarTabs').height() - 50;
        } else {
            return $(window).height() - $('#topBarWrap').height() - 100;
        }
    };

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setMyHeight(calculateHeight());
        }, 300);

        setMyHeight(calculateHeight());

        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    useEffect(()=> {

        $(document).off('hidden.bs.modal', '#laya-modal');
        $(document).on('hidden.bs.modal', '#laya-modal', ()=> {
            const url = props.fetchEventsUrl
                .replace('__FROM__', datesFromTo.start)
                .replace('__TO__', datesFromTo.end)
                .replace('__USER_ID__', props.userId);
            loadEvents(url);
        })

    }, [datesFromTo])


    useEffect(() => {
        let responsiveView = 'week';
        switch (view) {
            case 'month':
                //calView = monthCalendar
                responsiveView = responsiveMonth(defaultStart, defaultEnd, defaultHasWeekends, step);
                break;
            case 'week':
                //calView = weekSchedule
                responsiveView = responsiveWeek(defaultStart, defaultEnd, defaultHasWeekends, wholeDay, step);
                break;
            case 'day':
                //calView = dayShedule
                responsiveView = responsiveDay(defaultStart, defaultEnd, wholeDay, step);
                break;
        }

        setResponsiveView(responsiveView);
    }, [defaultStart, defaultEnd, defaultHasWeekends, wholeDay, step]);

    useEffect(() => {
        $(document).off('ajaxComplete');
        $(document).on('ajaxComplete', (event, request) => {
            let requestResult = null;
            if (request && request.hasOwnProperty('responseJSON')) {
                requestResult = request.responseJSON;
            }

            if (requestResult && requestResult.hasOwnProperty('orderUpdated')) {
                const event = requestResult.orderUpdated.data;
                const startDate = moment(datesFromTo.start, 'X'),
                    endDate = moment(datesFromTo.end, 'X'),
                    date = moment(event.startDatetime, 'X'),
                    isBetween = date.isBetween(startDate, endDate);
                //Only reload events, if returned new event is between datesFromTo range
                if (isBetween) {
                    const url = props.fetchEventsUrl
                        .replace('__FROM__', datesFromTo.start)
                        .replace('__TO__', datesFromTo.end)
                        .replace('__USER_ID__', props.userId);
                    loadEvents(url);
                }
            }
            const orderListener = '[data-laya-reload-url]';
            if (requestResult && requestResult.hasOwnProperty('serviceUpdated')) {
                const el = $(orderListener);
                $('#laya-modal').modal('hide');
                $('#laya-modal').on('hidden.bs.modal', () => {
                    $('#laya-modal').remove();
                });
                let url = '';
                el.each((key, row) => {
                    let newUrl = $(row).data('laya-reload-url').replace(/&amp;/g, '&');
                    if (url.length > 0) {
                        url += ';;' + newUrl;
                    } else {
                        url = newUrl;
                    }
                });
                if (url.length) {
                    const calendarUrl = props.fetchEventsUrl
                        .replace('__FROM__', datesFromTo.start)
                        .replace('__TO__', datesFromTo.end)
                        .replace('__USER_ID__', props.userId);

                    loadEvents(calendarUrl);

                    $.getJSON(props.currentUrl, res => {
                        if (res.hasOwnProperty('data') && res.data.hasOwnProperty('calendarSettingGgranularity')) {
                            setStep(res.data.calendarSettingGgranularity);
                        }
                    });

                    Router.navigateManualy(url);
                }
            }
        });
    }, [datesFromTo]);



    const popupButtons = React.useMemo(() => {
        return [
            'close',
            {
                handler: ev => {
                    const url = props.copyEventUrl
                        .replace('__FROM__', popupEventData.start.unix())
                        .replace('__TO__', popupEventData.end.unix())
                        .replace('__CUSTOMER_ID__', popupEventData.optionalData.customer.id)
                        .replace('__USER_ID__', props.userId)
                        .replace('__SERVICE_ID__', popupEventData.optionalData.service.id);
                    Router.navigateManualy(url);
                    setOpen(false);
                    setPopupData(null);
                },
                keyCode: 'enter',
                text: 'Opakovat',
                cssClass: 'btn btn-outline-primary',
            },
            {
                handler: ev => {
                    const url = props.editEventUrl.replace('__ID__', popupEventData.id).replace('__USER_ID__', props.userId);
                    Router.navigateManualy(url);
                    setOpen(false);
                    setPopupData(null);
                },
                keyCode: 'enter',
                text: 'Upravit',
                cssClass: 'btn btn-outline-primary',
            },
            {
                handler: ev => {
                    const url = props.deleteEventUrl.replace('__ID__', popupEventData.id).replace('__USER_ID__', props.userId);
                    Router.navigateManualy(url);
                    setOpen(false);
                    setPopupData(null);
                },
                keyCode: 'delete',
                text: 'Storno',
                cssClass: 'btn btn-outline-primary',
            },
            {
                handler: ev => {
                    const url = props.previewEventUrl.replace('__ID__', popupEventData.id).replace('__USER_ID__', props.userId);
                    Router.navigateManualy(url);
                    setOpen(false);
                    setPopupData(null);
                },
                keyCode: 'delete',
                text: 'Detail',
                cssClass: 'btn btn-outline-danger',
            },
        ];
    }, [popupEventData]);
    return (
        <div className="md-switching-view-cont">
            {myEvents && <Eventcalendar
                ref={setInstance}
                renderHeader={customWithNavButtons}
                height={myHeight}
                //view={calView}
                colors={slotColors}
                data={myEvents}
                clickToCreate="single"
                newEventText="Nová objednávka"
                dragToCreate={true}
                responsive={responsiveView}
                dragToMove={true}
                dragToResize={true}
                datesFromTo={datesFromTo}
                renderScheduleEvent={renderScheduleEvent}
                selectedDate={mySelectedDate}
                onSelectedDateChange={onSelectedDateChange}
                onEventClick={onEventClick}
                onEventCreate={onEventCreate}
                onEventCreated={onEventCreated}
                onEventRightClick={onEventRightClick}
                onEventDeleted={onEventDeleted}
                onEventUpdate={onEventUpdate}
                onPageLoading={onPageLoading}
            />}
            <Popup
                display="center"
                closeOnOverlayClick={false}
                closeOnEsc={false}
                closeOnScroll={false}
                height={200}
                contentPadding={true}
                buttons={[
                    {
                        handler: ev => {
                            let start = moment(confirmEventMove.event.start);
                            let end = moment(confirmEventMove.event.end);
                            const checkCollision =
                                props.checkColisionUrl
                                    .replace('__FROM__', start.unix())
                                    .replace('__TO__', end.unix())
                                    .replace('__USER_ID__', props.userId) + confirmEventMove.event.id;
                            $('.appLoader').addClass('appLoader-active');
                            $.getJSON(checkCollision, res => {
                                $('.appLoader').removeClass('appLoader-active');
                                if (res.hasOwnProperty('data') && _.size(res.data) > 0) {
                                    confirm({
                                        title: 'Upozornění',
                                        message: 'Přesouváte objednávku na čas, který je již obsazen. Chcete ji skutečně přesunout?',
                                        okText: 'Ano',
                                        cancelText: 'Ne',
                                    }).then(function (result) {
                                        if (result == false) {
                                            resetEvent(confirmEventMove);
                                        } else {
                                            updateEvent(confirmEventMove);
                                        }
                                        setConfirmEventMove(undefined)
                                    });
                                } else {
                                    updateEvent(confirmEventMove);
                                    setConfirmEventMove(undefined)
                                }
                                //handleNotification(res);
                            });
                        },
                        keyCode: 'enter',
                        text: 'Ano',
                        cssClass: 'btn btn-outline-primary',
                    },
                    {
                        cssClass: 'btn btn-outline-primary',
                        text: 'Ano - neupozorňovat',
                        handler: () => {
                            let start = moment(confirmEventMove.event.start);
                            let end = moment(confirmEventMove.event.end);
                            confirmEventMove.skipNotifications = true;
                            const checkCollision =
                                props.checkColisionUrl
                                    .replace('__FROM__', start.unix())
                                    .replace('__TO__', end.unix())
                                    .replace('__USER_ID__', props.userId) + confirmEventMove.event.id;
                            $('.appLoader').addClass('appLoader-active');
                            $.getJSON(checkCollision, res => {
                                $('.appLoader').removeClass('appLoader-active');
                                if (res.hasOwnProperty('data') && _.size(res.data) > 0) {
                                    confirm({
                                        title: 'Upozornění',
                                        message: 'Přesouváte objednávku na čas, který je již obsazen. Chcete ji skutečně přesunout?',
                                        okText: 'Ano',
                                        cancelText: 'Ne',
                                    }).then(function (result) {
                                        if (result == false) {
                                            resetEvent(confirmEventMove);

                                        } else {
                                            updateEvent(confirmEventMove);
                                        }
                                        setConfirmEventMove(undefined)
                                    });
                                } else {
                                    updateEvent(confirmEventMove);
                                    setConfirmEventMove(undefined)
                                }

                                //handleNotification(res);
                            });
                        }
                    },
                    {
                        cssClass: 'btn btn-outline-danger',
                        text: 'Ne',
                        handler: () => {
                            resetEvent(confirmEventMove);
                            setConfirmEventMove(undefined)
                        },
                    }
                ]}
                isOpen={!!confirmEventMove}
                onClose={()=>setConfirmEventMove(undefined)}>
                <p><strong>Upozornění:</strong></p>
                <p><strong>Přesouváte objednávku na jiný čas, chcete objednávku přesunout a upozornit pacienta?</strong></p><p>Vyberte "neupozorňovat" pokud nehcete zaslat pacientovi notifikaci.</p>
            </Popup>
            <Popup
                display="bottom"
                fullScreen={true}
                contentPadding={true}
                buttons={popupButtons}
                anchor={anchor}
                isOpen={isOpen}
                onClose={onClose}
                responsive={responsivePopup}>
                {popupEventData && popupEventData.hasOwnProperty('start') && (
                    <div>
                        <div className="calendarOrder">
                            <div className="order-title"
                                 style={{background: popupEventData.optionalData.hasOwnProperty('color') ? popupEventData.optionalData.color.backgroundService : ''}}>{popupEventData.optionalData.service.name} {popupEventData.optionalData.hasOwnProperty('color') && popupEventData.optionalData.color.star.map((row, index) =>
                                <span key={index} className={'p-1'}><i style={{color: row}}
                                                                       className="fa fa-star"/></span>)}
                                {popupEventData.optionalData.hasOwnProperty('color') && popupEventData.optionalData.color.circle.map((row, index) =>
                                    <span key={index} className={'p-1'}><i style={{color: row}}
                                                                           className="fa fa-circle"/></span>)}</div>
                            <div className="order-time">
                                <div className="order-from">
                                    <p className="order-label">Informace</p>
                                    <p className="order-time-date">{popupEventData.start.format('DD.MM.YYYY')}</p>
                                    <p className="order-time-hour">
                                        {popupEventData.start.format('HH:mm')}{' '}
                                        <span className="badge badge-primary">
                      {(popupEventData.end.unix() - popupEventData.start.unix()) / 60} min
                    </span>
                                    </p>
                                    <div className="order-status">
                                        <p className="order-label">Další informace</p>
                                        <p className="order-text">
                                            Status:{' '}
                                            <span
                                                className="badge text-white badge-primary">{popupEventData.optionalData.status.label}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="order-to">
                                    <div className="order-patient">
                                        <p className="order-label">Pacient</p>
                                        <p className="order-customer"
                                           style={{background: popupEventData.optionalData.hasOwnProperty('color') ? popupEventData.optionalData.color.backgroundCustomer : ''}}>{popupEventData.optionalData.customer.fName}</p>
                                        <p className="order-text">{popupEventData.optionalData.customer.customerIdentifier}</p>
                                        <br/>
                                        <p className="order-email">{popupEventData.optionalData.customer.email}</p>
                                        <p className="order-phone">{popupEventData.optionalData.customer.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Popup>
            <Popup
                display="bottom"
                fullScreen={true}
                contentPadding={true}
                anchor={anchor}
                isOpen={isOpenSecondary}
                onClose={onCloseSecondary}
                responsive={responsivePopupSecondary}>
                {popupEventData && (
                    <Button className="d-block w-100" onClick={onClickPopupSecondary}>
                        Nová objednávka na
                        <br/>{' '}
                        <strong>
                            {moment(popupEventData.start).format('HH:mm')} - {moment(popupEventData.end).format('HH:mm')}
                        </strong>
                    </Button>
                )}
            </Popup>
        </div>
    );
}

$(() => {
    if ($('#calendar').length == 0) return;
    const newEventUrl = '/calendar/__USER_ID__/order/add/__SERVICE_ID__/__FROM__/__TO__?_action=modal';
    const copyEventUrl = '/calendar/__USER_ID__/order/add/__SERVICE_ID__/__FROM__/__TO__/__CUSTOMER_ID__?_action=modal';
    const editEventUrl = '/calendar/__USER_ID__/order/edit/__ID__?_action=modal';
    const deleteEventUrl = '/calendar/__USER_ID__/order/storno/__ID__?_action=modal';
    const previewEventUrl = '/calendar/__USER_ID__/order/preview/__ID__?_action=panel';
    const changeDateEventUrl = '/calendar/__USER_ID__/resources/event/by-id/__ID__/change-date/__FROM__/__TO__';
    const fetchEventsUrl = '/calendar/__USER_ID__/resources/events/by-date/__FROM__/__TO__';
    const checkColisionUrl = '/calendar/__USER_ID__/helpers/check-collisions/__FROM__/__TO__/';
    const slotsBackgroundUrl = '/calendar/__USER_ID__/resources/slots/by-date/__FROM__/__TO__';
    const calendarSettingUrl = $('#calendar').data('calendar-setting-url');
    const defaultStart = $('#calendar').data('calendar-default-start');
    const defaultHasWeekends = $('#calendar').data('calendar-default-show-weekend');
    const defaultEnd = $('#calendar').data('calendar-default-end');
    const userId = $('#calendar').data('calendar-user-id');
    const calendarGranularity = $('#calendar').data('calendar-setting-granularity');
    const currentUrl = $('#calendar').data('calendar-current-url');
    const slotCalendarLink = $('#calendar').data('calendar-slot-link');
    ReactDOM.render(
        <React.StrictMode>
            <App
                slotsBackgroundUrl={slotsBackgroundUrl}
                previewEventUrl={previewEventUrl}
                deleteEventUrl={deleteEventUrl}
                copyEventUrl={copyEventUrl}
                calendarGranularity={calendarGranularity}
                currentUrl={currentUrl}
                changeDateEventUrl={changeDateEventUrl}
                editEventUrl={editEventUrl}
                slotCalendarLink={slotCalendarLink}
                defaultStart={defaultStart}
                defaultHasWeekends={defaultHasWeekends}
                defaultEnd={defaultEnd}
                userId={userId}
                newEventUrl={newEventUrl}
                fetchEventsUrl={fetchEventsUrl}
                checkColisionUrl={checkColisionUrl}
                calendarSettingUrl={calendarSettingUrl}
            />
        </React.StrictMode>,
        document.getElementById('calendar'),
    );
    $(document)
        .on('mouseover', '.mbsc-schedule-item', ev => {
            let localdata = localStorage.getItem('activeService');
            if (localdata == 'undefined') {
                localStorage.setItem('activeService', JSON.stringify($('[data-service-view-reason="user-default"]').data()));
                localdata = localStorage.getItem('activeService');
            }
            let service = JSON.parse(localdata);
            if (service === null || !service.hasOwnProperty('serviceLength')) {
                let data = $('[data-service-view-reason="user-default"]').data();
                localStorage.setItem('activeService', JSON.stringify(data));
                service = data;
            } else {
                if (
                    parseInt($('[data-service-id="' + service.serviceId + '"]').data('service-length')) !=
                    parseInt(service.serviceLength)
                ) {
                    let data = $('[data-service-id="' + service.serviceId + '"]').data();
                    localStorage.setItem('activeService', JSON.stringify(data));
                    service = data;
                }
            }
            const index = $(ev.currentTarget)
                .parent('.mbsc-schedule-column-inner')
                .find('.mbsc-schedule-item')
                .index(ev.currentTarget);
            const text = $('.mbsc-schedule-time-wrapper').eq(index).find('.mbsc-schedule-time:first-child').text();
            const time = text.split(':');
            let start = moment();
            start.set({
                hour: time[0],
                minute: time[1],
            });
            let end = start.clone();

            end.add(service.serviceLength, 'minutes');

            $(ev.currentTarget)
                .tooltip({
                    title:
                        'Vytvořit objednávku:<br><strong>' +
                        start.format('HH:mm') +
                        ' - ' +
                        end.format('HH:mm') +
                        '</strong><br>' +
                        service.serviceName,
                    placement: 'bottom',
                    html: true,
                })
                .tooltip('show');
        })
        .on('mouseleave', '.mbsc-schedule-item', ev => {
            $(ev.currentTarget).tooltip('dispose');
        });
});
