import $ from 'jquery';

const BUTTON = '[data-activate="true"]';

export const activateLink = (() => {
  $(document).on("click",BUTTON,(ev)=>{
    let target = $(ev.currentTarget);
    target.siblings().removeClass("active");
    target.addClass("active");
  });
})();
