import $ from 'jquery';
import {
  Load,
  LoadInto,
  Modal
} from 'laya-js';

import Panel from "../handler/panel";

export const initTooltip = (() => {
  $(() => {
    callTooltips();
    Load.on('componentPrepared', removeTooltips);
    Load.on('componentRendered', callTooltips);
    LoadInto.on('componentPrepared', removeTooltips);
    LoadInto.on('componentRendered', callTooltips);
    Modal.on('componentPrepared', removeTooltips);
    Modal.on('componentRendered', callTooltips);
    Panel.on('componentPrepared', removeTooltips);
    Panel.on('componentRendered', callTooltips);
  });
})();
export const removeTooltips = () => {
  $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip('dispose');
};
export const callTooltips = () => {
  $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
};