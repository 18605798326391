export const serviceGroupFetchData = data => ({
    serviceGroup: data,
    type: "SERVICE_GROUP_FETCH_DATA"
});
export const serviceFetchData = data => ({
    service: data,
    type: "SERVICE_FETCH_DATA"
});
export const resourcesFetchData = data => ({
    resources: data,
    type: "RESOURCES_FETCH_DATA"
});
export const employeesFetchData = data => ({
    employees: data,
    type: "EMPLOYEES_FETCH_DATA"
});
export const officesFetchData = data => ({
    offices: data,
    type: "OFFICES_FETCH_DATA"
});
export const serviceResetData = () => ({
    service: null,
    type: "SERVICE_FETCH_DATA"
});
export const slotFetchData = data => ({
    slot: data,
    type: "SLOT_FETCH_DATA"
});
export const slotResetData = () => ({
    slot: null,
    type: "SLOT_FETCH_DATA"
});