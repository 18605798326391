import moment from 'moment';
import $ from 'jquery';
import { Container } from 'laya-js';

import { toast } from '@mobiscroll/react';
import { _ } from 'core-js';

const START = '[data-calendar-order-edit-start-datetime="true"]';
const END = '[data-calendar-order-edit-end-datetime="true"]';

const ORDER_ID = '[data-calendar-order-edit-order-id="true"]';

const LENGTH = '[data-calendar-order-edit-minutes="true"]';

const USER_ID = '[data-calendar-order-edit-userid="true"]';

const PICKER = '.clockpicker [data-hour="true"]';

const SELECT = '[data-calendar-order-edit-service-id="true"]';

const CUSTOMER_SELECT = '[data-calendar-order-edit-customer-select]';

const FIND_DATES = '[data-calendar-order-edit-find-dates="true"]';

const checkColisionUrlAdd = '/calendar/__USER_ID__/helpers/check-collisions/__FROM__/__TO__';
const checkColisionUrlEdit = '/calendar/__USER_ID__/helpers/check-collisions/__FROM__/__TO__/__ORDER_ID__';

const suggestDatesUrl = '/calendar/__USER_ID__/helpers/suggest-other-date/__FROM__/__TO__';

const CHANGE_DATES = '[data-change-dates="true"]';

const Router = Container.get('Router');

const formEdit = (() => {
  $(document).on('click', FIND_DATES, ev => {
    ev.preventDefault();
    const el = $(ev.currentTarget);
    if (el.data('bs.popover')) {
      el.popover('dispose');
      el.html(el.data('title'));
      return;
    }
    el.data('title', el.html());
    const dateStart = $(START);
    const dateEnd = $(END);
    if (dateStart.length > 0 || dateEnd.length > 0) {
      const startP = dateStart.parents('.form-row');
      const endP = dateEnd.parents('.form-row');
      const timeStart = startP.find('.clockpicker .form-control');
      const timeEnd = endP.find('.clockpicker .form-control');
      let start = moment(dateStart.val() + ' ' + timeStart.val(), 'DD.MM.YYYY HH:mm');
      let end = moment(dateEnd.val() + ' ' + timeEnd.val(), 'DD.MM.YYYY HH:mm');
      const orderProcessLink = $('[data-calendar-order-process-link]')
        .data('calendar-order-process-link')
        .replace('__SERVICE_LENGTH__', $(LENGTH).val())
        .replace('__SERVICE_ID__', $(SELECT).val());
      const url = suggestDatesUrl
        .replace('__FROM__', start.unix())
        .replace('__TO__', end.unix())
        .replace('__USER_ID__', $(USER_ID).val());
      $.getJSON(url, res => {
        let btn = '';
        if (_.size(res.data) > 0) {
          _.each(res.data, (el, k) => {
            const start = moment(el.startDatetime, 'X');
            const end = moment(el.endDatetime, 'X');
            btn +=
              '<button data-change-dates="true" data-start="' +
              el.startDatetime +
              '" data-end="' +
              el.endDatetime +
              '" class="btn btn-sm btn-outline-primary btn-block">' +
              start.format('DD.MM.YYYY | HH:mm') +
              ' - ' +
              end.format('HH:mm') +
              '</button>';
          });
          btn +=
            '<a href="' +
            orderProcessLink +
            '" target="_blank" class="btn btn-primary btn-block"><i class="fas fa-calendar-plus"></i> otevřít chytré objednání</a>';
          el.popover({
            content: btn,
            html: true,
            placement: 'bottom',
            sanitize: false,
          }).popover('show');
          el.html('<i class="fas fa-times"></i> zavřít');
        } else {
          toast({
            message: 'Nebyly nalezeny žádné další volné termíny',
            color: 'danger',
            display: 'center',
          });
        }
      });
    }
  });
  $(document).on('click', CHANGE_DATES, ev => {
    ev.preventDefault();
    const dateStart = $(START);
    const dateEnd = $(END);
    const el = $(ev.currentTarget);
    const start = moment(el.data('start'), 'X');
    const end = moment(el.data('end'), 'X');
    const startP = dateStart.parents('.form-row');
    const endP = dateEnd.parents('.form-row');
    const timeStart = startP.find('.clockpicker .form-control');
    const timeEnd = endP.find('.clockpicker .form-control');
    timeStart.val(start.format('HH:mm'));
    timeEnd.val(end.format('HH:mm'));
    dateStart.val(start.format('DD.MM.YYYY'));
    dateEnd.val(end.format('DD.MM.YYYY'));
    const findDates = $(FIND_DATES);
    findDates.popover('dispose');
    findDates.html(findDates.data('title'));
    toast({
      message: 'Termín byl změněn',
      color: 'success',
      display: 'center',
    });
  });
  $(document).on('changeClock', PICKER, ev => {
    const el = $(ev.currentTarget);
    if (el.parents('.form-row')) {
      const dateStart = $(START);
      const dateEnd = $(END);
      if (dateStart.length > 0 || dateEnd.length > 0) {
        const startP = dateStart.parents('.form-row');
        const endP = dateEnd.parents('.form-row');
        const timeStart = startP.find('.clockpicker .form-control');
        const timeEnd = endP.find('.clockpicker .form-control');
        let start = moment(dateStart.val() + ' ' + timeStart.val(), 'DD.MM.YYYY HH:mm');
        let end = moment(dateEnd.val() + ' ' + timeEnd.val(), 'DD.MM.YYYY HH:mm');
        let duration = moment.duration(end.diff(start));
        $(LENGTH).val(duration.asMinutes());
      }
    }
  });
  $(document).on('change', LENGTH, ev => {
    const el = $(ev.currentTarget);
    const dateStart = $(START);
    const dateEnd = $(END);
    if (dateStart.length > 0 || dateEnd.length > 0) {
      const startP = dateStart.parents('.form-row');
      const endP = dateEnd.parents('.form-row');
      const timeStart = startP.find('.clockpicker .form-control');
      const timeEnd = endP.find('.clockpicker .form-control');
      let start = moment(dateStart.val() + ' ' + timeStart.val(), 'DD.MM.YYYY HH:mm');
      let end = moment(dateEnd.val() + ' ' + timeEnd.val(), 'DD.MM.YYYY HH:mm');
      let duration = moment.duration(end.diff(start));
      end.add(el.val() - duration.asMinutes(), 'minutes');
      timeEnd.val(end.format('HH:mm'));
      console.log(end.format('HH:mm'))
      timeEnd.parents('.clockpicker').find("[data-hour='true']").val(end.format('HH'));
      timeEnd.parents('.clockpicker').find("[data-minute='true']").val(end.format('mm'));
      checkCollisionForDates();
    }
  });

  $(document).on('change', SELECT, ev => {
    const el = $(ev.currentTarget);
    const minutesArray = el.data('services-length');
    $(LENGTH).val(minutesArray[el.val()]).trigger('change');
  });

  $(document).on('change', START, ev => {
    const el = $(ev.currentTarget);
    $(END).val(el.val());
    checkCollisionForDates();
  });
  $(document).on('click', CUSTOMER_SELECT, ev => {
    ev.preventDefault();
    const target = $(ev.currentTarget);
    $('body').append(
      '<div class="modal fade" id="modalCustomer" data-backdrop="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"><div class="modal-dialog"><div class="modal-content" id="modalCustomerBody"></div></div></div>',
    );
    $('#modalCustomer').modal('show');
    $('#modalCustomer').on('shown.bs.modal', () => {
      Router.navigateManualy(target.data('calendar-order-edit-customer-select'));
    });
    $('#modalCustomer').on('hidden.bs.modal', () => {
      $('#modalCustomer').modal('dispose').remove();
      $("body").addClass("modal-open");
    });
  });
  const checkCollisionForDates = () => {
    const dateStart = $(START);
    const dateEnd = $(END);
    if (dateStart.length > 0 || dateEnd.length > 0) {
      const startP = dateStart.parents('.form-row');
      const endP = dateEnd.parents('.form-row');
      const timeStart = startP.find('.clockpicker .form-control');
      const timeEnd = endP.find('.clockpicker .form-control');
      const start = moment(dateStart.val() + ' ' + timeStart.val(), 'DD.MM.YYYY HH:mm');
      const end = moment(dateEnd.val() + ' ' + timeEnd.val(), 'DD.MM.YYYY HH:mm');
      let checkCollision = '';
      if ($('[data-order-add="true"]').length) {
        checkCollision = checkColisionUrlAdd
          .replace('__FROM__', start.unix())
          .replace('__TO__', end.unix())
          .replace('__USER_ID__', $(USER_ID).val());
      } else {
        checkCollision = checkColisionUrlEdit
          .replace('__FROM__', start.unix())
          .replace('__TO__', end.unix())
          .replace('__ORDER_ID__', $(ORDER_ID).val())
          .replace('__USER_ID__', $(USER_ID).val());
      }
      $.getJSON(checkCollision, res => {
        if (res.hasOwnProperty('data') && _.size(res.data) > 0) {
          toast({
            message: 'Ve zvolených časech již existuje objednávka. Tlačítkem ULOŽIT potvrdíte toto naplánování',
            color: 'danger',
            duration: 10000,
            display: 'center',
          });
        }
      });
    }
  };
})();

export default formEdit;
