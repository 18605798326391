import { Laya, Container } from 'laya-js';
import _ from "lodash";


/**
 * setSelectOptions - Sets options
 * @extends Laya.ActionAjax
 */

const inputLengthMinutes = '[data-length-minutes="true"]';
const inputSlotId = '[data-slot-id="true"]';
const buttonShowFreeOptions = '[data-show-free-options="true"]';
 $(document).on("change", "select", (ev) => {
    const option = $(ev.currentTarget).find("option:selected");
    
    if(option.data("length-minutes")) {
        $(inputLengthMinutes).val(option.data("length-minutes"));
        if($(inputSlotId).length) {
            const slotId = $(inputSlotId).val();
            console.log(slotId);
            const link = $(buttonShowFreeOptions).data("show-free-options-href");
            $(buttonShowFreeOptions).attr("href",link.replace("__SLOTID__",slotId).replace("__MINUTES__",option.data("length-minutes")));
        }
    }
    
 });

class SetSelectOptions extends Laya.ActionAjax {
	constructor() {
		super();
    }
	render(requestResult) {
		this.requestResult = requestResult;
        const select = $(`#${requestResult.params[0]}`);
        select.empty();
        select.append(`<option value="_NULL">--- Vybrat ---</option>`);
        _.each(requestResult.data, (row, key) => {
            select.append(`<option data-length-minutes="${row.minutes}" value="${row.value}">${row.name}</option>`);
        }); 
		super.render(requestResult);
	}
}

Container.register('SetSelectOptions', SetSelectOptions);

export default SetSelectOptions;
