import $ from "jquery";
import _ from "lodash";

import {Container} from "laya-js";
const Panel = Container.getClass("Panel");
Panel.on("componentRendered", ()=>{
    checkDependableInputAfterLoad();
});
const SELECT = "[data-dependable-master='true']";

const getColNumber = (input)  => {
    const classes = input.parents(".form-group").attr("class").split(" ");
    return parseInt(classes.filter((r)=> r.indexOf("col-md") != -1).toString().split("-")[2]);
}

const toggleDependableInput = (el) => {
    const id = el.attr("id");
    const value = el.find("option:selected").val();
    const inputs = $(`[data-dependable-to='${id}']`);
    if(_.isUndefined(inputs.data("dependable-on"))) return;
    inputs.each((index, item) => {
        const input = $(item);
        const colNumber = getColNumber(input);
        //console.log(input);
        if(input.data("dependable-on").indexOf(value) != -1) {
            //console.log(colNumber, el);
            
            el.parents(".form-group").removeClass(`col-md-${colNumber*2}`).addClass(`col-md-${colNumber}`);
            input.parents(".form-group").show();
        }
        else {
            //el.parents(".form-group").removeClass(`col-md-${colNumber}`).addClass(`col-md-${colNumber*2}`);
            input.val('');
            input.parents(".form-group").hide();
        }
    });
    
}
const checkDependableInputAfterLoad = () => {
    $(()=>{
        $(SELECT).each((index, element)=>{
            const el = $(element);
            toggleDependableInput(el);
        });
    });
};
const handleDependableInput = (()=>{
    
    $(document).on("change", SELECT, (ev) => {
        const el = $(ev.currentTarget);
        toggleDependableInput(el);      
    });
    checkDependableInputAfterLoad();
})();
