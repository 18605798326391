import { Laya, Container } from 'laya-js';



/**
 * ShowInput - Shows input and its group
 * @extends Laya.ActionAjax
 */

class ShowInput extends Laya.ActionAjax {
	constructor() {
		super();
    }
	render(requestResult) {
		this.requestResult = requestResult;
		const input = $(`#${requestResult.params[0]}`);
		input.parents(".form-group").show();
		super.render(requestResult);
	}
}

Container.register('ShowInput', ShowInput);

export default ShowInput;
