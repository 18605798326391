import $ from "jquery";

const openDataUri = (()=>{
    $(document).on("click","[data-open-uri='true']", (ev) => {
        ev.preventDefault();
        const el = $(ev.currentTarget);
        var win = window.open(); 
        setTimeout(() => { 
            win.document.write('<html><head><title>Zpráva</title><style>body {margin: 0;}</style></head><body>');
            win.document.write("<iframe style='margin: 0;padding: 0;border: 0;font-size: 100%;font: inherit;vertical-align: baseline;' width='100%' height='100%' src='data:text/html;base64, " +
        encodeURI(el.attr("href")) + "'></iframe>");
        win.document.write('</body></html>');
            win.document.title = "Dokument";
            console.log(win.document.getElementsByTagName('body'));
            
        },500);

    });
})();