import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import {Provider} from "react-redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";

import {DateRange} from 'react-date-range';
import cs from "date-fns/locale/cs";
import sk from "date-fns/locale/sk";

import {
    serviceGroupFetchData,
    serviceFetchData,
    slotFetchData,
    slotResetData,
    serviceResetData,
    resourcesFetchData,
    employeesFetchData,
    officesFetchData,
} from "./actions";

import {Container, Modal} from "laya-js";

const Router = Container.get("Router");

import store from "./store";

import $ from "jquery";

import Panel from "../../handler/panel";

const Notification = Container.get('Notification');


export class Slot extends React.Component {
    constructor(props) {
        super(props);
        this.slotClick = props.slotClick

    }

    renderOptions() {
        let rows = [];
        let prev = null;
        _.each(this.props.options, (time, key) => {
            const hour = time.split(":")[0];
            if (prev != null && hour != prev && (this.props.slot.selectTimeMode == "any" || this.props.slot.selectTimeMode == "lines" || this.props.slot.selectTimeMode == "fixed-lines")) {
                let hrkey = "hr_" + key;
                rows.push(<hr key={hrkey}/>);
            }
            rows.push(<button onClick={ev => this.slotClick(time, key, this.props.slot)} key={key} type="button"
                              className="btn btn-outline-primary btn-sm mr-1 mb-1">{time}</button>);
            prev = hour;
        });
        return rows;
    }

    render() {
        return (
            <div className="col-lg-12">
                {this.props.showDay &&
                    <div className="card rounded-0 bg-primary mt-4">
                        <div className="card-body">
                            <h4 className="card-title text-white"><i
                                className="fas fa-calendar-alt"></i> {this.props.slot.date}</h4>
                        </div>
                    </div>
                }
                {this.renderItem()}
            </div>

        );
    }

    renderItem() {
        const colors = {
            'gray': '#495057',
            'red': '#dc3545',
            'green': '#8DB75D',
            'blue': '#2061B5',
            'yellow': '#d8860b',
            'pink': '#D43C84',
        };
        const bg = {background: colors[this.props.slot.color]};
        return (
            <div className="card rounded-0">
                <div className="card-header d-flex flex-column flex-lg-row justify-content-between pb-0">
                    <div>
                        <span style={bg} className="badge badge-primary"><i
                            className="fas fa-tag"></i> {this.props.slot.name}</span>
                    </div>
                    <div className="card-controls">
                        {!_.isEmpty(this.props.slot.paidPriceWithoutVatService) && <span
                            className="badge badge-success text-white mr-1">{window.TRANSLATIONS.priceService} {this.props.slot.paidPriceWithoutVatService}</span>}
                        {!_.isEmpty(this.props.slot.paidPriceWithoutVatSlot) && <span
                            className="badge badge-success text-white mr-1">{window.TRANSLATIONS.priceSlot} {this.props.slot.paidPriceWithoutVatSlot}</span>}
                        {!_.isEmpty(this.props.slot.paidPriceWithoutVat) && <span
                            className="badge badge-success text-white mr-1">{window.TRANSLATIONS.price} {this.props.slot.paidPriceWithoutVat}</span>}
                    </div>
                </div>
                <div className="card-body">
                    {this.renderOptions()}
                    <div className='d-flex justify-content-between border-top mt-2 pb-1'>
                        <div><span
                            className="badge badge-secondary">{window.TRANSLATIONS.offices}: {this.props.slot.office}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Booking extends React.Component {
    constructor(props) {
        super(props);
        this.slotClick = this.slotClick.bind(this);
        this.removeSlot = this.removeSlot.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetAll = this.resetAll.bind(this);
        this.handleChangeWeekdays = this.handleChangeWeekdays.bind(this);
        this.handleEmployeesPart = this.handleEmployeesPart.bind(this);
        this.handleResourcesPart = this.handleResourcesPart.bind(this);
        this.handleOfficesPart = this.handleOfficesPart.bind(this);
        this.handleMoveStep = this.handleMoveStep.bind(this);
        this.handleChangeWithoutOrders = this.handleChangeWithoutOrders.bind(this);
        this.handleChangeRegisteredPatient = this.handleChangeRegisteredPatient.bind(this);
        this.selectRef = React.createRef();
        this.selectRef.current = {
            "serviceGroup": [],
            "service": []
        };
        let secondPartValue = "empty";
        if (!_.isUndefined(props.serviceId)) {
            secondPartValue = props.serviceId;
        }
        this.state = {
            selectedTimes: [],
            firstPartValue: "empty",
            secondPartValue: secondPartValue,
            selectedServiceName: this.props.serviceName || null,
            selectedServiceId: this.props.serviceId || null,
            selectedServiceGroupName: this.props.serviceGroupName || null,
            selectedServiceGroupId: this.props.serviceGroupId || null,
            date: [{
                startDate: null,
                endDate: null,
                key: 'selection'
            }],
            startDate: moment().format("DD.MM.YYYY"),
            endDate: null,
            step: 0,
            isMultiple: false,
            resources: !_.isUndefined(this.props.activeResource) ? [this.props.activeResource] : [],
            employees: !_.isUndefined(this.props.activeEmployee) ? this.props.activeEmployee : [],
            offices: !_.isUndefined(this.props.activeOffice) ? this.props.activeOffice : [],
            weekdays: [
                {value: 0, isChecked: false, name: window.TRANSLATIONS.monday},
                {value: 1, isChecked: false, name: window.TRANSLATIONS.tuesday},
                {value: 2, isChecked: false, name: window.TRANSLATIONS.wednesday},
                {value: 3, isChecked: false, name: window.TRANSLATIONS.thursday},
                {value: 4, isChecked: false, name: window.TRANSLATIONS.friday},
                {value: 5, isChecked: false, name: window.TRANSLATIONS.saturday},
                {value: 6, isChecked: false, name: window.TRANSLATIONS.sunday}
            ],
            allowSlotsWithoutOrders: 1,
            registeredPatient: 1,
        };
    }

    constructFindSlotUrl() {
        const slotUrl = this.props.findSlotUrl.replace(/step\/\d/, "step/" + this.state.step);
        const url = new URL(slotUrl);
        const starDate = this.state.startDate;
        const endDate = this.state.endDate;
        const weekdays = this.state.weekdays;
        const serviceId = this.state.secondPartValue;
        if (this.state.secondPartValue != "empty") {
            url.searchParams.append("serviceId", serviceId);
        }
        if (!_.isNull(starDate)) {
            url.searchParams.append("dateFrom", starDate);
        }
        if (!_.isNull(endDate)) {
            url.searchParams.append("dateUntil", endDate);
        }

        if (!_.isNull(weekdays)) {
            _.each(weekdays, (day, index) => {
                if (day.isChecked == true) {
                    url.searchParams.append("weekdays[]", day.value);
                }

            });
        }
        _.each(this.state.employees, (row, index) => {
            url.searchParams.append("employees[]", row);
        });
        _.each(this.state.resources, (row, index) => {
            url.searchParams.append("resources[]", row);
        });
        _.each(this.state.offices, (row, index) => {
            url.searchParams.append("offices[]", row);
        });

        url.searchParams.append("allowSlotsWithoutOrders", this.state.allowSlotsWithoutOrders);
        url.searchParams.append("registeredPatient", this.state.registeredPatient);
        return url.href;

    }

    slotClick(time, datetime, slot) {


            if (this.state.isMultiple) {
                const selectedTimes = [...this.state.selectedTimes];
                if (!selectedTimes.find((r) => r.slotId == slot.id && r.datetime == datetime)) {
                    selectedTimes.push({
                        slotId: slot.id,
                        time,
                        datetime,
                        dateHuman: slot.date,
                        url: slot.url,
                        serviceId: slot.serviceId
                    });
                    this.setState({
                        selectedTimes: selectedTimes
                    });
                }

            }
            else {
                const selectedTimes = [{
                    slotId: slot.id,
                    time,
                    datetime,
                    dateHuman: slot.date,
                    url: slot.url,
                    serviceId: slot.serviceId
                }];
                this.createOrder(selectedTimes)
            }
    }
    removeSlot() {
        const selectedTimes = [...this.state.selectedTimes];
        if (selectedTimes.find((r) => r.slotId == selectedTime.slotId && r.datetime == selectedTime.datetime)) {
            const index = selectedTimes.findIndex((r)=>  r.slotId == selectedTime.slotId && r.datetime == selectedTime.datetime);
            selectedTimes.splice(index, 1)
            this.setState({
                selectedTimes: selectedTimes
            });
        }
    }

    createOrder(selectedTime=null) {
        const selectedTimes =  selectedTime ?? this.state.selectedTimes;
        Modal.on('componentPrepared', (methods) => {
            methods.placements = selectedTimes.map((item)=>({
                slotId: item.slotId,
                serviceId: item.serviceId,
                datetime: item.datetime,
                originServiceId: null,
                originCompanyId: null
            }));
        });
        Router.navigateManualy(selectedTimes[0].url);
    }

    handleChange(event, target = null, value = null, name = null, isMultiple=null) {
        const type = !_.isNull(target) ? target : event.currentTarget.getAttribute("data-target");
        const val = !_.isNull(value) ? value : event.currentTarget.getAttribute("data-value");
        console.log(isMultiple);
        switch (type) {
            case "serviceGroup":
                this.setState({
                    firstPartValue: val,
                    secondPartValue: "empty"
                });
                //if(event.target.value == "empty") {
                this.props.resetServiceData();
                this.props.resetSlotData();
                //}
                let url = this.props.serviceUrl.replace("__SERVICE_GROUP_ID__", val);
                this.props.fetchSecondPartData(url);

                break;
            case "service":
                if (typeof window.gtag != "undefined") {
                    const _name = !_.isNull(name) ? name : $(event.currentTarget).text();
                    gtag('event', 'add_to_cart', {
                        'currency': 'CZK',
                        'value': 0,
                        'items': [{
                            'item_id': val,
                            'item_name': _name
                        }],
                    });
                }
                this.setState({
                    secondPartValue: val,
                    isMultiple: isMultiple,
                    selectedTimes: []
                });
                break;
            default:
                break;
        }

    }

    handleChangeDate(item) {

        const startDate = moment(item.selection.startDate).format("DD.MM.YYYY");
        const endDate = moment(item.selection.endDate).format("DD.MM.YYYY");
        this.setState({
            date: [item.selection],
            startDate: startDate,
            endDate: endDate,
            step: 0
        });

        return this;
    }

    handleChangeWeekdays(event, day) {
        const val = day.value;
        let state = _.cloneDeep(this.state.weekdays);

        state.map((row, index) => {
            if (row.value == val) {
                row.isChecked = !row.isChecked;
            }
            return row;
        });

        this.setState({
            weekdays: state,
            step: 0
        });
        return this;
    }

    handleEmployeesPart(event, row) {
        event.preventDefault();
        let res = [];
        if (row != false) {
            res = [...this.state.employees];
            if (res.indexOf(row.value) != -1) {
                res.splice(res.indexOf(row.value), 1);
            } else {
                res.push(row.value);
            }
        }
        this.setState({
            employees: res,
        });
        return this;
    }

    handleResourcesPart(event, row) {
        event.preventDefault();
        let res = [...this.state.resources];
        if (res.indexOf(row.value) != -1) {
            res.splice(res.indexOf(row.value), 1);
        } else {
            res.push(row.value);
        }
        this.setState({
            resources: res,
        });
        return this;
    }

    handleOfficesPart(event, row) {
        event.preventDefault();
        let res = [...this.state.offices];
        if (res.indexOf(row.value) != -1) {
            res.splice(res.indexOf(row.value), 1);
        } else {
            res.push(row.value);
        }
        this.setState({
            offices: res,
        });
        return this;
    }

    handleMoveStep(ev, num) {
        ev.preventDefault();
        let step = _.clone(this.state.step);
        if (num > 0) {
            step++;
        } else {
            step--;
        }

        this.setState(
            {
                step: step
            }
        )
    }

    handleChangeWithoutOrders(ev) {
        ev.preventDefault();
        let val = _.clone(this.state.allowSlotsWithoutOrders);
        if (val == 1) {
            val = 0;
        } else {
            val = 1;
        }
        this.setState({
            allowSlotsWithoutOrders: val
        });
        return this;
    }

    handleChangeRegisteredPatient(ev) {
        ev.preventDefault();
        let val = _.clone(this.state.registeredPatient);
        if (val == 1) {
            val = 0;
        } else {
            val = 1;
        }
        this.setState({
            registeredPatient: val
        });
        return this;
    }

    componentDidMount() {
        if (_.isNull(this.state.selectedServiceId)) {
            this.props.fetchFirstPartData(this.props.serviceGroupUrl);
            this.props.fetchFilterData(this.props.employeesUrl, this.props.resourcesUrl, this.props.officesUrl);
        } else {
            if (typeof window.gtag != "undefined") {
                gtag('event', 'add_to_cart', {
                    'currency': 'CZK',
                    'value': 0,
                    'items': [{
                        'item_id': this.state.selectedServiceId,
                        'item_name': this.state.selectedServiceName
                    }],
                });
            }
            const url = this.constructFindSlotUrl();
            if (!_.isNull(this.state.resources) || !_.isNull(this.state.offices) || !_.isNull(this.state.employees)) {
                this.props.fetchFilterData(this.props.employeesUrl, this.props.resourcesUrl, this.props.officesUrl);
            }
            this.props.fetchThirdPartData(url);

        }


    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.secondPartValue != this.state.secondPartValue && this.state.secondPartValue != "empty") ||
            prevState.startDate != this.state.startDate ||
            prevState.endDate != this.state.endDate ||
            !_.isEqual(prevState.weekdays, this.state.weekdays) ||
            prevState.step != this.state.step ||
            prevState.employees != this.state.employees ||
            prevState.resources != this.state.resources ||
            prevState.offices != this.state.offices ||
            prevState.registeredPatient != this.state.registeredPatient ||
            prevState.allowSlotsWithoutOrders != this.state.allowSlotsWithoutOrders
        ) {
            this.props.resetSlotData();
            const url = this.constructFindSlotUrl();
            this.props.fetchThirdPartData(url);
        }
        //If reseted selected data
        if (prevState.selectedServiceId != this.state.selectedServiceId) {
            this.props.fetchFirstPartData(this.props.serviceGroupUrl);
            this.props.fetchFilterData(this.props.employeesUrl, this.props.resourcesUrl, this.props.officesUrl);
        }
        // If there is only one serviceGroup or group, automatically select them
        if (prevProps.serviceGroup != this.props.serviceGroup && !_.isNull(this.props.serviceGroup) && this.props.serviceGroup.hasOwnProperty("data") && this.props.serviceGroup.data.length == 1) {
            const current = _.first(this.props.serviceGroup.data);
            if (current) {
                this.handleChange(null, "serviceGroup", current.value, current.name, current.isMultiple);
            }
        }
        if (prevProps.service != this.props.service && !_.isNull(this.props.service) && this.props.service.hasOwnProperty("data") && this.props.service.data.length == 1) {
            const current = _.first(this.props.service.data);
            if (current) {
                this.handleChange(null, "service", current.value, current.name, current.isMultiple);
            }
        }

    }

    renderFirstPart() {
        if (_.isNull(this.state.selectedServiceId)) {
            return (
                <div className="order-part" id="serviceGroupPart">
                    <h4 className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-primary">1</span> {window.TRANSLATIONS.chooseCategory}</h4>
                    <div>
                        {this.renderFirstPartOptions()}
                    </div>
                </div>
            );
        } else {
            return (null);
        }
    }

    renderFirstPartOptions() {

        let rows = [];
        if (_.isNull(this.props.serviceGroup)) {
            rows.push(<div key="00" className="alert alert-warning"><i
                className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}</div>);
        } else {
            _.each(this.props.serviceGroup.data, (row, key) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (row.value == this.state.firstPartValue) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                rows.push(<button title={row.description} data-toggle="tooltip" data-target="serviceGroup"
                                  onClick={this.handleChange} className={cssClass} key={key} type="button"
                                  data-value={row.value}><i className="fas fa-tags"></i> {row.name}</button>);
            });
        }
        return rows;
    }

    renderSecondPart() {
        if (!_.isNull(this.props.service)) {
            return (
                <div className="order-part">
                    <h4 id="servicePart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-primary">2</span> {window.TRANSLATIONS.chooseService}</h4>
                    {this.renderSecondPartOptions()}
                </div>

            );
        } else if (_.isNull(this.props.service) && this.state.firstPartValue != "empty") {
            return (
                <div className="order-part">
                    <h4 id="servicePart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-primary">2</span> {window.TRANSLATIONS.chooseService}</h4>
                    <div className="alert alert-warning"><i
                        className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}</div>
                </div>

            );
        } else if (!_.isNull(this.state.selectedServiceId)) {
            return (
                <div>
                    <div className="order-part">
                        <h4 id="servicePart" className="card-title card-title-big mb-2"><span
                            className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.chosenCategoryGroup}
                        </h4>
                        <button className="btn btn-primary" type="button"><i
                            className="fas fa-tags"></i> {this.state.selectedServiceGroupName}</button>
                        <button className="btn btn-primary" type="button"><i
                            className="fas fa-tag"></i> {this.state.selectedServiceName}</button>
                        <hr/>
                        <button onClick={this.resetAll} className="btn btn-dark btn-sm" type="button"><i
                            className="fas fa-times"></i> {window.TRANSLATIONS.changeServices}</button>
                    </div>
                </div>

            )
        } else {
            return (null);
        }
    }

    resetAll(ev) {
        ev.preventDefault();
        this.setState({
            selectedServiceGroupId: null,
            selectedServiceId: null,
            selectedServiceName: null,
            selectedServiceGrouName: null,
            firstPartValue: "empty",
            secondPartValue: "empty"
        });
    }

    renderSecondPartOptions() {
        let rows = [];
        if (this.props.service.data == false) {
            rows.push(<div key="00" className="alert alert-warning">{window.TRANSLATIONS.noData}</div>);
        } else {
            _.each(this.props.service.data, (row, key) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (row.value == this.state.secondPartValue) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                let premium = <i className="fas fa-tag"></i>;
                if (row.isPremium == true) {
                    premium = <span className='badge badge-primary'><i className="fas fa-star"></i> premium</span>;
                }
                rows.push(<button title={row.description} data-toggle="tooltip" data-target="service"
                                  onClick={()=>this.handleChange(null, "service", row.value, row.name, row.isMultiple)} className={cssClass} key={key} type="button"
                                  data-value={row.value}>{premium} {row.name}</button>);
            });
        }


        return rows;
    }

    renderThirdPartOptions() {
        if (!_.isNull(this.props.slot) && this.props.slot.data !== false) {
            let rows = [];
            let prevDate = null;
            let showDay = false;
            _.each(this.props.slot.data, (row, key) => {
                const date = moment(row.slot.startDatetime * 1000).format("DD.MM.YYYY");
                if (prevDate != date) {
                    prevDate = date;
                    showDay = true;
                } else {
                    showDay = false;
                }
                rows.push(<Slot slotClick={this.slotClick} showDay={showDay} key={key} {...row} />);
            });
            return rows;
        } else if (!_.isNull(this.props.slot) && this.props.slot.data === false) {
            return (<div className="alert alert-warning">{window.TRANSLATIONS.noDates}</div>);
        } else {
            return (null);
        }

    }

    renderFilterPart() {
        /*<button type="button" data-toggle="collapse" data-target="#filterWeeks" className="btn btn-outline-primary">{window.TRANSLATIONS.moreFilter}</button>*/
        let calLang = cs;
        if (LANG == "sk_SK.UTF-8") {
            calLang = sk;
        }
        return (
            <div className="d-flex flex-column">

                <DateRange
                    locale={calLang}
                    editableDateInputs={false}
                    minDate={new Date()}
                    showSelectionPreview={false}
                    onChange={item => this.handleChangeDate(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={this.state.date}
                    endDatePlaceholder={window.TRANSLATIONS.notDefined}
                    startDatePlaceholder={window.TRANSLATIONS.notDefined}
                />

                <div className="collapse show mb-2" id="filterWeeks">
                    <hr/>
                    {this.renderOfficesPart()}
                    <hr/>
                    {this.renderWeekdays()}
                    <hr/>
                    {this.renderAllowSlotsWithoutOrders()}
                    <hr/>
                    {this.renderRegisteredPatients()}
                </div>


            </div>
        );
    }

    renderAllowSlotsWithoutOrders() {
        let cssClass = 'btn btn-primary';
        if (this.state.allowSlotsWithoutOrders == false) {
            cssClass = 'btn btn-outline-primary';
        }
        return (
            <div>
                <h4 className="card-title mb-2">{window.TRANSLATIONS.allowSlotsWithoutOrders}?</h4>
                <button className={cssClass} type="checkbox" onClick={this.handleChangeWithoutOrders}><i
                    className='fas fa-check'></i> {window.TRANSLATIONS.yes}</button>
            </div>
        )
    }

    renderRegisteredPatients() {
        let cssClass = 'btn btn-primary';
        if (this.state.registeredPatient == false) {
            cssClass = 'btn btn-outline-primary';
        }
        return (
            <div>
                <h4 className="card-title mb-2">{window.TRANSLATIONS.registeredPatient}</h4>
                <button className={cssClass} type="checkbox" onClick={this.handleChangeRegisteredPatient}><i
                    className='fas fa-check'></i> {window.TRANSLATIONS.yes}</button>
            </div>
        )
    }

    renderWeekdays() {
        let rows = [];
        rows.push(<h4 key="00" className='card-title mb-2'>{window.TRANSLATIONS.days}</h4>);
        _.each(this.state.weekdays, (day, index) => {
            let cssClass = 'btn btn-outline-primary mr-1 mb-1';
            if (day.isChecked) {
                cssClass = 'btn btn-primary mr-1 mb-1';
            }
            rows.push(<button className={cssClass} key={index} type="button"
                              onClick={ev => this.handleChangeWeekdays(ev, day)}><i
                className='fas fa-calendar-day'></i> {day.name}</button>)
        });
        return rows;
    }

    renderResourcesPart() {
        if (!_.isNull(this.props.resources) && this.props.resources.hasOwnProperty("data")) {
            let rows = [];
            _.each(this.props.resources.data, (row, index) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (this.state.resources.indexOf(row.value) != -1) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                rows.push(<button onClick={ev => this.handleResourcesPart(ev, row)} className={cssClass} type="button"
                                  key={index}><i className="fas fa-clinic-medical"></i> {row.name}</button>);
            });
            return (
                <div className="order-part">
                    <h4 id="employeesPart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.resources}</h4>
                    {rows}
                </div>
            )
        } else {
            return (null);
        }

    }

    renderOfficesPart() {
        if (!_.isNull(this.props.offices) && this.props.offices.hasOwnProperty("data")) {
            let rows = [];
            rows.push(<h4 key="00" className='card-title mb-2'>{window.TRANSLATIONS.offices}</h4>);
            _.each(this.props.offices.data, (row, index) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (this.state.offices.indexOf(row.value) != -1) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                rows.push(<button onClick={ev => this.handleOfficesPart(ev, row)} className={cssClass} type="button"
                                  key={index}><i className="fas fa-notes-medical"></i> {row.name}</button>);
            });
            return rows;
        } else {
            return (null);
        }

    }

    renderSteps() {
        //  && this.props.slot.data.length > 9
        if (!_.isNull(this.props.slot)) {
            return (
                <div className="mt-4 d-flex justify-content-center">
                    <div className="btn-group">
                        {this.state.step > 0 && <button onClick={ev => this.handleMoveStep(ev, -1)} type="button"
                                                        className="btn btn-outline-primary">{window.TRANSLATIONS.prev}</button>}
                        {this.props.slot.data !== false &&
                            <button type="button" onClick={ev => this.handleMoveStep(ev, 1)}
                                    className="btn btn-outline-primary">{window.TRANSLATIONS.more}</button>}
                    </div>
                </div>
            );
        } else {
            return (null)
        }
    }

    renderEmployeesPart() {
        if (this.state.secondPartValue != "empty" && !_.isNull(this.props.employees) && this.props.employees.data != false) {
            let rows = [];
            _.each(this.props.employees.data, (row, index) => {
                let cssClass = 'btn mr-1 btn-outline-primary';
                if (this.state.employees.indexOf(row.value) != -1) {
                    cssClass = 'btn mr-1 btn-primary';
                }
                rows.push(<button type="button" onClick={ev => this.handleEmployeesPart(ev, row)} className={cssClass}
                                  key={index}><i className="fas fa-user-md"></i> {row.name}</button>);
            });
            let cssClassDefault = 'btn mr-1 btn-outline-primary';
            if (_.isEmpty(this.state.employees)) {
                cssClassDefault = 'btn mr-1 btn-primary';
            }
            return (
                <div className="order-part">
                    <h4 id="employeesPart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.chooseEmployee}</h4>
                    <button type="button" onClick={ev => this.handleEmployeesPart(ev, false)}
                            className={cssClassDefault} key="00"><i
                        className="fas fa-user-md"></i> {window.TRANSLATIONS.all}</button>
                    {rows}
                </div>
            )
        } else {
            return (null);
        }
    }

    render() {
        let numPart = "4.";
        if (!_.isNull(this.state.selectedServiceId)) {
            numPart = "1.";
        }
        let showSlots = false;
        if (this.state.secondPartValue != "empty" && _.isNull(this.state.selectedServiceId)) {
            showSlots = true;
        } else if (!_.isNull(this.state.selectedServiceId)) {
            showSlots = true;
        }
        const width = $(window).width()
        return (
            <Provider store={store}>
                {this.state.selectedTimes.length > 0 &&
                    (<div className={'booking-selected-times'}>
                        <div className={'container'}>
                            <div className={'d-flex justify-content-between align-items-lg-center flex-column flex-lg-row'}>
                                <div className={'d-flex flex-column'}>
                                    <p className={'mb-1'}><strong>Vybrané termíny:</strong></p>
                                    <div className={'d-flex flex-column flex-lg-row'}>
                                        {this.state.selectedTimes.map((row, index) => (<button key={index} onClick={()=>this.removeSlot(row)} className={'btn btn-sm btn-outline-primary mr-1'}><i className="fas fa-times" /> {row.dateHuman}  {row.time}</button>))}
                                    </div>
                                </div>
                                <button onClick={()=>this.createOrder()} className={'btn btn-outline-success'}>Vytvořit objednávku</button>
                            </div>
                        </div>
                    </div>)}
                <div className="booking card" style={{paddingBottom : width < 500 ? '20rem' : 0}}>
                    <div className="card-header pb-3 bg-success">
                        <h4 className="card-title text-white text-center"><i
                            className="fas fa-calendar"></i> {window.TRANSLATIONS.createOrder}</h4>
                    </div>
                    <div className="card-body">

                        <form className="laya-form-tested form-groupped" onSubmit={this.handleSubmit}>
                            {this.renderFirstPart()}
                            {this.renderSecondPart()}
                            {this.renderResourcesPart()}
                            {this.renderEmployeesPart()}
                            {showSlots === true &&
                                <div className="order-part">
                                    <h4 id="slotPart" className="card-title card-title-big mb-2"><span
                                        className="badge badge-pill badge-primary">{numPart}</span> {window.TRANSLATIONS.choseDate}
                                    </h4>
                                    <h4 className='card-title mb-2'>{window.TRANSLATIONS.from} - {window.TRANSLATIONS.to}</h4>
                                    <div className="row">
                                        <div className="col-lg-5">
                                            {this.renderFilterPart()}
                                        </div>
                                        {!_.isNull(this.props.slot) &&
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    {this.renderThirdPartOptions()}
                                                </div>
                                                {this.renderSteps()}
                                            </div>
                                        }
                                        {_.isNull(this.props.slot) &&
                                            <div className="col-lg-7">
                                                <div className="alert alert-warning"><i
                                                    className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>}

                        </form>
                    </div>
                </div>
            </Provider>
        );
    }
}

Booking.contextTypes = {store: PropTypes.object};
Booking.propTypes = {
    serviceGroup: PropTypes.any,
    serviceGroupUrl: PropTypes.string,
    serviceUrl: PropTypes.string,
    findSlotUrl: PropTypes.string,
    resourcesUrl: PropTypes.string,
    employeesUrl: PropTypes.string,
    officesUrl: PropTypes.string,
    fetchFirstPartData: PropTypes.func.isRequired,
    fetchSecondPartData: PropTypes.func.isRequired
};
const mapStateToProps = store => {
    return {
        serviceGroup: store.serviceGroup,
        service: store.service,
        slot: store.slot,
        resources: store.resources,
        employees: store.employees,
        offices: store.offices,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchFirstPartData: (url) => {
            $.getJSON(url).done((result) => {
                dispatch(serviceGroupFetchData(result));
                $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
            });

        },
        fetchFilterData(employeesUrl, resourcesUrl, officesUrl) {
            $.getJSON(employeesUrl).done((result) => {
                dispatch(employeesFetchData(result));
            });
            $.getJSON(resourcesUrl).done((result) => {
                dispatch(resourcesFetchData(result));
            });
            $.getJSON(officesUrl).done((result) => {
                dispatch(officesFetchData(result));
            });
        },
        fetchSecondPartData: (url) => {
            $.getJSON(url).done((result) => {
                dispatch(serviceFetchData(result));
                $("#servicePart")[0].scrollIntoView({behavior: 'smooth', block: 'start'});
                $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
            });
        },
        fetchThirdPartData: (url) => {
            $.getJSON(url).done((result) => {
                dispatch(slotFetchData(result));
                if (result.hasOwnProperty("notification")) {
                    _.each(result.notification.messages, (messages, type) => {
                        //Notification.create(type, m)
                        Notification.create(type, messages);
                    });
                }
                if ($("#employeesPart").length) {
                    $("#employeesPart")[0].scrollIntoView({behavior: 'smooth', block: 'start'});
                }

            });
        },
        resetSlotData: () => {
            dispatch(slotResetData());
        },
        resetServiceData: () => {
            dispatch(serviceResetData());
        }
    }
};
const BookingConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(Booking);

class BookingContainer extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <BookingConnected {...this.props} />
            </Provider>
        );
    }
}

//Initializer
export const initBooking = () => {
    if ($("#componentBooking").length) {
        const serviceGroupUrl = $("#componentBooking").data("service-group-url");
        const serviceUrl = $("#componentBooking").data("service-url");
        const activeEmployee = $("#componentBooking").data("active-employee");
        const activeOffice = $("#componentBooking").data("active-office");
        const activeResource = $("#componentBooking").data("active-resource");
        const findSlotUrl = $("#componentBooking").data("find-slot-url");
        const resourcesUrl = $("#componentBooking").data("resources-url");
        const employeesUrl = $("#componentBooking").data("employees-url");
        const officesUrl = $("#componentBooking").data("offices-url");
        const serviceId = $("#componentBooking").data("service-id");
        const serviceName = $("#componentBooking").data("service-name");
        const serviceGroupName = $("#componentBooking").data("servicegroup-name");
        ReactDOM.render(
            React.createElement(BookingContainer,
                {
                    serviceGroupUrl: serviceGroupUrl,
                    activeEmployee: activeEmployee,
                    activeResource: activeResource,
                    activeOffice: activeOffice,
                    serviceUrl: serviceUrl,
                    findSlotUrl: findSlotUrl,
                    resourcesUrl: resourcesUrl,
                    employeesUrl: employeesUrl,
                    officesUrl: officesUrl,
                    serviceName: serviceName,
                    serviceGroupName: serviceGroupName,
                    serviceId: serviceId
                }
            ),
            $("#componentBooking")[0]
        );
    }
};
Panel.on("componentRendered", () => {
    initBooking();
});
$(() => {
    initBooking();
});
