import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_cs';
import { Container, Save, Load, LoadInto, SaveAndLoad, Modal, SaveAndLoadData } from 'laya-js';
import _ from 'lodash';
import 'select2/dist/js/select2.full';
import 'clockpicker-umd/dist/clockpicker';
//import "selectize";

import { ruleIc, ruleBirthNumber, ruleZip, ruleSelect, rulePhone, ruleNoDoubleSpaces, ruleDiagnosis } from './validators';

const VALIDATE_FORM = true; //Sets if form is validated by JS
const notification = Container.get('Notification');

const Panel = Container.getClass("Panel");
/**
 * Handles the plugin init process, form validation and input masks
 *
 * @class Form
 */
class Form {
  constructor() {
    //Listeners

    $(document).on('focus', '.form-control', this.focusFormGroup.bind(this));
    $(document).on('blur', '.form-control', this.blurFormGroup.bind(this));
    $(document).on('change', '[data-rule-select]', this.handleChangeValidation.bind(this));
    $(document).on('click', "[data-laya-form='forget-permanent']", this.clearFilterForm.bind(this));

    //Validation
    if (VALIDATE_FORM) {
      $(()=>{
          this.setupValidateForm();
          this.prepareDOMPlugins();
      });

      //InputMask.init();
      Save.on('componentPrepared', this.validateForm.bind(this));
      SaveAndLoad.on('componentPrepared', this.validateForm.bind(this));
      SaveAndLoadData.on('componentPrepared', this.validateForm.bind(this));
    }

    //Laya js - Listen to event actions and reinit DOM plugins
    Load.on('componentRendered', this.prepareDOMPlugins.bind(this));
    LoadInto.on('componentRendered', this.prepareDOMPlugins.bind(this));
    Modal.on('componentRendered', this.prepareDOMPlugins.bind(this));
    SaveAndLoad.on('componentRendered', this.prepareDOMPlugins.bind(this));
    Panel.on('componentRendered', this.prepareDOMPlugins.bind(this));



  }
  /**
   * Validates form using $.validator, listens to Save componentPrepared
   *
   * @param {object} methods
   * @param {object} deferred
   * @returns {object} this
   * @memberof Form
   */
  validateForm(methods, deferred) {
    let params = _.clone(methods._actions[0].params);
    let param = '';
    if (_.isPlainObject(params)) {
      param = params.form;
    } else {
      param = params;
    }
    _.each(param, val => {
      if(!$(`#${val}`).hasClass("ignore-validation")) {
        let validator = $(`#${val}`).validate();
        if (typeof validator != "undefined" && validator.form() === false) {
          deferred.reject();
        }  
      }
    });
    return this;
  }
  /**
   * Clears filter form
   *
   * @param {object} ev - click event
   * @returns {Object} this
   * @memberof Form
   */
  clearFilterForm(ev) {
    let target = $(ev.currentTarget);
    if (target.parents('form').length) {
      $(':input', target.parents('form'))
        .not(':button, :submit, :reset, :hidden')
        .val('')
        .removeAttr('checked')
        .removeAttr('selected');
    }
    return this;
  }
  /**
   * Inits jquery plugins that are connected to form UI
   *
   * @returns {object} this
   * @memberof Form
   */
  prepareDOMPlugins() {
    $('.select:not([data-hvlp]):not([data-uzis]):not([data-substances])').each((key, el) => {
      if (!$(el).hasClass('select2-hidden-accessible')) {
        $(el).select2({
          width: 'style'
        });
      }
    });
    $('.clockpicker').clockpicker()
    .find('input').change( (ev) => {
      const el = $(ev.currentTarget);
      const val = $(ev.currentTarget).val().split(":");
      const hour = val[0];
      const min = val[1];


      el.parents(".clockpicker").find("[data-hour='true']").val(hour).trigger("changeClock");
      el.parents(".clockpicker").find("[data-minute='true']").val(min).trigger("changeClock");

    });
    //InputMask.init();
    return this;
  }
  /**
   * Handles validation for select2 selects
   *
   * @param {object} ev
   * @returns this
   * @memberof Form
   */
  handleChangeValidation(ev) {
    if (VALIDATE_FORM) {
      let target = $(ev.currentTarget);
      var parent = target.closest('.form-group');
      if (target.valid()) {
        parent.tooltip('hide');
        parent.removeClass('form-group-is-invalid');
        parent.addClass('form-group-is-valid');
      } else {
        parent.removeClass('form-group-is-valid');
        parent.addClass('form-group-is-invalid');
      }
    }
    return this;
  }
  /**
   * Set active class for form-group, initialize validate for form
   *
   * @param {object} ev - jquery event
   * @returns {object} this
   * @memberof Form
   */
  focusFormGroup(ev) {
    let target = $(ev.currentTarget);
    let parentGroup = target.parents('.form-group');
    let parentForm = target.parents('form');
    if(parentForm.hasClass("ignore-validation")) return;
    if (parentForm.length && VALIDATE_FORM) {
      parentForm.validate();
    }
    if (parentGroup.length) {
      parentGroup.addClass('form-group-active');
    }
    return this;
  }
  /**
   * Remove active class for form-group
   *
   * @param {object} ev - jquery event
   * @returns {object} this
   * @memberof Form
   */
  blurFormGroup(ev) {
    let target = $(ev.currentTarget);
    let parentGroup = target.parents('.form-group');
    let parentForm = target.parents('form');
    if(parentForm.hasClass("ignore-validation")) return;
    if (parentGroup.length) {
      parentGroup.removeClass('form-group-active');
    }
    return this;
  }
  /**
   * Setups $.validator plugin
   *
   * @returns {object} this
   * @memberof Form
   */
  setupValidateForm() {
    $.validator.setDefaults({
      ignore: '',
      showErrors: function(errorMap, errorList) {
        var $this = this;
        $.each(this.successList, function(index, value) {
          var parent = $(this).closest('.form-group');
          if (parent.length) return $(value).popover('hide');
        });
        $.each(errorList, function(index, value) {
          var parent = $(value.element).closest('.form-group');
          if (!parent.length) {
            return $this.defaultShowErrors();
          }

          var _popover;
          if (parent.data('bs.tooltip')) {
            parent.data('bs.tooltip').config.title = value.message;
          } else {
            _popover = parent.tooltip({
              trigger: 'manual',
              placement: 'top',
              html: true,
              container: parent.closest('form'),
              title: value.message,
            });
          }
          parent.removeClass('form-group-is-valid');
          parent.addClass('form-group-is-invalid');
          parent.tooltip('show');
        });
        return true;
      },
      invalidHandler: function(event, validator) {
        let errors = [];
        var errorList = validator.errorList;
        $.each(errorList, function(index, value) {
          let parent = $(value.element).closest('.form-group');
          let el = $(value.element);
          let text = '';
          if(parent.find('label').length) {
            text = parent.find('label').text();
          }
          else if(el.attr("placeholder")) {
            text = el.attr("placeholder");
          }
          errors.push(text + ' : ' + value.message);
        });
        if (errors.length) {
          notification.manipulate({ warning: [errors.join('<br>')] });
        }
      },
      onfocusout: function(element) {
        var parent = $(element).closest('.form-group');
        if ($(element).valid()) {
          parent.tooltip('hide');
          parent.removeClass('form-group-is-invalid');
          parent.addClass('form-group-is-valid');
        } else {
          parent.removeClass('form-group-is-valid');
          parent.addClass('form-group-is-invalid');
        }
      },
      onkeyup: function(element) {
        var parent = $(element).closest('.form-group');
        if ($(element).valid()) {
          parent.tooltip('hide');
          parent.removeClass('form-group-is-invalid');
          parent.addClass('form-group-is-valid');
        } else {
          parent.removeClass('form-group-is-valid');
          parent.addClass('form-group-is-invalid');
        }
      },
      errorPlacement: function(error, element) {
        var parent = $(element).closest('.form-group');
        parent.addClass('form-group-is-invalid');
        error.insertAfter(parent);
      },
    });
    return this;
  }
}

export default new Form;
