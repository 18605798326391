import $ from 'jquery';

const BUTTON = '[data-navigation="collapse"]';
const BTN_GROUP = '.v-buttonGroup';


export const collapseNavigation = (() => {
  $(document).on("click",BUTTON,(ev)=>{
    ev.preventDefault();
    $(BTN_GROUP).toggleClass("active");
  });
})();
