import { Laya, Container } from 'laya-js';
import "bootstrap";


/**
 * HideDiv - Hides div
 * @extends Laya.ActionAjax
 */

class HideDiv extends Laya.ActionAjax {
	constructor() {
		super();
    }
	render(requestResult) {
		this.requestResult = requestResult;
        const div = $(`#${requestResult.params[0]}`);
        if(div.hasClass("collapse")) {
            div.collapse("hide");
        }
        else {
            div.hide();
        }
		
		super.render(requestResult);
	}
}

Container.register('HideDiv', HideDiv);

export default HideDiv;
