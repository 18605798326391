import $ from 'jquery';
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/lang/summernote-cs-CZ';

import { Load, Modal, LoadInto, SaveAndLoad, Reload } from 'laya-js';

import Panel from "../handler/panel";

const initSummernote = () => {
	$('.summernote').summernote({
		toolbar: [
			['style', ['bold', 'italic', 'underline', 'clear']],
			['font', ['strikethrough', 'superscript', 'subscript']],
			['fontsize', ['fontsize']],
			['color', ['color']],
			['para', ['ul', 'ol', 'paragraph']],
			['codeview']
		],
		height: 300,
		lang: 'cs-CZ',
		dialogsInBody: true,
		disableDragAndDrop: true,
		shortcuts: false,
		focus: true, 
		callbacks: { 
			onPaste: function (e) { 
				var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text'); 
				e.preventDefault(); 
				document.execCommand('insertText', false, bufferText); 
			} 
		} 
    });
};

Load.on('componentRendered', initSummernote);
Modal.on('componentRendered', initSummernote);
LoadInto.on('componentRendered', initSummernote);
SaveAndLoad.on('componentRendered', initSummernote);
Reload.on('componentRendered', initSummernote);
Panel.on('componentRendered', initSummernote);

export default (() => {
	$(() => {
		initSummernote();
	});
})();
