import introJs from 'intro.js';
import Cookies from 'js-cookie';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import { Load, LoadInto } from 'laya-js';

const IS_ENABLED = Cookies.get('tutorial');

class Tutorial {
  constructor() {
    document.addEventListener('DOMContentLoaded', this.init.bind(this));
    Load.on('componentPrepared', this.exit.bind(this));
    LoadInto.on('componentPrepared', this.exit.bind(this));
  }
  init() {
    if (IS_ENABLED) {
      this.introPage = $('[data-intro-page]').data('intro-page');
      $(()=>{
        if (!window.localStorage.getItem('intro-page-' + this.introPage)) {

          if(this.introPage == "welcome") {
            setTimeout(()=>{
              window.scrollTo(0,0);
              this.run();
            },100);

          }
          else {
            this.run();
          }
        }
      });
    }
  }
  run() {
    this.intro = introJs.introJs();

    this.intro
      .setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        nextLabel: 'Další &rarr;',
        prevLabel: '&larr; Předchozí',
        skipLabel: 'Přeskočit',
        doneLabel: 'Hotovo',

        showStepNumbers: false,
      })
      .start()
      .onexit(this.handleExit.bind(this));
  }
  handleExit() {
    window.localStorage.setItem('intro-page-' + this.introPage, true);
  }
  exit() {
    if (this.intro) {
      this.intro.exit();
    }
  }
}

export default new Tutorial();
