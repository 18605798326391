import $ from "jquery";

const customerHeader = (()=>{
    let timer = null;
    let lastScrollTop = 0;
    
    $(window).on("scroll", (ev)=> {
        let st = $(window).scrollTop();
        
        if(st > lastScrollTop) {
            if($(window).scrollTop() >= 0) {
                $(".customerHeader").removeClass("active");
            }
        }
        else {
            $(".customerHeader").removeClass("active");
        }
        
        if(timer !== null) {
            clearTimeout(timer);        
        }
        timer = setTimeout(function() {
            if($(window).scrollTop() > 300) {
                $(".customerHeader").addClass("active");
            }
            else {
                $(".customerHeader").removeClass("active");
            }
        }, 500);
        lastScrollTop = st;
    });
})();