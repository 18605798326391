export const serviceFetchData = data => ({
    service: data,
    type: "SERVICE_FETCH_DATA"
});

export const freeOptionsFetchData = data => ({
    freeOptions: data,
    type: "FREE_OPTIONS_FETCH_DATA"
});

export const freeOptionsResetData = () => ({
    freeOptions: null,
    type: "FREE_OPTIONS_FETCH_DATA"
});
export const resourcesFetchData = data => ({
    resources: data,
    type: "RESOURCES_FETCH_DATA"
});
export const employeesFetchData = data => ({
    employees: data,
    type: "EMPLOYEES_FETCH_DATA"
});
export const officesFetchData = data => ({
    offices: data,
    type: "OFFICES_FETCH_DATA"
});

export const resetStore = () => ({
    slot: null, 
    freeOptions: null, 
    type: "RESET_STORE"
});