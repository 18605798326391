import _ from "lodash";
import { combineReducers } from "redux";



const service = function(state = null, action) {
    switch (action.type) {
      case "SERVICE_FETCH_DATA":
        if (!_.isNull(action.service) && action.service.hasOwnProperty("data")) {
          return {
            ...state,
            data: action.service.data
          };
        } else if (action.service === null) {
          return state = null;
        }
        else {
          return { ...state, data: false };
        } 
        break;
      case "RESET_STORE":
        return state = null;
        break;
      default:
        return state;
    }
};
const freeOptions = function(state = null, action) {
  switch (action.type) {
    case "FREE_OPTIONS_FETCH_DATA":
      if (!_.isNull(action.freeOptions) && action.freeOptions.hasOwnProperty("data")) {
        return {
          ...state,
          data: action.freeOptions.data
        };
      } else if (action.freeOptions === null) {
        return state = null;
      }
      else {
        return { ...state, data: false };
      } 
      break;
    case "RESET_STORE":
        return state = null;
        break; 
    default:
      return state;
  }
};
const resources = function(state = null, action) {
  switch (action.type) {
    case "RESOURCES_FETCH_DATA":
      if (!_.isNull(action.resources) && action.resources.hasOwnProperty("data")) {
        
        return {
          ...state,
          data: action.resources.data
        };
      } else {
        
        return state = null;
      }
      break;
    default:
      return state;
  }
};
const offices = function(state = null, action) {
  switch (action.type) {
    case "OFFICES_FETCH_DATA":
      if (!_.isNull(action.offices) && action.offices.hasOwnProperty("data")) {
        
        return {
          ...state,
          data: action.offices.data
        };
      } else {
        return state = null;
      }
      break;
    default:
      return state;
  }
};
const employees = function(state = null, action) {
  switch (action.type) {
    case "EMPLOYEES_FETCH_DATA":
      if (!_.isNull(action.employees) && action.employees.hasOwnProperty("data")) {
        
        return {
          ...state,
          data: action.employees.data
        };
      } else {
        return state = null;
      }
      break;
    default:
      return state;
  }
};
  
const reducer = combineReducers({
  service,
  freeOptions,
  resources,
  employees,
  offices
});
export default reducer;
