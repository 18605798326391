import $ from "jquery";

const handleSlotButton = (() => {
  const SELECT = "[data-slot-template-select='true']";
  const BUTTON = "[data-slot-template-button='true']";
  $(document).on("change", SELECT, (ev) => {
    ev.preventDefault();
    const option = $(ev.currentTarget).find("option:selected");
    $(BUTTON).attr("href",option.data("slot-template-url"));
  });
})();
