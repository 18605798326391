import $ from "jquery";
import {Laya, Container} from "laya-js";


class RedirectSilent extends Laya.ActionAjax {
	constructor(...args) {
        //console.log(args);
		super(...args);
    }
	render(requestResult) {
        //console.log(requestResult);
		if(requestResult.hasOwnProperty("url")) {
            window.history.pushState("", "", requestResult.url);
        }
        super.render(requestResult);
    }
    
}



Container.register('RedirectSilent', RedirectSilent);

export default RedirectSilent;
