import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import {Provider} from "react-redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import moment from "moment";

import {DateRange} from 'react-date-range';
import cs from "date-fns/locale/cs";
import sk from "date-fns/locale/sk";

import {
    serviceFetchData,
    freeOptionsFetchData,
    freeOptionsResetData,
    resetStore,
    resourcesFetchData,
    employeesFetchData,
    officesFetchData
} from "./actions";

import {Container} from "laya-js";

const Router = Container.get("Router");

import store from "./store";

import $ from "jquery";

import Panel from "../../handler/panel";

const Notification = Container.get('Notification');

export class Slot extends React.Component {
    constructor(props) {
        super(props);
    }

    renderOptions() {
        let rows = [];
        let prev = null;
        _.each(this.props.options, (time, key) => {
            const hour = time.split(":")[0];
            if (this.props.respectSlotSettings == 1) {
                if (prev != null && hour != prev && (this.props.slot.selectTimeMode == "any" || this.props.slot.selectTimeMode == "lines" || this.props.slot.selectTimeMode == "fixed-lines")) {
                    let hrkey = "hr_" + key;
                    rows.push(<hr key={hrkey}/>);
                }
            } else {
                if (prev != null && hour != prev) {
                    let hrkey = "hr_" + key;
                    rows.push(<hr key={hrkey}/>);
                }
            }
            let cssClass = 'btn mr-1 btn-sm mb-1 btn-outline-primary';

            if (key == this.props.activeTime && this.props.activeSlotId == this.props.slot.id) {
                cssClass = 'btn mr-1 btn-sm mb-1 btn-primary';
            }
            rows.push(<button onClick={ev => this.props.slotClick(time, key, this.props.slot)} key={key} type="button"
                              className={cssClass}>{time}</button>);
            prev = hour;
        });
        return rows;
    }

    render() {
        return (
            <div className="col-lg-12">
                {this.props.showDay &&
                    <div className="card rounded-0 bg-primary mt-4">
                        <div className="card-body">
                            <h4 className="card-title text-white"><i
                                className="fas fa-calendar-alt"></i> {this.props.slot.date}</h4>
                        </div>
                    </div>
                }
                {this.renderItem()}
            </div>

        );
    }

    renderItem() {
        const colors = {
            'gray': '#495057',
            'red': '#dc3545',
            'green': '#8DB75D',
            'blue': '#2061B5',
            'yellow': '#d8860b',
            'pink': '#D43C84',
        };
        const bg = {background: colors[this.props.slot.color]};
        const capacityColor = `badge ${this.props.slot.capacityColor} mr-1`;
        return (
            <div className="card rounded-0">
                <div className="card-header d-flex flex-column flex-lg-row justify-content-between pb-0">
                    <div>
                        <span style={bg} className="badge badge-primary"><i
                            className="fas fa-tag"></i> {this.props.slot.name}</span>
                    </div>
                    <div className="card-controls">
                        <span
                            className={capacityColor}>{window.TRANSLATIONS.capacity} {this.props.slot.freeCapacity}/{this.props.slot.totalCapacity}</span>
                        {!_.isEmpty(this.props.slot.paidPriceWithoutVatService) && <span
                            className="badge badge-success text-white mr-1">{window.TRANSLATIONS.priceService} {this.props.slot.paidPriceWithoutVatService}</span>}
                        {!_.isEmpty(this.props.slot.paidPriceWithoutVatSlot) && <span
                            className="badge badge-success text-white mr-1">{window.TRANSLATIONS.priceSlot} {this.props.slot.paidPriceWithoutVatSlot}</span>}
                        {!_.isEmpty(this.props.slot.paidPriceWithoutVat) && <span
                            className="badge badge-success text-white mr-1">{window.TRANSLATIONS.price} {this.props.slot.paidPriceWithoutVat}</span>}
                    </div>
                </div>
                <div className="card-body">
                    {this.renderOptions()}
                    <div className='d-flex justify-content-between border-top mt-2 pb-1'>
                        <div><span
                            className="badge badge-secondary">{window.TRANSLATIONS.offices}: {this.props.slot.office}</span>
                        </div>
                        <a className="btn btn-outline-primary btn-sm d-none d-lg-inline-block" target="_blank"
                           href={this.props.slot.calendarLink}><i
                            className="fas fa-calendar-day"></i> {window.TRANSLATIONS.showCalendar}</a></div>
                </div>
            </div>
        );
    }
}

class BookingSimple extends React.Component {
    constructor(props) {
        super(props);
        let service = "empty";
        let minutes = "empty";
        if (props.activeService) {
            service = props.activeServiceId;
            minutes = props.activeLenghtMinutes;
        }
        let timeCustom = false;
        let time = "empty";
        if (props.startDatetime) {
            timeCustom = true;
            time = props.startDatetime;
        }

        let slotId = "empty";
        if (props.slotId) {
            slotId = props.slotId;
        }
        this.state = {
            selectedTimes: [],
            service: service,
            minutes: minutes,
            minutesCustom: null,
            time: time,
            timeCustom: timeCustom,
            slotId: slotId,
            date: [{
                startDate: null,
                endDate: null,
                key: 'selection'
            }],
            startDate: moment().format("DD.MM.YYYY"),
            endDate: null,
            step: 0,
            isMultiple: false,
            resources: [],
            employees: [],
            offices: [],
            weekdays: [
                {value: 0, isChecked: false, name: window.TRANSLATIONS.monday},
                {value: 1, isChecked: false, name: window.TRANSLATIONS.tuesday},
                {value: 2, isChecked: false, name: window.TRANSLATIONS.wednesday},
                {value: 3, isChecked: false, name: window.TRANSLATIONS.thursday},
                {value: 4, isChecked: false, name: window.TRANSLATIONS.friday},
                {value: 5, isChecked: false, name: window.TRANSLATIONS.saturday},
                {value: 6, isChecked: false, name: window.TRANSLATIONS.sunday}
            ],
            allowSlotsWithoutOrders: 1,
            registeredPatient: 1,
            respectSlotSettings: 1,
        };
        this.removeSlot = this.removeSlot.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.handleSubmitMinutes = this.handleSubmitMinutes.bind(this);
        this.handleChangeWeekdays = this.handleChangeWeekdays.bind(this);
        this.handleEmployeesPart = this.handleEmployeesPart.bind(this);
        this.handleResourcesPart = this.handleResourcesPart.bind(this);
        this.handleOfficesPart = this.handleOfficesPart.bind(this);
        this.handleMoveStep = this.handleMoveStep.bind(this);
        this.resetTime = this.resetTime.bind(this);
        this.handleChangeWithoutOrders = this.handleChangeWithoutOrders.bind(this);
        this.handleChangeRegisteredPatient = this.handleChangeRegisteredPatient.bind(this);
        this.handleChangeRespectSlotSettings = this.handleChangeRespectSlotSettings.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.minutesRef = React.createRef();
    }

    onKeyUp(event) {

        if (event.charCode === 13) {
            let slotId = "empty";
            if (this.props.slotId) {
                slotId = this.props.slotId;
            }
            let time = "empty";
            if (this.state.timeCustom) {
                time = this.props.startDatetime;
            }
            event.preventDefault();
            if (parseInt(event.target.value) > 0) {
                this.setState(
                    {
                        minutesCustom: event.target.value,
                        time: time,
                        slotId: slotId,
                    }
                );
            } else {
                alert(window.TRANSLATIONS.numberBiggerZero);
            }
        }
    }

    componentDidMount() {
        this.props.fetchServices(this.props.servicesUrl);
        this.props.fetchFilterData(this.props.employeesUrl, this.props.resourcesUrl, this.props.officesUrl);
        if (this.props.activeServiceId && this.state.timeCustom == false) {
            this.props.fetchFreeOptions(this.constructFetchFreeOptionsUrl());
        }
    }

    removeSlot(selectedTime) {
        const selectedTimes = [...this.state.selectedTimes];
        if (selectedTimes.find((r) => r.slotId == selectedTime.slotId && r.datetime == selectedTime.datetime)) {
            const index = selectedTimes.findIndex((r) => r.slotId == selectedTime.slotId && r.datetime == selectedTime.datetime);
            selectedTimes.splice(index, 1)
            this.setState({
                selectedTimes: selectedTimes
            });
        }
    }

    resetTime(event) {
        event.preventDefault();
        this.setState({
            time: "empty",
            timeCustom: false
        });
    }

    handleChange(ev, row) {
        ev.preventDefault();
        let time = "empty";
        if (this.state.timeCustom) {
            time = this.props.startDatetime;
        }
        this.setState({
            service: row.value,
            minutes: row.minutes,
            isMultiple: row.isMultiple,
            minutesCustom: null,
            selectedTimes: [],
            time: time
        });

    }

    handleChangeOptions(time, datetime, slot) {

        if (this.props.isMultipleView) {
            if (this.state.isMultiple) {
                const selectedTimes = [...this.state.selectedTimes];
                if (!selectedTimes.find((r) => r.slotId == slot.id && r.datetime == datetime)) {
                    selectedTimes.push({
                        slotId: slot.id,
                        time,
                        ...(this.state.minutesCustom && {
                            customLengthMinutes: this.state.minutesCustom
                        }),
                        datetime,
                        dateHuman: slot.date,
                        url: slot.url,
                        serviceId: slot.serviceId
                    });
                    this.setState({
                        selectedTimes: selectedTimes
                    });
                }
            } else {
                console.log(this.state.minutesCustom);
                const selectedTimes = [{
                    slotId: slot.id,
                    time,
                    ...(this.state.minutesCustom && {
                        customLengthMinutes: this.state.minutesCustom
                    }),
                    datetime,
                    dateHuman: slot.date,
                    url: slot.url,
                    serviceId: slot.serviceId
                }];
                console.log(selectedTimes);
                this.setState({
                    selectedTimes: selectedTimes
                });
            }
        } else {
            this.setState({
                time: datetime,
                slotId: slot.id
            });
        }
        /*
        $(document).animate({
            scrollTop: $("#finalPart").scrollTop()
        }, 1000);
        */

    }

    handleSubmitMinutes(event, remove) {
        event.preventDefault();
        let slotId = "empty";
        if (this.props.slotId) {
            slotId = this.props.slotId;
        }
        let time = "empty";
        if (this.state.timeCustom) {
            time = this.props.startDatetime;
        }
        if (remove == false) {
            if (parseInt(this.minutesRef.current.value) > 0) {
                this.setState(
                    {
                        minutesCustom: this.minutesRef.current.value,
                        time: time,
                        slotId: slotId
                    }
                );
            } else {
                alert(window.TRANSLATIONS.numberBiggerZero);
            }
        } else {
            this.minutesRef.current.value = '';
            this.setState(
                {
                    minutesCustom: null,
                    time: time,
                    slotId: slotId
                }
            );
        }
    }

    handleChangeDate(item) {
        const startDate = moment(item.selection.startDate).format("DD.MM.YYYY");
        const endDate = moment(item.selection.endDate).format("DD.MM.YYYY");
        this.setState({
            date: [item.selection],
            startDate: startDate,
            endDate: endDate,
            step: 0
        });

        return this;
    }

    handleChangeWeekdays(event, day) {
        const val = day.value;
        let state = _.cloneDeep(this.state.weekdays);

        state.map((row, index) => {
            if (row.value == val) {
                row.isChecked = !row.isChecked;
            }
            return row;
        });

        this.setState({
            weekdays: state,
            step: 0
        });
        return this;
    }

    handleEmployeesPart(event, row) {
        event.preventDefault();
        let res = [];
        if (row != false) {
            res = [...this.state.employees];
            if (res.indexOf(row.value) != -1) {
                res.splice(res.indexOf(row.value), 1);
            } else {
                res.push(row.value);
            }
        }
        this.setState({
            employees: res,
        });
        return this;
    }

    handleResourcesPart(event, row) {
        event.preventDefault();
        let res = [...this.state.resources];
        if (res.indexOf(row.value) != -1) {
            res.splice(res.indexOf(row.value), 1);
        } else {
            res.push(row.value);
        }
        this.setState({
            resources: res,
        });
        return this;
    }

    handleOfficesPart(event, row) {
        event.preventDefault();
        let res = [...this.state.offices];
        if (res.indexOf(row.value) != -1) {
            res.splice(res.indexOf(row.value), 1);
        } else {
            res.push(row.value);
        }
        this.setState({
            offices: res,
        });
        return this;
    }

    handleMoveStep(ev, num) {
        ev.preventDefault();
        let step = _.clone(this.state.step);
        if (num > 0) {
            step++;
        } else {
            step--;
        }

        this.setState(
            {
                step: step
            }
        )
    }

    handleChangeWithoutOrders(ev) {
        ev.preventDefault();
        let val = _.clone(this.state.allowSlotsWithoutOrders);
        if (val == 1) {
            val = 0;
        } else {
            val = 1;
        }
        this.setState({
            allowSlotsWithoutOrders: val
        });
        return this;
    }

    handleChangeRespectSlotSettings(ev) {
        ev.preventDefault();
        let val = _.clone(this.state.respectSlotSettings);
        if (val == 1) {
            val = 0;
        } else {
            val = 1;
        }
        this.setState({
            respectSlotSettings: val
        });
        return this;
    }

    handleChangeRegisteredPatient(ev) {
        ev.preventDefault();
        let val = _.clone(this.state.registeredPatient);
        if (val == 1) {
            val = 0;
        } else {
            val = 1;
        }
        this.setState({
            registeredPatient: val
        });
        return this;
    }

    constructFetchFreeOptionsUrl() {
        const slotUrl = this.props.freeOptionsUrl.replace(/step\/\d/, "step/" + this.state.step);
        const url = new URL(slotUrl);
        const starDate = this.state.startDate;
        const endDate = this.state.endDate;
        const weekdays = this.state.weekdays;
        const minutesCustom = this.state.minutesCustom;
        const serviceId = this.state.service;
        if (this.state.secondPartValue != "empty") {
            url.searchParams.append("serviceId", serviceId);
        }
        if (!_.isNull(starDate)) {
            url.searchParams.append("dateFrom", starDate);
        }
        if (!_.isNull(endDate)) {
            url.searchParams.append("dateUntil", endDate);
        }

        if (!_.isNull(weekdays)) {
            _.each(weekdays, (day, index) => {
                if (day.isChecked == true) {
                    url.searchParams.append("weekdays[]", day.value);
                }

            });
        }
        if (!_.isNull(this.state.minutesCustom)) {
            url.searchParams.append('minutesCustom', minutesCustom);
        }
        _.each(this.state.employees, (row, index) => {
            url.searchParams.append("employees[]", row);
        });
        _.each(this.state.resources, (row, index) => {
            url.searchParams.append("resources[]", row);
        });
        _.each(this.state.offices, (row, index) => {
            url.searchParams.append("offices[]", row);
        });

        url.searchParams.append("allowSlotsWithoutOrders", this.state.allowSlotsWithoutOrders);
        url.searchParams.append("registeredPatient", this.state.registeredPatient);
        url.searchParams.append("respectSlotSettings", this.state.respectSlotSettings);
        return url.href;

    }

    componentDidUpdate(prevProps, prevState) {
        let min = this.state.minutesCustom != null ? this.state.minutesCustom : this.state.minutes;
        if (this.state.timeCustom == false &&
            (prevState.service != this.state.service ||
                prevState.minutes != this.state.minutes ||
                prevState.minutesCustom != this.state.minutesCustom ||
                prevState.startDate != this.state.startDate ||
                prevState.endDate != this.state.endDate ||
                !_.isEqual(prevState.weekdays, this.state.weekdays) ||
                prevState.step != this.state.step ||
                prevState.employees != this.state.employees ||
                prevState.resources != this.state.resources ||
                prevState.offices != this.state.offices ||
                prevState.registeredPatient != this.state.registeredPatient ||
                prevState.allowSlotsWithoutOrders != this.state.allowSlotsWithoutOrders ||
                prevState.timeCustom != this.state.timeCustom ||
                prevState.respectSlotSettings != this.state.respectSlotSettings)
        ) {
            this.props.fetchFreeOptions(this.constructFetchFreeOptionsUrl());
        } else if (this.state.timeCustom == true && this.state.service != prevState.service) {
            const url = this.props.checkDatesUrl
                .replace("__MINUTES__", min)
                .replace("__SLOT_ID__", this.state.slotId)
                .replace("__START_DATETIME__", this.props.startDatetime);
            Router.navigateManualy(url);
        }
        let showLastPart = false;
        if (this.state.timeCustom === false && this.state.service != "empty" && this.state.minutes != "empty") {
            if (this.props.isMultipleView && this.state.selectedTimes.length > 0) {
                showLastPart = true;
            }
            if (!this.props.isMultipleView && this.state.time != "empty") {
                showLastPart = true;
            }

        }
        if (this.state.timeCustom === true && this.state.service != "empty") {
            showLastPart = true;
        }
        if (this.props.isMultipleView && this.state.selectedTimes != prevState.selectedTimes) {
            const selectedTimes = this.state.selectedTimes;
            $('[data-order-simple-start-placements="true"]').val(JSON.stringify(selectedTimes.map((item) => ({
                slotId: item.slotId,
                serviceId: item.serviceId,
                ...(item.customLengthMinutes && {
                    customLengthMinutes: item.customLengthMinutes
                }),
                datetime: item.datetime,
                originServiceId: null,
                originCompanyId: null
            }))));
        }

        if (this.state.time == "empty" && prevState.time != "empty") {
            showLastPart = false;
        }
        if (showLastPart) {
            if (!this.props.isMultipleView) {
                $('[data-order-simple-service-id="true"]').val(this.state.service);
                $('[data-order-simple-minutes="true"]').val(min);
                $('[data-order-simple-start-datetime="true"]').val(this.state.time);
                $('[data-order-simple-slot-id="true"]').val(this.state.slotId);
            }
            if ($('[data-order-simple-step="last"]').hasClass('show')) {
                $('#finalPart')[0].scrollIntoView({behavior: 'smooth', block: 'start'});
            } else {
                $('[data-order-simple-step="last"]').collapse("show");
                $('[data-order-simple-step="last"]').on('shown.bs.collapse', () => {
                    $('#finalPart')[0].scrollIntoView({behavior: 'smooth', block: 'start'});
                });
            }
        } else {
            $('[data-order-simple-step="last"]').collapse("hide");
        }
    }

    renderFilterPart() {
        /*<button type="button" data-toggle="collapse" data-target="#filterWeeks" className="btn btn-outline-primary">{window.TRANSLATIONS.moreFilter}</button>*/

        let calLang = cs;
        if (LANG == "sk_SK.UTF-8") {
            calLang = sk;
        }
        return (
            <div className="d-flex flex-column">

                <DateRange
                    locale={calLang}
                    editableDateInputs={false}
                    minDate={new Date()}
                    showSelectionPreview={false}
                    onChange={item => this.handleChangeDate(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={this.state.date}
                    endDatePlaceholder={window.TRANSLATIONS.notDefined}
                    startDatePlaceholder={window.TRANSLATIONS.notDefined}
                />

                <div className="collapse show mb-2" id="filterWeeks">
                    {this.renderOfficesPart()}
                    <hr/>
                    {this.renderWeekdays()}
                    <hr/>
                    {this.renderAllowSlotsWithoutOrders()}
                    <hr/>
                    {this.renderRespectSlotSettings()}
                    <hr/>
                    {this.renderRegisteredPatients()}
                </div>

            </div>
        );


    }

    renderRespectSlotSettings() {
        let cssClass = 'btn btn-primary';
        if (this.state.respectSlotSettings == false) {
            cssClass = 'btn btn-outline-primary';
        }
        return (
            <div>
                <h4 className="card-title mb-2">{window.TRANSLATIONS.respectSlotSettings}</h4>
                <button className={cssClass} type="checkbox" onClick={this.handleChangeRespectSlotSettings}><i
                    className='fas fa-check'></i> {window.TRANSLATIONS.yes}</button>
            </div>
        )
    }

    renderAllowSlotsWithoutOrders() {
        let cssClass = 'btn btn-primary';
        if (this.state.allowSlotsWithoutOrders == false) {
            cssClass = 'btn btn-outline-primary';
        }
        return (
            <div>
                <h4 className="card-title mb-2">{window.TRANSLATIONS.allowSlotsWithoutOrders}</h4>
                <button className={cssClass} type="checkbox" onClick={this.handleChangeWithoutOrders}><i
                    className='fas fa-check'></i> {window.TRANSLATIONS.yes}</button>
            </div>
        )
    }

    renderRegisteredPatients() {
        let cssClass = 'btn btn-primary';
        if (this.state.registeredPatient == false) {
            cssClass = 'btn btn-outline-primary';
        }
        return (
            <div>
                <h4 className="card-title mb-2">{window.TRANSLATIONS.registeredPatient}</h4>
                <button className={cssClass} type="checkbox" onClick={this.handleChangeRegisteredPatient}><i
                    className='fas fa-check'></i> {window.TRANSLATIONS.yes}</button>
            </div>
        )
    }

    renderWeekdays() {
        let rows = [];
        rows.push(<h4 key="00" className='card-title mb-2'>{window.TRANSLATIONS.days}</h4>);
        _.each(this.state.weekdays, (day, index) => {
            let cssClass = 'btn btn-outline-primary mr-1 mb-1';
            if (day.isChecked) {
                cssClass = 'btn btn-primary mr-1 mb-1';
            }
            rows.push(<button className={cssClass} key={index} type="button"
                              onClick={ev => this.handleChangeWeekdays(ev, day)}><i
                className='fas fa-calendar-day'></i> {day.name}</button>)
        });
        return rows;
    }

    renderResourcesPart() {
        if (!_.isNull(this.props.resources) && this.props.resources.hasOwnProperty("data")) {
            let rows = [];
            _.each(this.props.resources.data, (row, index) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (this.state.resources.indexOf(row.value) != -1) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                rows.push(<button onClick={ev => this.handleResourcesPart(ev, row)} className={cssClass} type="button"
                                  key={index}><i className="fas fa-clinic-medical"></i> {row.name}</button>);
            });
            return (
                <div className="order-part">
                    <h4 id="employeesPart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.resources}</h4>
                    {rows}
                </div>
            )
        } else {
            return (null);
        }

    }

    renderOfficesPart() {
        if (!_.isNull(this.props.offices) && this.props.offices.hasOwnProperty("data")) {
            let rows = [];
            rows.push(<h4 key="00" className='card-title mb-2'>{window.TRANSLATIONS.offices}</h4>);
            _.each(this.props.offices.data, (row, index) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (this.state.offices.indexOf(row.value) != -1) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                rows.push(<button onClick={ev => this.handleOfficesPart(ev, row)} className={cssClass} type="button"
                                  key={index}><i className="fas fa-notes-medical"></i> {row.name}</button>);
            });
            return rows;
        } else {
            return (null);
        }

    }

    renderSteps() {
        //  && this.props.freeOptions.data.length > 9 nemůže fungovat, pokud nejsou zadaná data od - do
        if (!_.isNull(this.props.freeOptions)) {
            return (
                <div className="mt-4 d-flex justify-content-center">
                    <div className="btn-group">
                        {this.state.step > 0 && <button onClick={ev => this.handleMoveStep(ev, -1)} type="button"
                                                        className="btn btn-outline-primary">{window.TRANSLATIONS.prev}</button>}
                        {this.props.freeOptions.data !== false &&
                            <button type="button" onClick={ev => this.handleMoveStep(ev, 1)}
                                    className="btn btn-outline-primary">{window.TRANSLATIONS.more}</button>}
                    </div>
                </div>
            );
        } else {
            return (null)
        }
    }

    renderEmployeesPart() {
        if (this.state.secondPartValue != "empty" && !_.isNull(this.props.employees) && this.props.employees.data != false) {
            let rows = [];
            _.each(this.props.employees.data, (row, index) => {
                let cssClass = 'btn mr-1 btn-outline-primary';
                if (this.state.employees.indexOf(row.value) != -1) {
                    cssClass = 'btn mr-1 btn-primary';
                }
                rows.push(<button type="button" onClick={ev => this.handleEmployeesPart(ev, row)} className={cssClass}
                                  key={index}><i className="fas fa-user-md"></i> {row.name}</button>);
            });
            let cssClassDefault = 'btn mr-1 btn-outline-primary';
            if (_.isEmpty(this.state.employees)) {
                cssClassDefault = 'btn mr-1 btn-primary';
            }
            return (
                <div className="order-part">
                    <h4 id="employeesPart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.chooseEmployee}</h4>
                    <button type="button" onClick={ev => this.handleEmployeesPart(ev, false)}
                            className={cssClassDefault} key="00"><i
                        className="fas fa-user-md"></i> {window.TRANSLATIONS.all}</button>
                    {rows}
                </div>
            )
        } else {
            return (null);
        }
    }

    renderServices() {
        let rows = [];
        const colors = {
            'gray': '#495057',
            'red': '#dc3545',
            'green': '#8DB75D',
            'blue': '#2061B5',
            'yellow': '#d8860b',
            'pink': '#D43C84',
        };

        /*
        else {
            */
        if (_.isNull(this.props.services)) {
            rows.push(<div key="00" className="alert alert-warning"><i
                className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}</div>);
        } else {
            const isInArray = _.find(this.props.services.data, {value: this.props.activeServiceId});
            if (this.props.activeServiceId && typeof isInArray == "undefined") {
                let mockRow = {
                    value: this.props.activeServiceId,
                    minutes: this.props.activeLenghtMinutes
                }
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                if (this.props.activeServiceId == this.state.service) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                }
                rows.push(<button key="00" onClick={ev => this.handleChange(ev, mockRow)} className={cssClass}><i
                    className="fas fa-tags"></i> {this.props.activeService} ({this.props.activeLenghtMinutes}min)
                </button>)
            }
            _.each(this.props.services.data, (row, index) => {
                let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
                let style = {
                    color: colors[row.color]
                };
                if (row.value == this.state.service) {
                    cssClass = 'btn mr-1 mb-1 btn-primary';
                    style = {
                        color: colors[row.color]
                    }
                }
                let icon = "fas fa-eye";
                if (row.visibility == "internal") {
                    icon = "fas fa-eye-slash";
                }
                rows.push(<button data-toggle="tooltip" title={row.visibilityHuman}
                                  onClick={ev => this.handleChange(ev, row)} className={cssClass} key={index}
                                  type="button" data-value={row.minutes}><i style={style}
                                                                            className="fas fa-tags"></i> {row.name} ({row.minutes}min) <span
                    className="badge badge-dark"><i className={icon}></i></span></button>);
            });
        }
        //}   
        return rows;
    }

    renderFreeOptions() {
        if (!_.isNull(this.props.freeOptions) && this.props.freeOptions.data !== false) {
            let rows = [];
            let prevDate = null;
            let showDay = false;
            _.each(this.props.freeOptions.data, (row, key) => {
                const date = moment(row.slot.startDatetime * 1000).format("DD.MM.YYYY");
                if (prevDate != date) {
                    prevDate = date;
                    showDay = true;
                } else {
                    showDay = false;
                }
                rows.push(<Slot showDay={showDay} respectSlotSettings={this.state.respectSlotSettings}
                                activeSlotId={this.state.slotId} activeTime={this.state.time} key={key}
                                slotClick={this.handleChangeOptions} {...row} />);
            });
            return rows;
        } else if (!_.isNull(this.props.freeOptions) && this.props.freeOptions.data === false) {
            return (<div className="alert alert-warning">{window.TRANSLATIONS.noDates}</div>);
        } else {
            return (null);
        }
    }

    render() {
        const startDatetime = null;
        return (
            <div>
                <div className="order-part">
                    <h4 id="servicePart" className="card-title card-title-big mb-2"><span
                        className="badge badge-pill badge-primary">1</span> {window.TRANSLATIONS.chooseService}</h4>
                    {this.renderServices()}
                </div>
                {this.state.service != "empty" &&
                    <div className="order-part">
                        <h4 id="minutesPart" className="card-title card-title-big mb-2"><span
                            className="badge badge-pill badge-primary">2</span> {window.TRANSLATIONS.customMinutes}</h4>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                {!_.isNull(this.state.minutesCustom) &&
                                    <button onClick={ev => this.handleSubmitMinutes(ev, true)} type="button"
                                            className="btn btn-outline-primary"><i className="fas fa-times"></i>
                                    </button>
                                }
                                <button onClick={ev => this.handleSubmitMinutes(ev, false)} type="button"
                                        className="btn btn-outline-primary"><i className="fas fa-check"></i></button>
                            </div>
                            <input min="1" type="number" ref={this.minutesRef} className="form-control pb-0"
                                   onKeyPress={this.onKeyUp} placeholder={window.TRANSLATIONS.minutes}/>

                        </div>
                    </div>}
                {this.renderResourcesPart()}
                {this.renderEmployeesPart()}

                {this.state.service != "empty" && this.state.timeCustom === false &&
                    <div className="order-part">
                        <h4 id="timePart" className="card-title card-title-big mb-2"><span
                            className="badge badge-pill badge-primary">3</span> {window.TRANSLATIONS.chooseDates}</h4>
                        <h4 className='card-title mb-2'>{window.TRANSLATIONS.from} - {window.TRANSLATIONS.to}</h4>
                        <div className="row">
                            <div className="col-lg-5">
                                {this.renderFilterPart()}
                            </div>
                            {!_.isNull(this.props.freeOptions) &&
                                <div className="col-lg-7">
                                    <div className="row">
                                        {this.renderFreeOptions()}
                                    </div>
                                    {this.renderSteps()}
                                </div>
                            }
                            {_.isNull(this.props.freeOptions) &&
                                <div className="col-lg-7">
                                    <div className="alert alert-warning"><i
                                        className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}</div>
                                </div>
                            }
                        </div>
                    </div>}
                {this.state.service != "empty" && this.state.timeCustom &&
                    <div className="order-part">
                        <h4 id="timePart" className="card-title card-title-big mb-2"><span
                            className="badge badge-pill badge-primary">3</span> {window.TRANSLATIONS.chosenDate}</h4>
                        <button type="button"
                                className="btn btn-primary">{moment(this.props.startDatetime * 1000).format("DD.MM.YYYY HH:mm")}</button>
                        <button type="button" className="btn btn-outline-primary" onClick={this.resetTime}><i
                            className="fas fa-times"></i> {window.TRANSLATIONS.choseAnotherDate}</button>
                    </div>
                }
                {this.props.isMultipleView && this.state.selectedTimes.length > 0 &&
                    (<div className="order-part">
                        <h4 id="timePartChosen" className="card-title card-title-big mb-2"><span
                            className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.chosenDate}</h4>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'d-flex'}>
                                    {this.state.selectedTimes.map((row, index) => (
                                        <button key={index} onClick={() => this.removeSlot(row)}
                                                className={'btn btn-sm btn-outline-primary mr-1'}><i
                                            className="fas fa-times"/> {row.dateHuman} {row.time}</button>))}
                                </div>
                            </div>
                        </div>
                    </div>)}
            </div>
        );
    }
}

BookingSimple.contextTypes = {store: PropTypes.object};
BookingSimple.propTypes = {
    serviceUrl: PropTypes.string,
    resourcesUrl: PropTypes.string,
    employeesUrl: PropTypes.string,
    officesUrl: PropTypes.string,
};
const mapStateToProps = store => {
    return {
        services: store.service,
        freeOptions: store.freeOptions,
        resources: store.resources,
        employees: store.employees,
        offices: store.offices,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchFilterData(employeesUrl, resourcesUrl, officesUrl) {

            $.getJSON(employeesUrl).done((result) => {
                dispatch(employeesFetchData(result));
            });
            $.getJSON(resourcesUrl).done((result) => {
                dispatch(resourcesFetchData(result));
            });
            $.getJSON(officesUrl).done((result) => {
                dispatch(officesFetchData(result));
            });
        },
        fetchServices: (url) => {
            $.getJSON(url).done((result) => {
                dispatch(serviceFetchData(result));
                $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
            });
        },
        fetchFreeOptions: (urlTemp, minutes) => {
            dispatch(freeOptionsResetData());
            const url = urlTemp.replace("__TIME__", minutes);
            $.getJSON(url).done((result) => {
                dispatch(freeOptionsFetchData(result));
                if (result.hasOwnProperty("notification")) {
                    _.each(result.notification.messages, (messages, type) => {
                        Notification.create(type, messages);
                    });
                }
                $("#timePart")[0].scrollIntoView({behavior: 'smooth', block: 'start'});
            });
        }
    }
};
const BookingConnectedSimple = connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingSimple);

class BookingContainerSimple extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <BookingConnectedSimple {...this.props} />
            </Provider>
        );
    }
}

//Initializer
export const initBooking = () => {
    if ($("#componentBookingSimple").length) {
        const servicesUrl = $("#componentBookingSimple").data("fetch-services");
        const multipleBooking = $("#componentBookingSimple").data("multiple-booking") ?? false;
        const freeOptionsUrl = $("#componentBookingSimple").data("fetch-free-options");
        const service = $("#componentBookingSimple").data("service");
        const serviceId = $("#componentBookingSimple").data("service-id");
        const slotId = $("#componentBookingSimple").data("slot-id");
        const customer = $("#componentBookingSimple").data("customer");
        const customerId = $("#componentBookingSimple").data("customer-id");
        const lenghtMinutes = $("#componentBookingSimple").data("minutes");
        const resourcesUrl = $("#componentBookingSimple").data("resources-url");
        const employeesUrl = $("#componentBookingSimple").data("employees-url");
        const officesUrl = $("#componentBookingSimple").data("offices-url");
        const startDatetime = $("#componentBookingSimple").data("start-datetime");
        const checkDatesUrl = $("#componentBookingSimple").data("check-dates-url");

        ReactDOM.render(
            React.createElement(BookingContainerSimple,
                {
                    isMultipleView: multipleBooking,
                    servicesUrl: servicesUrl,
                    freeOptionsUrl: freeOptionsUrl,
                    activeService: service,
                    activeServiceId: serviceId,
                    activeCustomer: customer,
                    activeCustomerId: customerId,
                    activeLenghtMinutes: lenghtMinutes,
                    slotId: slotId,
                    resourcesUrl: resourcesUrl,
                    employeesUrl: employeesUrl,
                    officesUrl: officesUrl,
                    startDatetime: startDatetime,
                    checkDatesUrl: checkDatesUrl
                }
            ),
            $("#componentBookingSimple")[0]
        );
    }
};
Panel.on("componentRendered", () => {

    //Temp fix, nevím proč to blbne...
    setTimeout(() => {
        initBooking();
    }, 100);

});
Panel.on("componentDestroyed", () => {
    if ($("#componentBookingSimple").length) {

        ReactDOM.unmountComponentAtNode($("#componentBookingSimple")[0]);
        store.dispatch(resetStore())
    }
});
$(() => {
    initBooking();
});
