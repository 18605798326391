import $ from 'jquery';

const SELECTOR_ORIGIN = '[data-copy-value-to]';
const SELECTOR_ORIGIN_BIRTHDATE = '[data-copy-birthdate-to]';
/**
 * Handles blur event on input and copies a value from one input to another one
 */
class CopyInputValue {
  constructor() {
    $(document).on('blur', SELECTOR_ORIGIN, this.copyValue.bind(this));
    $(document).on('blur', SELECTOR_ORIGIN_BIRTHDATE, this.copyBirthDateValue.bind(this));
  }
  /**
   * Copies value from one iput value to another input
   * @param {object} ev  -blur event
   * @returns {bool}
   */
  copyValue(ev) {
    let target = $(ev.currentTarget);
    if (target.val().length == 0) return false;
    let targetEl = $($(target).data('copy-value-to'));
    if (targetEl.val().length == 0) {
      targetEl.val(target.val());
      return true;
    }
  }
  copyBirthDateValue(ev) {
    let target = $(ev.currentTarget);
    if (target.val().length == 0) return false;
    let targetEl = $($(target).data('copy-birthdate-to'));
    //if (targetEl.val().length == 0) {
      let birthnumber = target.val();
      targetEl.val(this.parseBirthNumber(target.val()));
      return true;
    //}
  }
  parseBirthNumber(num) {
    let year = parseInt(num.substr(0, 2), 10);
    let month = parseInt(num.substr(2, 2), 10);
    let day = parseInt(num.substr(4, 2), 10);
    if(num.length==9) {
      year += year < 53 ? 1900 : 1800;
    }
    else {
      year += year < 53 ? 2000 : 1900;
    }
    if (day > 50) day -= 50;
    if (month > 70 && year > 2003) month -= 70;
    else if (month > 50) month -= 50;
    else if (month > 20 && year > 2003) month -= 20;
    return this.padNumber(day)+"."+this.padNumber(month)+"."+this.padNumber(year);
  }
  padNumber(n){
    return n<10 ? '0'+n : n
  }
}

export default new CopyInputValue();
