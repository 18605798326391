import $ from 'jquery';
import 'bootstrap';
import { Container } from 'laya-js';
import React from 'react';
import ReactDOM from 'react-dom';

import 'pace-progress';

//Handlers classes

import Notification from './handler/notification';

//Handlers instances

import './handler/router/';
import './handler/login';
import './handler/closeModal';
import './handler/openInNewTab';
import './handler/showInput';
import './handler/hideInput';
import './handler/redirectSilent';
import './handler/showDiv';
import './handler/hideDiv';
import './handler/setText';
import './handler/orderSimple';
import './handler/orderUpdated';
import './handler/setSelectOptions';
import './handler/panel';
import './handler/form/';

//Connections classess and instances
import Ajax from './handler/connection/ajax';
import './handler/connection/socket'; //socket instance

//Loader React component
import Loader from './components/loader';

import './components/booking';
import './components/calendar';
import './components/booking/widget';
import './components/bookingSimple';
import './components/phone';

//UI instances
import './ui/autocomplete';
import './ui/activateLink';
import './ui/browser';
import './ui/customer';
import './ui/birthNumberSwitch';
import './ui/tableControls';
import './ui/chart';
import './ui/changeCollapseButton';
import './ui/addPackage';
import './ui/datePicker';
import './ui/worklist';
import './ui/downloadBase64';
//import './ui/externalLink'; //nebude moc dobře fungovat
import './ui/modal';
import './ui/filterWeek';
import './ui/bookingPatientAdd';
import './ui/tutorial';
import './ui/wysiwyg';
import './ui/calendar';
import './ui/copyInputValue';
import './ui/rescheduleOrders';
import './ui/copyToClipboard';
import './ui/slot';
import './ui/dataUri';
import './ui/dependableInput';
import './ui/tooltip';
import './ui/popover';
import './ui/upload';
import './ui/navigation';
import './ui/resetPassword';
import './ui/fillCustomer';

document.addEventListener('DOMContentLoaded', event => {
  //Functions
  Notification();
  if ($('#componentAppLoader').length) {
    ReactDOM.render(React.createElement(Loader), document.getElementById('componentAppLoader'));
  }
  //Init Classes
  new Ajax();
});


/*

useEffect(()=> {

        const slotsBackgroundUrl = props.slotsBackgroundUrl.replace('__FROM__', datesFromTo.start).replace('__TO__', datesFromTo.end);

        $.getJSON(slotsBackgroundUrl, res => {
            if (res.hasOwnProperty('data')) {
                setSlotColors(res.data);
            }
        });

    }, [datesFromTo, slotColors]);
 */
