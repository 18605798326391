import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import {Load, Container} from "laya-js";
import {Session, C,  UA} from "sip.js";

const Router = Container.get("Router");

export class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 0
        };
        this.timer = null;
    }
    tick() {
        const newSeconds = _.clone(this.state.seconds) + 1;
        this.setState({
            seconds: newSeconds
        });
    }
    componentDidMount() {
        this.timer = setInterval(this.tick.bind(this), 1000);
    }
    getHumanTime() {
        const mins = ~~(this.state.seconds / 60);
        const secs = this.state.seconds % 60;
        const secsFormated = secs < 10 ? `0${secs}` : secs;
        const time = `${mins}:${secsFormated}`;
        return time;
    }
    render() {
        return(
            <h4 className="text-center text-muted"><small>{this.getHumanTime()}</small></h4>
        );
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
}


export class Phone extends React.Component {
    constructor(props) {
        super(props);
        this.handleCall = this.handleCall.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.stack = null;
        this.state = {
            callEndTime: null,
            callAnsweredTime: null,
            callSession: null,
            number: null,
            callSession: null,
            callSessionType: null,
            isConnected: false,
            responseCode: null
        };
        this.sipCodes = {
          "100": "pokus",
          "180": "vytáčím",
          "181": "hovor je přesměrován",
          "182": "fronta",
          "183": "postup relace",
          "200": "připojeno",
          "202": "připojeno",
          "301": "trvale přemístěn",
          "302": "dočasně přemístěn",
          "380": "alternativní služba",
          "400": "nesprávný příkaz",
          "401": "neauzorizovaný",
          "403": "zakázáno",
          "404": "uživatel nebyl nalezen",
          "406": "není přijatelné",
          "480": "dočasně není k dispozici",
          "486": "obsazeno",
          "487": "hovor zrušen",
          "503": "služba není k dispozici",
          "600": "obsazeno",
          "603": "odmítnuto",
        };
    }
    componentWillUnmount() {
        this.state.userAgent.stop();
    }
    componentDidMount() {
        this.registerUserAgent();
    }
    handleCancel(ev) {
        ev.preventDefault();
        this.state.callSession.terminate();
        this.setState(
            {
                callSession: null,
                callSessionType: null
            }
        );
    }
    handleCall(ev) {
        ev.preventDefault();
        const options = {
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: true,
                    video: false,
                },
            },
            alwaysAcquireMediaFirst: true
        };

        const callSession = this.state.userAgent.invite(this.props.number, options);
        callSession.on('progress', (response) => {
            const session = callSession;
            if (response.status_code == 183 && response.body && session.hasOffer && !session.dialog) {
                if(!response.hasHeader('require') || response.getHeader('require').indexOf('100rel') == -1) {
                    if(session.sessionDescriptionHandler.hasDescription(response.getHeader('Content-Type'))) {
                        if(!session.createDialog(response, 'UAC')) return;
                        session.hasAnswer = true
                        session.dialog.pracked.push(response.getHeader('rseq'))
                        session.status = Session.C.STATUS_EARLY_MEDIA
                        session.sessionDescriptionHandler.setDescription(response.body, session.sessionDescriptionHandlerOptions, session.modifiers).catch((reason) => {
                            session.failed(response, C.causes.BAD_MEDIA_DESCRIPTION)
                            session.terminate({ status_code: 488, reason_phrase: 'Bad Media Description'})
                        });
                    }
                }
            }
        });        
            
        this.setState(
            {
                callSession: callSession
            }
        );
        this.setupSessionCallEvents(callSession);
    }
    setupSessionCallEvents(callSession) {
        callSession.on("accepted", this.onAccepted.bind(this));
        callSession.on("terminated", this.onTerminated.bind(this));
        callSession.on("progress", this.onProgress.bind(this));
        callSession.on("trackAdded", this.onTrackAdded.bind(this));
    }
    onTerminated(ev) {
        let responseCode = null;
        if(ev && ev.hasOwnProperty('status_code')) {
            const code = this.sipCodes[ev.status_code];
            responseCode = "stav: " + code + " (" + ev.status_code + ")";
        }
        const callEndTime = Date.now();
        this.callLog(callEndTime, _.clone(this.state.callAnsweredTime));
        this.setState(
            {
                responseCode: responseCode,
                callSessionType: null,
                callEndTime: Date.now()
            }
        );
        this.reloadTime();
        //Reset after some time
        setTimeout(()=>{
            this.setState(
                {
                    responseCode: null,
                }
            );  
        },10 * 1000)
    }
    reloadTime() {
        const orderListener = '[data-laya-order-listen]';
        const el = $(orderListener);
        let url = "";
        el.each((key, row)=>{
            let newUrl = $(row).data("laya-order-listen").replace(/&amp;/g,"&");
            if(url.length>0) {
                url +=";;"+newUrl;
            }
            else {
                url = newUrl;
            }

        });
        if(url.length) {
            Router.navigateManualy(url);
        }
    }
    onAccepted(ev) {

        const code = this.sipCodes[ev.status_code];
        if(code != undefined) {
          this.setState({
            responseCode: "stav: " + code + " (" + ev.status_code + ")",
            callAnsweredTime: Date.now(),
            callSessionType: "connected"
          });
        }
          
    }
    onProgress(ev) {
        this.setState(
            {
                callSessionType: "calling"
            }
        );
        const code = this.sipCodes[ev.status_code];
        if(code != undefined) {
            this.setState(
                {
                    responseCode: "stav: " + code + " (" + ev.status_code + ")"
                }
            );
        }
        
          
    }
    onTrackAdded(ev) {
        const remoteAudio = $("#"+this.props.audioRemote)[0];

        const session = this.state.callSession;
        const pc = session.sessionDescriptionHandler.peerConnection;
        const remoteStream = new MediaStream;
        pc.getReceivers().forEach((receiver) => {

            remoteStream.addTrack(receiver.track);
        });
        remoteAudio.srcObject = remoteStream;
        remoteAudio.play();
    
    }
    onRegistered() {
        this.setState({
            isConnected: true
        });
    }
    onDisconnected() {
        this.setState({
            isConnected: false
        });
    }
    registerUserAgent() {
        const options = {
            uri: this.props.uri,
            allowLegacyNotifications: true,
            password: this.props.password,
            transportOptions: {
                wsServers: this.props.wsServers,
                traceSip: true,
            },
            log: {
                builtinEnabled: true
            },
            registerExpires: 60
        }
        const userAgent = new UA(options)

        this.setState(
            {
                userAgent: userAgent
            }
        );
        userAgent.on("registered", this.onRegistered.bind(this));
        userAgent.on("disconnected", this.onDisconnected.bind(this));
    }
    callLog(end, start) {
        const length = start > 0 ? Math.floor((end - start) / 1000) : 0;
        const startTime = start > 0 ? start / 1000 : 0;
        const data = {
            id: 0,
            customerId: this.props.customerId,
            orderId: this.props.orderId,
            startTime: startTime,
            direction: "out",
            length: length,
            did: this.props.number,
            cid: window.IMPI
        };
        $.ajax({
            url: this.props.callLogUrl,
            dataType: "json",
            data: data,
            method: "post",
            success: (res) => {
                //console.log(res);
            }
        });
    }
    render() {
        return(
            <div className="card phone">
                <div className="card-body text-center">
                <audio id={this.props.audioRemote} autoPlay="autoplay"></audio>
                {this.state.callSessionType == "calling" &&
                    
                    <div className="phone-calling">
                        <i className="fas fa-spin fa-circle-notch fa-2x mb-4"></i>
                        <h4 className="text-black">{this.props.number}</h4>
                        <button onClick={this.handleCancel} className="btn btn-danger">
                            <i className="fa fa-phone-slash"></i>
                        </button>
                    </div>
                }
                {this.state.isConnected == true && this.state.callSessionType == null && 
                    <div className="phone-call">
                        <button onClick={this.handleCall} className="btn btn-success text-white">
                            <i className="fa fa-phone"></i> zavolat
                        </button><br />
                        <span className="badge badge-dark">Provoláno minut: {this.props.phonedTime}</span>
                    </div>
                    
                }
                {this.state.callSessionType == "connected" &&
                    <div className="phone-connected">
                        <i className="fas fa-spin fa-circle-notch fa-2x mb-4"></i>
                        <h4 className="text-black mb-4">{this.props.number}</h4>
                        <Timer />
                        <button onClick={this.handleCancel} className="btn btn-danger">
                            <i className="fa fa-phone-slash"></i>
                        </button>
                    </div>
                }
                {!_.isNull(this.state.responseCode) && 
                    <p className="text-muted">{this.state.responseCode}</p>
                }
                </div>
            </div>
        )
    }
}
const initPhone = () => {
    if($("#ReactComponentPhoneMakeCall").length == 0) return;
    Phone.propTypes = {
        impi:  PropTypes.string,
        password: PropTypes.string,
        wsServers: PropTypes.string,
        stunServers: PropTypes.array,
        audioRemote: PropTypes.string,
        uri: PropTypes.string,
        number: PropTypes.string,
        customerId: PropTypes.number,
        customerName: PropTypes.string,
        callLogUrl: PropTypes.string,
        orderId: PropTypes.number,
      };
    Phone.defaultProps = {
        impi: window.IMPI,
        uri: window.IMPI+'@sip.up123.eu',
        password: 'GhosujDiodVak4othedvom',
        wsServers: 'wss://sip.up123.eu:8089/ws',
        audioRemote: "audioRemote",
        stunServers: ['stun:stun.l.google.com:19305','stun:stun1.l.google.com:19305','stun:stun2.l.google.com:19305','stun:stun3.l.google.com:19305']
    
    };

    const number = $("#ReactComponentPhoneMakeCall").data("number");
    const customerId = $("#ReactComponentPhoneMakeCall").data("customer-id");
    const customerName = $("#ReactComponentPhoneMakeCall").data("customer-name");
    const orderId = $("#ReactComponentPhoneMakeCall").data("order-id");
    const callLogUrl = $("#ReactComponentPhoneMakeCall").data("call-log-url");
    const phonedTime = $("#ReactComponentPhoneMakeCall").data("phoned-time");
    ReactDOM.render(React.createElement(Phone,
        {
            number: number,
            customerId: customerId,
            customerName: customerName,
            orderId: orderId,
            callLogUrl: callLogUrl,
            phonedTime: phonedTime
        }
        ), $("#ReactComponentPhoneMakeCall")[0]);
}

$(()=>{
    initPhone();
})
Load.on("componentPrepared", (methods)=>{
    if($("#ReactComponentPhoneMakeCall").length) {
        ReactDOM.unmountComponentAtNode($("#ReactComponentPhoneMakeCall")[0]);
    }
    
});
Load.on("componentRendered", (methods)=>{
    initPhone();
});


export default Phone;