import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <div className="appLoader">
        <svg
          width="200px"
          viewBox="0 0 1064 1066"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="linearGradient-1"
            >
              <stop stopColor="#355CC1" offset="0%" />
              <stop stopColor="#345ABE" offset="5.20941426%" />
              <stop stopColor="#193A8E" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="linearGradient-2"
            >
              <stop stopColor="#24ABC1" offset="0%" />
              <stop stopColor="#1A91A4" offset="100%" />
            </linearGradient>
          </defs>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="logo">
              <path
                d="M844.032534,412 C869.366999,412 889.904641,431.997259 889.904641,456.781794 L889.904641,609.066983 C889.904641,633.799284 869.44205,653.848776 844.032534,653.848776 L219.872108,653.848776 C194.537642,653.848776 174,633.851518 174,609.066983 L174,456.781794 C174,432.049492 194.462591,412 219.872108,412"
                id="Rectangle-3-Copy"
                fill="#601D8C"
              />
              <path
                d="M844.032534,412 C869.366999,412 889.904641,431.997259 889.904641,456.781794 L889.904641,609.066983 C889.904641,633.799284 869.44205,653.848776 844.032534,653.848776 L219.872108,653.848776 C194.537642,653.848776 174,633.851518 174,609.066983 L174,456.781794 C174,432.049492 194.462591,412 219.872108,412"
                id="Rectangle-3-Copy"
                fill="#601D8C"
                transform="translate(531.952321, 532.924388) rotate(-90.000000) translate(-531.952321, -532.924388) "
              />
              <path  strokeWidth="15" stroke="#fff" className="appLoader-wave" d="M375 541.500768 467.538779 541.500768 492.000287 491.802226 514.523656 605.639351 552.156235 421.299985 583.705213 574.352598 603.175693 537.344458 697.660004 540.208843"/>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default Loader;
