import $ from "jquery";
import { Modal } from "laya-js";

export const extendModal = (() => {
    Modal.prototype.render = function(requestResult) {
        this.requestResult = requestResult;
        this.addComponentToDom();
        this.el.modal({
            backdrop: 'static'
        });
        this.el.find(this.defaults.contextElement).addClass("element-has-context").data("laya-context-queue-id", this.queueId).data("laya-context-main-queue-id", this.mainQueueId).data("laya-action-instance", this).data("laya-action-tpl-target", this.defaults.setTplTarget);
        return Modal.__super__.render.apply(this, arguments);
    };

})();