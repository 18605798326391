import Chart from "chart.js";

export default (() => {
  var draw = Chart.controllers.line.prototype.draw;
  Chart.controllers.line = Chart.controllers.line.extend({
    draw: function() {
      draw.apply(this, arguments);
      let ctx = this.chart.chart.ctx;
      let _stroke = ctx.stroke;
      ctx.stroke = function() {
        ctx.save();
        ctx.shadowColor = "rgba(0,218,255,0.4)";
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 4;
        _stroke.apply(this, arguments);
        ctx.restore();
      };
    }
  });
  Chart.defaults.scale.gridLines.display = false;
})();
