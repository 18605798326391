import autobahn from 'autobahn';
import Cookies from 'js-cookie';
import _ from 'lodash';
import $ from 'jquery';
import { Container } from 'laya-js';
import progressBarHandler from '../../ui/progressBar';


const notification = Container.get('Notification');




class Socket {
  constructor() {
    $(()=>{
      this.config = {
        url: window.WS_SERVER,
        realm: window.REALM,
        prefix: window.WS_PREFIX,
      };
      if(typeof window.WS_PREFIX != "undefined") {
        this.connection = new autobahn.Connection({
          url: this.config.url,
          realm: this.config.realm,
        });
        this.session = null;
        this.connection.onopen = this.onConnect.bind(this);
        this.connection.open();
      } 
    });
    
  }
  /**
   *
   *
   * @param {object} session
   * @memberof Socket
   */
  onConnect(session) {
    this.session = session;
    this.subscribe();
    return this;
  }
  /**
   * Call a procedure
   *
   * @param {object} message
   * @memberof Socket
   */
  call(message) {
    if (this.session === null) throw Error('session has not been initated');
    return this.session.call(this.config.prefix + message.action.toLowerCase(), [], message);
  }
  subscribe() {
    if (this.session) {
      let sessionId = Cookies.get('PHPSESSID');
      this.session.subscribe(this.config.prefix + 'user' + sessionId, this.handleSubscribe.bind(this));
    }
  }
  /**
   * Handles pubsub, creates notification if needed, shows progressbar, and displays results
   * @param {object} obj
   * @returns {object} this
   * @memberof Socket
   */
  handleSubscribe(args, argskw) {
    if (argskw.hasOwnProperty('notification')) {
      notification.manipulate(argskw.notification);
    }
    else if (argskw.hasOwnProperty('notification-ack')) {
      notification.manipulate(argskw['notification-ack']);
    }
    else if (argskw.hasOwnProperty('action')) {

      if (argskw.action == 'progressbarStatus' || argskw.action == 'progressbarShowResult') {
        progressBarHandler.handleSocketCall(argskw);
      }
      else if(argskw.action == "checkNewMessages") {
        checkNewMessages();
      }
    }
    return this;
  }
}

let socket = new Socket();

export default socket;
