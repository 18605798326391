import _ from "lodash";
import { combineReducers } from "redux";

const initialState = null;

const serviceGroup = function(state = initialState, action) {
  switch (action.type) {
    case "SERVICE_GROUP_FETCH_DATA":
      if (action.serviceGroup.hasOwnProperty("data")) {
        return {
          ...state,
          data: action.serviceGroup.data
        };
      } else if (action.serviceGroup === false) {
        return { ...state, data: false };
      } 
      else {
        return { ...state, data: false };
      }
      break;
    default:
      return state;
  }
};
const service = function(state = initialState, action) {
    switch (action.type) {
      case "SERVICE_FETCH_DATA":
        if (!_.isNull(action.service) && action.service.hasOwnProperty("data")) {
          return {
            ...state,
            data: action.service.data
          };
        } else if (action.service === null) {
          return state = null;
        }
        else {
          return { ...state, data: false };
        } 
        break;
      default:
        return state;
    }
  };
  const resources = function(state = null, action) {
    switch (action.type) {
      case "RESOURCES_FETCH_DATA":
        if (!_.isNull(action.resources) && action.resources.hasOwnProperty("data")) {
          
          return {
            ...state,
            data: action.resources.data
          };
        } else {
          
          return state = null;
        }
        break;
      default:
        return state;
    }
  };
const offices = function(state = null, action) {
    switch (action.type) {
      case "OFFICES_FETCH_DATA":
        if (!_.isNull(action.offices) && action.offices.hasOwnProperty("data")) {
          return {
            ...state,
            data: action.offices.data
          };
        } else {
          return state = null;
        }
        break;
      default:
        return state;
    }
};
  const employees = function(state = null, action) {
    switch (action.type) {
      case "EMPLOYEES_FETCH_DATA":
        if (!_.isNull(action.employees) && action.employees.hasOwnProperty("data")) {
          
          return {
            ...state,
            data: action.employees.data
          };
        } else {
          return state = null;
        }
        break;
      default:
        return state;
    }
  };
  const slot = function(state = initialState, action) {
    switch (action.type) {
      case "SLOT_FETCH_DATA":
        if (!_.isNull(action.slot) && action.slot.hasOwnProperty("data")) {
          return {
            ...state,
            data: action.slot.data
          };
        } else if (!_.isNull(action.slot) && !action.slot.hasOwnProperty("data")) {
          return {
            ...state,
            data: false
          };
        } else if (action.slot === null) {  
            return state = null;
        } 
        break;
      default:
        return state;
    }
  }; 

const reducer = combineReducers({
  serviceGroup,
  service,
  slot,
  resources,
  employees,
  offices
});
export default reducer;
