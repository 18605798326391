import $ from "jquery";
import _ from "lodash";

import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import {Modal, Load} from "laya-js";

/**
 * @type {String}
 */
const EL_FILE_UPLOAD = '[data-upload]';
/**
 * @type {String}
 */
const EL_FILE_HIDDEN_INPUT = '[data-upload-filename]';

/**
 * @type {String}
 */
if(typeof FILES_URL == "undefined") {
 var FILES_URL = null;
}
const UPLOAD_URL = FILES_URL;



/**
 * Upload class, handles the file selection and upload to S3 instance
 */
class Upload {
  /**
   * Listens to change event for file input
   */
  constructor() {
    $(() => {
      this.setupFilePond();
      Modal.on("componentRendered",this.setupFilePond.bind(this));
      Load.on("componentRendered",this.setupFilePond.bind(this));
    });

  }
  /**
   *
   * @return {Upload}
   */
  setupFilePond() {
    if(UPLOAD_URL == null) return;
    this.fileList = [];
    FilePond.setOptions({
      labelFileLoading: "Načítám",
      labelFileLoading: "Stala se chyba při načítání souboru",
      labelFileProcessing: "Nahrávám na server",
      labelFileProcessingComplete: "Nahrávání dokončeno",
      labelFileProcessingAborted: "Nahrávání zrušeno",
      labelFileProcessingError: "Nahrávání neuspěšné",
      labelTapToRetry: "Zkusit znovu",
      labelTapToCancel: "Zrušit nahrávání",
      labelTapToUndo: "Zrušit",
      labelIdle: 'Přetáhněte své soubory nebo je <span class="filepond--label-action"> <strong>vyberte</strong> </span>',
      maxFileSize: '20MB',
      labelMaxFileSizeExceeded: 'Soubor je příliš veliký',
      labelMaxFileSize: 'Maximální velikost souboru je: {filesize}', 
      server: {
        url: UPLOAD_URL,
        process: this.upload.bind(this),
        fetch: null,
        revert: this.revert.bind(this),
        restore: null,
        load: null
      }
    });
    FilePond.registerPlugin(
      FilePondPluginFileValidateType,
      FilePondPluginImageExifOrientation,
      FilePondPluginFileValidateSize,
      FilePondPluginImagePreview
    );
    FilePond.create(
      document.querySelector(EL_FILE_UPLOAD)
    );
    return this;
  }
  /**
   * [upload description]
   * @param  {String} fieldName
   * @param  {File} file
   * @param  {Object} metadata
   * @param  {Function} load
   * @param  {Function} error
   * @param  {Function} progress
   * @param  {Function} abort
   * @return {jqXHR}
   */
  upload(fieldName, file, metadata, load, error, progress, abort) {
    let xhr = $.ajax({
      url: UPLOAD_URL + "upload/" + file.name,
      type: "PUT",
      data: file,
      processData: false,
      contentType: false,
      cache: false,
      dataType: 'json',
      xhr: function () {
        var xhr = $.ajaxSettings.xhr();
        xhr.upload.onprogress = function (e) {
          progress(e.lengthComputable, e.loaded, e.total);
        };
        return xhr
      }
    }).done(this.handleSuccess.bind(this, load)).fail(this.handleFailure.bind(this, error));

    // Should expose an abort method so the request can be cancelled
    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        xhr.abort();

        // Let FilePond know the request has been cancelled
        abort();
      }
    };
  }
  /**
   * @param  {String} fileId
   * @param  {Function} load
   * @param  {Function} error
   * @return {Upload}
   */
  revert(fileId, load, error) {
    let index = _.findIndex(this.fileList, {
      'path': fileId
    });
    _.pullAt(this.fileList, index);
    this.setValue();
    load();
    return this;
  }
  /**
   *
   * @param  {Object} res
   * @return {Upload} this
   */
  handleSuccess(load, res) {
    if (res.path) {
      this.fileList.push({
        path: res.path,
        key: res.key
      });
      load(res.path);
      this.setValue();
    }
    return this;
  }
  setValue() {
    $(EL_FILE_HIDDEN_INPUT).val(this.getJsonFormat());
    return this;
  }
  /**
   *
   * @param  {Object} object
   * @return {Json}
   */
  getJsonFormat() {
    return JSON.stringify(this.fileList);
  }
  /**
   *
   * @param  {jqXHR} jqXHR
   * @param  {String} textStatus
   * @param  {String} errorThrown
   * @return {Upload} this
   */
  handleFailure(error, jqXHR, textStatus, errorThrown) {
    error(textStatus);
    return this;
  }

}

export default new Upload;
