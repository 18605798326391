import $ from "jquery";
import form from "../handler/form/index";

export const birthNumberSwitch = (()=>{
    $(document).on("click", '[data-birthnumber-switch="on"]', (ev)=>{
        ev.preventDefault();
        const el = $(ev.currentTarget);
        $('[data-birthnumber-switch="true"]').text(el.text());
        $('[data-birthnumber-switch="on"]').addClass("d-none");
        $('[data-birthnumber-switch="off"]').removeClass("d-none");
    
        let target = $('[data-birthnumber-switch="input"]');
        target.addClass("birthnumber-validate");
        
    });
    $(document).on("click", '[data-birthnumber-switch="off"]', (ev)=>{
        ev.preventDefault();
        const el = $(ev.currentTarget);
        $('[data-birthnumber-switch="true"]').text(el.text());
        $('[data-birthnumber-switch="off"]').addClass("d-none");
        $('[data-birthnumber-switch="on"]').removeClass("d-none");

        let target = $('[data-birthnumber-switch="input"]');
        target.removeClass("birthnumber-validate");
    });
})();