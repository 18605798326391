import { Container } from 'laya-js';
import _ from 'lodash';

import createHistory from 'history/createBrowserHistory';

class RouterHasher {
  get allowed() {
    return this.allowedActions;
  }
  set allowed(arr = []) {
    this.allowedActions = arr;
  }
  get currentAction() {
    return this.actionData;
  }
  set currentAction(data) {
    this.actionData = data;
  }
  get param() {
    return this.actionParam;
  }
  set param(str) {
    this.actionParam = str;
  }
  get url() {
    return this.actionUrl;
  }
  set url(str) {
     let url = str.replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im, '');
     this.actionUrl = url.replace("//","/");
  }
  constructor() {
    this.allowedActions = ['load', 'loadInto', 'saveAndLoad', 'saveAndLoadData'];
    this.disabledUrlParts = ['void', 'layaDoNotChangeUrl','inbox', 'mm'];
    /*
    "add",
    "edit",
    "delete",
    "update",
    "check",
    "step"
    */

    this.defaultParam = 'appContent';
    this.actionData = null;
    this.actionParam = null;
    this.actionUrl = null;
    this.currentXhr = null;
    this.paramFormatDefault = '?_action=load&_param0=' + this.defaultParam;
    this.history = createHistory();
    this.layaRouter = Container.get('Router');

    //Set default
    this.setDefaultUrl();

    const unlisten = this.history.listen((lct, action) => {
      //console.log(action,lct);
      if (action == 'POP') {
        //if(this.disabledUrlParts.in)
        if(lct.hash.indexOf("#") == -1 && this.disabledUrlParts.indexOf(lct.hash.replace("#","")) == -1) {
          window.location.href = lct.pathname;
        }
        //
      }
      /*
      if (action == "POP") {
        let view = lct.pathname;
        let params = "?" + cryptr.decrypt(lct.search.replace("?", ""));
        if (_.isUndefined(lct.state)) {
          params = this.paramFormatDefault;
        }
        this.url =
          window.location.protocol +
          "//" +
          window.location.hostname +
          view +
          params;
        if (this.currentXhr) {
          this.currentXhr.abort();
        }
        this.layaRouter.navigateManualy(this.url);

      }
      */
    });

    this.connection = Container.get('AjaxController');
    this.connection.on('beforeSend', this.handleTransfer.bind(this));
  }

  setDefaultUrl() {
    /*
      if (window.location.href.indexOf("?") == -1) {
          this.url = window.location.href + this.paramFormatDefault;
      }
      else {
          let url = window.location.href.split("?");
          url = url[0] + "?" + cryptr.decrypt(url[1]);
          this.url = url;
      }
    */
    this.url = window.location.href;
  }

  splitAndHashUrl(url) {
    let newUrl = url.split('?');
    return newUrl;
    //return newUrl[0] + '?' + cryptr.encrypt(newUrl[1]);
  }
  handleTransfer(xhr, settings, methods) {
    this.currentAction = methods._actions[0];
    this.currentXhr = xhr;
    //now only handles load and saveAndLoad
    if (this.allowed.indexOf(this.currentAction.action) != -1) {
      if (this.currentAction.action == 'load') {
        this.param = this.currentAction.params[0];
      } else if (this.currentAction.action == 'saveAndLoad' || this.currentAction.action == 'saveAndLoadData') {
        this.param = this.currentAction.params[1];
      }
      let foundDisabled = false;
      _.each(this.disabledUrlParts, part => {
        if (this.currentAction.rawUrl.indexOf(part) != -1) {
          foundDisabled = true;

          return;
        }
      });
      if (this.url == this.currentAction.url) {
        return;
      }
      if (!foundDisabled) {
        this.url = this.currentAction.url;
        this.history.push(this.url, { param: this.param });
      }

    }
  }
}
const routerHasher = new RouterHasher();
export default routerHasher;
