import { Container } from 'laya-js';
import "bootstrap-notify";
import _ from 'lodash';

export default () => {
	var Notification = Container.get('Notification')
	//Notification.defaults.template = '<div id="<%= notifId %>" class="alert alert-dismissible-absolute d-flex align-items-center alert-<%= notificationType %>" data-type="<%= notificationType %>"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><div class="alert-image"><img src="' + STATIC_URL + 'dist/images/logo-big.png" height="42"></div><div class="alert-body"><%= message %></div></div>';
	Notification.defaults.template = '<div id="<%= notifId %>" class="alert" data-type="<%= notificationType %>"><%= message %></div>';
	Notification.defaults.timeout = 10;
	Notification.defaults.notificationTypeClasses = {
		success: "success",
        error: "danger",
        info: "info",
        warning: "warning",
        notice: "warning"
	}
	//Dont render notifs to alert-container so rewrite the method
	Notification.create = (type, messages) =>  {
		_.each(messages, (val, k) => {
			if (val.hasOwnProperty('callback') && val.callback.hasOwnProperty('url')) {
				if(val.callback.url != null) {
						$.post(val.callback.url);
				}
			}
			let message = val;
			let autoHide = true;
			if(val.hasOwnProperty('message')) {
				message = val.message;
			}
			Notification.trigger("notificationCreated", null, messages, type);
			let align = "left";
			let offset = "20";
			if(!$(".isDesktop").is(":visible")) {
				align = "right";
				offset = "0";
			}
			let opt = {
				type: type,
					position: "fixed",
					placement: {
						from: "bottom",
						align: align
					},
					offset: 0,
					z_index: 9999
			};
			if(type == "danger" || type == "warning") {
				opt.delay= 20000;
			}
			else {
				opt.delay= 10000;
			}
			$.notify({
				message: message
			}, opt);
		});

	}
	//Move rendered alerts to notify
	$(()=>{
		let align = "left";
		let offset = "20";
		if(!$(".isDesktop").is(":visible")) {
			align = "right";
			offset = "0";
		}
		$("#alert-container .alert").each((key, el) => {
			let notif = $(el);
			notif.find(".close").remove();
			let type = notif.data("type");
			let autoHide = true;
			let message = notif.html();
			let opt = {
				type: type,
					position: "fixed",
					placement: {
						from: "bottom",
						align: align
					},
					offset: offset,
					z_index: 9999
			};
			if(type == "danger" || type == "warning") {
				opt.delay = 20000;
			}
			else {
				opt.delay = 10000;
			}
			$.notify({
				message: message
			}, opt);
			$(el).remove();
		});
	});
}
