import { Laya, Container } from 'laya-js';



/**
 * OpenInNewTab - opens link in new browser tab
 * @extends Laya.ActionAjax
 */

class OpenInNewTab extends Laya.ActionAjax {
	constructor() {
		super();
	}
	render(requestResult) {
		this.requestResult = requestResult;
		let link = requestResult.data;
		window.open(link, '_blank');
		//console.log(link);
		super.render();
	}
}

Container.register('OpenInNewTab', OpenInNewTab);

export default OpenInNewTab;
