import $ from "jquery";
import _ from "lodash";
import moment from "moment";


const EL_YEAR = '[data-calendar-filter="year"]';
const EL_WEEK = '[data-calendar-filter="week"]';


const filterWeek = (()=>{
    $(document).on("change", EL_YEAR, (ev)=>{
        const weeks = generateWeeks($(ev.currentTarget).val());
        $(EL_WEEK).html('');
        _.each(weeks, (row, index)=>{
            let newOption = new Option(row, index, false, false);
            $(EL_WEEK).append(newOption);
        });
        $(EL_WEEK).trigger('change');
    });
})();

const generateWeeks = (year) => {
    let start = moment().year(year).month(0).startOf('isoWeek');
    let end = moment().month(11).endOf('month');
    let weeks = {};
    let loop = start;
    for(let i=1;i<=end.isoWeek();i++) {
        weeks[i] = loop.startOf('isoWeek').format('DD.MM.YYYY') + " - " +loop.endOf('isoWeek').format('DD.MM.YYYY') + ' (' + i +')';
        loop = loop.add(1, 'isoWeek');
    }
    return weeks;
}