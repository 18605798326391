import $ from "jquery";
import _ from "lodash";
import "pickadate/lib/picker";
import "pickadate/lib/picker.date";
import "pickadate/lib/picker.time";

import { Load, Modal, LoadInto, SaveAndLoad, Container } from "laya-js";

const Panel = Container.getClass("Panel");

const defaultOptions = {
  monthsFull: [
    "leden",
    "únor",
    "březen",
    "duben",
    "květen",
    "červen",
    "červenec",
    "srpen",
    "září",
    "říjen",
    "listopad",
    "prosinec"
  ],
  monthsShort: [
    "led",
    "úno",
    "bře",
    "dub",
    "kvě",
    "čer",
    "čvc",
    "srp",
    "zář",
    "říj",
    "lis",
    "pro"
  ],
  weekdaysFull: [
    "neděle",
    "pondělí",
    "úterý",
    "středa",
    "čtvrtek",
    "pátek",
    "sobota"
  ],
  selectMonths: true,
  selectYears: 30,
  weekdaysShort: ["ne", "po", "út", "st", "čt", "pá", "so"],
  today: "dnes",
  container: "body",
  clear: "vymazat",
  close: "zavřít",
  firstDay: 1,
  format: "dd.mm.yyyy",
  formatSubmit: "dd.mm.yyyy",
  onSet: function(thingSet) {
    $(this.$node).trigger('blur');
  }
};
//Maybe create a class?
export const init = (options = {}, methods) => {
  let opt = $.extend({}, defaultOptions, options);
  let param = "body";

  if (methods && methods.hasOwnProperty("action")) {
    let actionName = methods.action;
    if (actionName == "modal" || actionName == "overlay") {
      param = "#laya-modal";
    }
    else if (actionName == "panel") {
      param = ".v-panel";
    }
    else {
      if(methods.params.hasOwnProperty("target")) {
        param = "#"+methods.params.target;
      }
      else {
        param = "#"+methods.params[0];
      }
      
    }
  }
  $(".custom-timepicker").pickatime({
    clear: "zrušit",
    format: "HH:i",
    container: "body"
  })
  .pickatime("picker");
  $(param).find(".datepicker").each((k, item) => {
    let el = $(item);
    if (el.data("iniated") || el.length == 0) return true;
    el.data("iniated", true);
    el.pickadate(opt);
  });

  $(param).find(".timepicker").each((k, item) => {
    let el = $(item);
    if (el.data("iniated") || el.length == 0) return true;
    el.data("iniated", true);
    let timePicker = el
      .pickatime({
        clear: "",
        format: "HH:i",
        interval: el.data("interval"),
        container: "body",
        min: [0, 0],
        max: [0, 0],
        klass: {
          list: "picker__list d-flex flex-wrap",
          listItem: "picker__list-item p-0 w-25",
          holder: "picker__holder picker__right"
        },
        formatLabel: function(timeObject) {
          let data = el.data("loaded");
          if (_.isArray(data)) {
            let min = (timeObject.mins < 10 ? "0" : "") + timeObject.mins;
            let hour = (timeObject.hour < 10 ? "0" : "") + timeObject.hour;
            let time = hour + ":" + min;
            if (data.indexOf(time) != -1) {
              return '<d!iv cl!ass="w-100 text-center pl-2 pr-2 pt-1 pb-1 !alert-d!anger"><b>HH:i</b></div>';
            } else {
              return '<d!iv cl!ass="w-100 text-center pl-2 pr-2 pt-1 pb-1 !alert-success"><b>HH:i</b></div>';
            }
          } else if (data && data.hasOwnProperty("error")) {
            return '<d!iv cl!ass="w-100 text-center pl-2 pr-2 pt-1 pb-1 !alert-w!arn!ing"><b>d!atum v m!inulost!i</b></div>';
          } else {
            return '<d!iv cl!ass="w-100 text-center pl-2 pr-2 pt-1 pb-1 !alert-w!arn!ing"><b>!n!ačítám d!at!a...</b></div>';
          }
        }
      })
      .pickatime("picker");
      
    timePicker.on("open", function() {
      if (!el.data("loaded")) {
        let url = el
          .data("busy-feed-link")
          .replace("DATE_PLACEHOLDER", $("#" + el.data("dateinput-id")).val());
        $.getJSON(url).done(res => {
          let roundedData = [];
          if (_.isArray(res.data)) {
            
            roundedData = res.data.map(time => {
              let arr = time.split(":");
              let hour = arr[0];
              let min = parseInt(arr[1]);
              let interval = parseInt(el.data("interval"));
              let newMin = Math.round(min / interval) * interval;
              if (newMin == 60) {
                newMin = "00";
              }
              newMin = (newMin < 10 ? "0" : "") + newMin;
              return "" + hour + ":" + newMin;
            });
            this.set("min", el.data("min"));
            this.set("max", el.data("max"));
          } else if (_.isObject(res.data) && res.data.hasOwnProperty("error")) {
            roundedData = {};
            roundedData.error = res.data.error;
          } else if (_.isUndefined(res.data)) {
            this.set("min", el.data("min"));
            this.set("max", el.data("max"));
          }
          el.data("loaded", roundedData);
          this.render();
        });
      }
    });
    let datePicker = $("#" + el.data("dateinput-id")).pickadate("picker");
    datePicker.set("min", new Date());
    datePicker.on("set", context => {
      if (context.select) {
        el.removeData("loaded");
        timePicker.set("min", [0, 0]);
        timePicker.set("max", [0, 0]);
        timePicker.render();
        timePicker.open();
      }
    });
  });
};

export const destroyPickers = actions => {
  let param = null;
  if(actions) {
    let actionName = actions._actions[0].action;
    if (actionName == "modal" || actionName == "overlay") {
      param = "#laya-modal";
    }
    else {
      if(actions._actions[0].params.hasOwnProperty("target")) {
        param = "#"+actions._actions[0].params.target;
      }
      else {
        param = "#"+actions._actions[0].params[0];
      }
    }
  }
  if(param == null) return;
  $(param)
    .find(".datepicker")
    .each((key, el) => {
      let date = $(el).pickadate("picker");
      if (date) {
        
        $(el).data("iniated", null);
        date.stop();
      }
    });
  $(param)
    .find(".timepicker")
    .each((key, el) => {
      let time = $(el).pickatime("picker");
      if (time) {
        $(el).data("iniated", null);
        time.stop();
      }
    });
};

Load.on("componentPrepared", destroyPickers);
Modal.on("componentPrepared", destroyPickers);
LoadInto.on("componentPrepared", destroyPickers);
SaveAndLoad.on("componentPrepared", destroyPickers);
Panel.on("componentPrepared", destroyPickers);

Load.on("componentRendered", methods => {
  init({}, methods);
});
Modal.on("componentRendered", methods => {
  init({}, methods);
});
LoadInto.on("componentRendered", methods => {
  init({}, methods);
});
SaveAndLoad.on("componentRendered", methods => {
  init({}, methods);
});

Panel.on("componentRendered", methods => {
  init({}, methods);
});

export default (() => {
  $(() => {
    init();
  });
})();
