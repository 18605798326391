import { Calendar } from '@fullcalendar/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin, { toMoment, toDUration } from '@fullcalendar/moment';
import moment from 'moment';
//import csLocale from '@fullcalendar/core/locales/cs';
import $ from 'jquery';
import _, { remove } from 'lodash';
import 'bootstrap';

import bootstrap from '@fullcalendar/bootstrap';

import { Container, SaveAndLoad, SaveAndLoadData, Save, Load } from 'laya-js';

import OrderUpdated from '../handler/orderUpdated';

const Router = Container.get('Router');

const Notification = Container.get('Notification');

const BTN_ADD = '[data-slot-add-url]';

let calendar = null;
let calendarSlotDetail = null;
let xhr = null;

let code = 'cs';
if (window.LANG == 'sk_SK.UTF-8') {
  code = 'sk';
}
const csLocale = {
  code: code,
  week: {
    dow: 1,
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: window.TRANSLATIONS.previous,
    next: window.TRANSLATIONS.next,
    today: 'Dnes',
    month: window.TRANSLATIONS.month,
    week: window.TRANSLATIONS.week,
    day: 'Den',
    list: window.TRANSLATIONS.list,
  },
  weekLabel: 'Týd',
  allDayText: 'Celý den',
  eventLimitText: function (n) {
    return '+další: ' + n;
  },
  noEventsMessage: window.TRANSLATIONS.noDates,
};
const initCalendar = () => {
  const calendarEl = $('#slotCalendar');
  if (calendarEl.length == 0) return;
  const canEdit = $('#slotCalendar').data('slot-passive') ? false : true;
  const defaultView = $('#slotCalendar').data('slot-calendar-view')
    ? $('#slotCalendar').data('slot-calendar-view')
    : 'timeGridWeek';
  const defaultDate =
    $('#slotCalendar').data('slot-calendar-date') > 0
      ? new Date($('#slotCalendar').data('slot-calendar-date') * 1000)
      : null;
  const defaultHeader = defaultView == 'listWeek' ? 'listWeek, timeGridWeek, timeGridDay' : 'timeGridWeek, timeGridDay';
  calendar = new Calendar(calendarEl[0], {
    plugins: [interactionPlugin, timeGridPlugin, listPlugin, 'bootstrap', momentPlugin],
    header: {
      left: defaultHeader,
      center: 'title',
      right: 'prev next',
    },
    allDaySlot: false,
    droppable: canEdit,
    editable: canEdit,
    defaultDate: defaultDate,
    eventDurationEditable: canEdit,
    eventStartEditable: false,
    locale: csLocale,
    height: () => {
      return $(window).height() - calendarEl.offset().top - 50;
    },
    defaultView: defaultView,
    themeSystem: 'bootstrap',
    slotDuration: '00:10:00',
    /*
    minTime: '06:00:00',
    maxTime: '19:00:00',
    */
    selectable: true,
    slotLabelInterval: {
      minutes: 10,
    },
    eventResize: info => {
      if (canEdit) {
        const end = toMoment(info.event.end, calendar);
        const actions = {
          _actions: [
            {
              action: 'save',
              postData: {
                save: 1,
                endDatetime: {
                  hour: end.format('HH'),
                  minute: end.format('mm'),
                },
              },
            },
          ],
        };
        $.post(
          info.event.extendedProps.resizeUrl,
          actions,
          res => {
            if (res.hasOwnProperty('notification') && res.notification.hasOwnProperty('messages')) {
              _.each(res.notification.messages, (message, type) => {
                Notification.create(type, message);
              });
              calendar.refetchEvents();
            }
          },
          'json',
        );
      }
    },
    eventRender: info => {
      const start = toMoment(info.event.start, calendar);
      const end = toMoment(info.event.end, calendar);

      const timeTitle =
        "<span class='badge badge-dark'>" +
        start.format('HH') +
        ':' +
        start.format('mm') +
        ' - ' +
        end.format('HH') +
        ':' +
        end.format('mm') +
        '</span>';
      if ($(info.el).find('.fc-list-item-title').length) {
        let services = '';
        if (!_.isEmpty(info.event.extendedProps.services)) {
          services =
            "<button class='btn btn-sm btn-primary' data-trigger='hover' data-container='body' data-title='" +
            info.event.extendedProps.services.join(', ') +
            "' data-toggle='tooltip'><i class='fas fa-eye'></i> služby</button>";
        }
        let capacity = '';
        if (!_.isNull(info.event.extendedProps.totalCapacity)) {
          capacity =
            "<span class='badge badge-secondary mr-1'>Kapacita: " +
            info.event.extendedProps.free +
            '/' +
            info.event.extendedProps.totalCapacity +
            '</span>';
        }
        let bodyTitle =
          "<div class='d-flex justify-content-between'><div><span class='badge badge-secondary mr-1'>" +
          info.event.title +
          '</span>' +
          capacity +
          '</div>' +
          services +
          "<button class='btn btn-sm btn-primary'><i class='fas fa-check'></i> vybrat časový blok</button></div>";
        $(info.el).find('.fc-list-item-time').html(timeTitle);
        $(info.el).find('.fc-list-item-title').html(bodyTitle);
        //why? Když je to eventRender a html už tam je, proč musí být timeout, aby mi fungoval tooltip?
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 100);
      } else {
        let bodyTitle =
          "<span class='badge badge-secondary'>" +
          info.event.title +
          "</span><span class='badge badge-secondary'>Kapacita: " +
          info.event.extendedProps.free +
          '/' +
          info.event.extendedProps.totalCapacity +
          '</span>';
        $(info.el).find('.fc-title').html(bodyTitle);
        $(info.el).find('.fc-time').html(timeTitle);
      }
    },
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      hour12: false,
      meridiem: 'false',
    },
    titleFormat: 'MMMM D, YYYY',
    /*
    dateClick: (info) => {
        let m = toMoment(info.date, calendar);
        let url = $(BTN_ADD).data('slot-add-url').replace("__from__",m.unix()).replace("__to__",m.unix());
        Router.navigateManualy(url);
      },
    */
    select: info => {
      if (canEdit) {
        let start = toMoment(info.start, calendar);
        let end = toMoment(info.end, calendar);
        let url = $(BTN_ADD).data('slot-add-url').replace('__from__', start.unix()).replace('__to__', end.unix());
        Router.navigateManualy(url);
      }
    },
    eventClick: function (info) {
      let start = toMoment(info.view.activeStart, calendar);
      let end = toMoment(info.view.activeStart, calendar);
      const url = info.event.extendedProps.url
        .replace(/&amp;/g, '&')
        .replace('__from__', start.unix())
        .replace('__to__', end.unix());
      Router.navigateManualy(url);
    },
  });
  calendar.addEventSource((info, success, error) => {
    const start = toMoment(info.start.getTime(), calendar).format('X');
    const end = toMoment(info.end.getTime(), calendar).format('X');
    const url = calendarEl.data('slot-calendar-events').replace('__from__', start).replace('__to__', end);

    if (xhr != null) {
      xhr.abort();
    }
    xhr = $.getJSON(url, res => {
      if (res.hasOwnProperty('data')) {
        success(res.data);
        let firstTime = 24;
        let firstTimeFull = 0;
        _.each(res.data, row => {
          const hour = parseInt(row.start.split(' ')[1].split(':')[0]);
          if (hour < firstTime) {
            firstTime = hour;
            firstTimeFull = row.start.split(' ')[1];
          }
        });
        calendar.scrollToTime(firstTimeFull);
        xhr = null;
      }
    });
  });
  calendar.render();
};

const initCalendarSlotDetail = () => {
  const calendarElDetail = $('#slotDetailCalendar');
  if (calendarElDetail.length == 0 || $('#slotDetailCalendar').children().length > 0) return;
  calendarSlotDetail = new Calendar(calendarElDetail[0], {
    plugins: [interactionPlugin, timeGridPlugin, bootstrap, momentPlugin],
    header: {
      left: 'title',
      center: '',
      right: '',
    },
    allDaySlot: false,
    droppable: false,
    editable: false,
    eventStartEditable: false,
    backgroundColor: 'red',
    locale: csLocale,
    height: () => {
      return $(window).height() - calendarElDetail.offset().top - 50;
    },
    eventTextColor: '#fff',
    defaultView: 'timeGridDay',
    themeSystem: 'bootstrap',
    slotDuration: '00:01:00',
    minTime: calendarElDetail.data('slot-calendar-from'),
    maxTime: calendarElDetail.data('slot-calendar-to'),
    defaultDate: calendarElDetail.data('slot-calendar-date'),
    selectable: true,
    slotLabelInterval: {
      minutes: 5,
    },
    /*
      eventResize: (info) => {
          const end = toMoment(info.event.end,calendar);
          const actions = {
              _actions : [{
                  action: "save",
                  postData: {
                      save: 1,
                      endDatetime: {
                          hour: end.format("HH"),
                          minute: end.format("mm")
                      }
                  }
              }]
          };
          $.post(info.event.extendedProps.resizeUrl, actions, (res) => {
              if(res.hasOwnProperty('notification') && res.notification.hasOwnProperty('messages')) {
                  _.each(res.notification.messages, (message, type) => {
                      Notification.create(type, message);
                  });
                  calendar.refetchEvents();
              }
          }, "json" );
  
      },
      */
    eventRender: info => {
      const start = toMoment(info.event.start, calendarSlotDetail);
      const end = toMoment(info.event.end, calendarSlotDetail);
      const timeTitle =
        '<strong>' +
        start.format('HH') +
        ':' +
        start.format('mm') +
        ' - ' +
        end.format('HH') +
        ':' +
        end.format('mm') +
        '</strong>';
      let status = 'badge-primary';
      if (info.event.extendedProps.status == 'approved') {
        status = 'badge-success';
      } else if (info.event.extendedProps.status == 'created') {
        status = 'badge-warning';
      } else if (info.event.extendedProps.status == 'storno') {
        status = 'badge-danger';
      }
      let bodyTitle =
        '<strong>' +
        info.event.title +
        '</strong><span>' +
        info.event.extendedProps.uniqueIdentifier +
        '</span><span>' +
        info.event.extendedProps.service +
        "</span><span class='badge text-white " +
        status +
        "'>" +
        info.event.extendedProps.statusHuman +
        '</span>';
      $(info.el).find('.fc-title').html(bodyTitle);
      $(info.el).find('.fc-time').html(timeTitle);
    },
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      hour12: false,
      meridiem: 'false',
    },
    titleFormat: 'MMMM D, YYYY',
    select: info => {
      let start = toMoment(info.start, calendarSlotDetail);
      let url = calendarElDetail.data('slot-calendar-reschedule').replace('__START_TIME__', start.unix());
      Router.navigateManualy(url);
    },
    /*
      dateClick: (info) => {
          let start = toMoment(info.date, calendarSlotDetail);
          let url = calendarElDetail.data('slot-calendar-reschedule').replace("__START_TIME__",start.unix());
          Router.navigateManualy(url);
    },
    */
    eventClick: function (info) {
      Router.navigateManualy(info.event.extendedProps.url.replace(/&amp;/g, '&'));
    },
  });
  calendarSlotDetail.addEventSource((info, success, error) => {
    const url = calendarElDetail.data('slot-calendar-events');
    if (xhr != null) {
      xhr.abort();
    }
    xhr = $.getJSON(url, res => {
      if (res.hasOwnProperty('data')) {
        success(res.data);
      } else {
        success([]);
      }
      xhr = null;
    });
  });
  calendarSlotDetail.render();
};

const loadPage = (() => {
  $(() => {
    initCalendar();
    initCalendarSlotDetail();
  });
  $(document).on('ajaxComplete', (event, request) => {
    let requestResult = null;
    if (request && request.hasOwnProperty('responseJSON')) {
      requestResult = request.responseJSON;
    }

    if (requestResult && requestResult.hasOwnProperty('orderUpdated')) {
      if (calendarSlotDetail) {
        calendarSlotDetail.refetchEvents();
      }
    }
  });
  Load.on('componentRendered', methods => {
    initCalendarSlotDetail();
  });
  Save.on('componentRendered', methods => {
    if (methods && methods.hasOwnProperty('rawUrl') && methods.rawUrl.indexOf('slotCalendarAdd/true') != -1) {
      calendar.refetchEvents();
    }
  });
})();

//Change condensed calendar

const changeCondensedCalendar = (() => {
  $(document).on('change', '[data-calendar-condensed="change"]', ev => {
    const el = $(ev.currentTarget);
    const date = moment(el.val(), 'DD.MM.YYYY');
    const url = el
      .data('url')
      .replace('__from__', date.startOf('isoWeek').format('X'))
      .replace('__to__', date.endOf('isoWeek').format('X'));
    Router.navigateManualy(url);
  });
})();
