import "core-js/stable";
import "regenerator-runtime/runtime";
import autoComplete from "@tarekraafat/autocomplete.js/dist/js/autoComplete";
import $ from "jquery";
import _ from "lodash";
import {Load, Modal} from "laya-js";
import Panel from "../handler/panel";
const initAutocomplete = (el) => {
    const placeHolder = $(el).attr("placeholder") ? $(el).attr("placeholder") : "Vyhledat...";
    const threshold = 3;
    $(document).on("click",  ".autoComplete-selected", (ev)=>{
        ev.preventDefault();
        const el = $(ev.currentTarget);
        const parent = el.parents(".autoComplete-component");
        const inputHidden = parent.find(".autoComplete-hidden");
        if(inputHidden.data("multiple")) {
            const id = el.parent(".autoComplete-input-group").data("id");
            let arr = inputHidden.val().split(",");
            let labels = inputHidden.data("label");
            const index = arr.indexOf(""+id);
            if(index > -1) {
                arr.splice(index, 1);
            }
            if(labels.hasOwnProperty(id)) {
                delete labels[id];
            }
            inputHidden.data("label", labels);
            el.parent(".autoComplete-input-group").remove();
            inputHidden.val(arr.join(","));
        }
        else {
            parent.find(".autoComplete-input-group").addClass("d-none");
            parent.find(".autoComplete-hidden").val('');
        }
        
    });
    if($(el).data("autocomplete-on")) return;
    $(el).data("autocomplete-on","true");
    new autoComplete({
        data: {                              // Data src [Array, Function, Async] | (REQUIRED)
          src: async () => {
            const $element = $(el);
            let options = [];
            const query = $element.val();
            if(query.length > threshold) {
                const url = new URL($element.data("laya-autocomplete-url").replace("__AUTOCOMPLETE_REPLACE__",query));
                const source = await fetch(url, {
                    headers: {
                        'Accept': 'application/json'
                    },
                });
                const data = await source.json();
                
                if(data.hasOwnProperty("children")) {
                    _.each(data.children, (row, key) => {
                        if(row.hasOwnProperty("children")) {
                            _.each(row.children, (group, index)=>{
                                const select = _.find(group.children, { typeSpecific: "selectAutocomplete", fullName: $element.data('name') });
                                if(select) {
                                    options = _.values(select.options);
                                }
                            });
                        }
                    });
                }
            }
            return options;
          },
          key: ["label"],
          cache: false
        },
        placeHolder: placeHolder,     // Place Holder text                 | (Optional)
        selector: () => el,           // Input field selector              | (Optional)
        threshold: threshold,                        // Min. Chars length to start Engine | (Optional)
        debounce: 300,                       // Post duration for engine to start | (Optional)
        searchEngine: (query, record) => {
            /*
            const recordLowerCase = record.toLowerCase();
            if (recordLowerCase.includes(query)) {
                // Regular Expression Query Pattern Ignores caseSensitive
                const pattern = new RegExp(`${query}`, "i");
                // Search for a match Query in Record
                query = pattern.exec(record);
                // Returns the match
                return record.replace(query, autoCompleteView.highlight(query));
              }
              */
             return record;
        },
        resultsList: {                       // Rendered results list object      | (Optional)
            render: true,
            /* if set to false, add an eventListener to the selector for event type
               "autoComplete" to handle the result */
            container: source => {
                source.setAttribute("id", "autoComplete_list");
            },
            destination: document.querySelector("#autoComplete"),
            position: "afterend",
            element: "ul"
        },
        maxResults: 100,                         // Max. number of rendered results | (Optional)
        highlight: true,                       // Highlight matching results      | (Optional)
        resultItem: {                          // Rendered result item            | (Optional)
            content: (data, source) => {
                source.innerHTML = data.match;
            },
            element: "li"
        },
        noResults: () => {                     // Action script on noResults      | (Optional)
            const result = document.createElement("li");
            result.setAttribute("class", "autoComplete_result");
            result.setAttribute("tabindex", "1");
            result.innerHTML = "Je nám líto, ale nejsou zde žádné výsledky.";
            document.querySelector("#autoComplete_list").appendChild(result);
        },
        onSelection: (feedback) => {             // Action script onSelection event | (Optional)
            feedback.event.preventDefault();
            $(el).val('');
            $(el).attr('placeholder',placeHolder);
            const inputHidden = $(el).parents('.autoComplete-component').find(".autoComplete-hidden");
            if(inputHidden.data("multiple")) {
                let arr = inputHidden.val().length>0 ? inputHidden.val().split(",") : [];
                let labels = inputHidden.data("label");
                if(arr.indexOf(""+feedback.selection.value.id) != -1) {
                    return false;
                }
                if(labels) {
                    labels[feedback.selection.value.id] = feedback.selection.value.label;
                }
                else {
                    labels = {};
                    labels[feedback.selection.value.id] = feedback.selection.value.label;
                }
                inputHidden.data("label", labels);

                if(arr.length>0) {
                    arr.push(feedback.selection.value.id);
                    inputHidden.val(arr.join(","));
                }
                else {
                    inputHidden.val(feedback.selection.value.id);
                }
                $(el).parents('.autoComplete-component').find('.d-none').removeClass('d-none');
                const group = $(el).parents('.autoComplete-component').find('.autoComplete-input-group-wrap');
                const html = '<div class="pl-2 pr-2 autoComplete-input-group"><button type="button" class="btn btn-outline-primary autoComplete-selected"><i class="fas fa-times"></i> <span class="autoComplete-label"></span></button></div>';
                group.html('');
                _.each(labels, (val, key)=>{
                    const el = $(html).appendTo(group);
                    el.data("id", key);
                    el.find(".autoComplete-label").text(val);
                });
            }
            else {
                inputHidden.val(feedback.selection.value.id);
                $(el).parents('.autoComplete-component').find('.d-none').removeClass('d-none');
                $(el).parents('.autoComplete-component').find('.autoComplete-label').text(feedback.selection.value.label);
            }
            
            
        }
    });
}
const init = () => {
    $(".autoComplete").each((key,el)=>{
        initAutocomplete(el);
    });
}
$(()=>{
    init();
});
Panel.on("componentRendered", init);
Modal.on("componentRendered", init);
Load.on("componentRendered", init);
