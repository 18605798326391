import $ from "jquery";
import {Load} from "laya-js";

import router from "../handler/router";

export const handleWorklistLoad = (()=>{
    Load.on("componentRendered", (methods, deferred, DOMevent, that) => {
        if(methods && methods.hasOwnProperty("rawUrl") && methods.rawUrl.indexOf("worklist/true") != -1) {
            const id = $(DOMevent.currentTarget).attr("id");
            $(".card").removeClass("card-selected");
            $(DOMevent.currentTarget).parents(".card").addClass("card-selected");
            history.pushState(id, "", "#"+id);
            $("#worklistList").collapse("hide");           
        }
    });
    $(()=>{
        if(location.hash.length>1) {
            const el = $(location.hash);
            if(el.length) {
                el.trigger("click");
                $(".worklist-table").animate({
                    scrollTop: $(".worklist-table").scrollTop() + el.parents(".card").position().top
                }, 1000);
                $("#worklistList").collapse("hide");
            }
        }
        router.history.listen((lct, action) => {
            if(lct.hash.length && lct.hash.indexOf("orderDetail") != -1 && action == "POP") {
                const el = $(location.hash);
                if(el.length) {
                    el.trigger("click");
                    $(".worklist-table").animate({
                        scrollTop: $(".worklist-table").scrollTop() + el.parents(".card").position().top
                    }, 1000);
                    $("#worklistList").collapse("hide");
                }
            }
        });
    });
})();