import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
export const getSuggestionValue = suggestion => {
  return '' + suggestion.text;
};

const returnIcon = id => {
  switch (id) {
    case 'detail':
      return 'fas fa-search';
      break;
    case 'edit':
      return 'fas fa-edit';
      break;
    case 'create-order-process':
      return 'fas fa-calendar-plus';
      break;
    case 'next-order':
      return 'fas fa-plus';
      break;
  }
};
// Use your imagination to render suggestions.
export const renderSuggestion = (suggestion, { query }) => {
  const suggestionText = `${suggestion.text}`;
  const matches = match(suggestionText, query);
  const parts = parse(suggestionText, matches);
  return (
    <div className="react-autosuggest__suggestion-item d-flex w-100  align-items-center justify-content-between">
      <div className="result">
        {parts.map((part, index) => {
          const className = part.highlight
            ? 'react-autosuggest__suggestion-title highlight'
            : 'react-autosuggest__suggestion-title';

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
        <br />
        <small className="text-muted">
          {suggestion.data.customerIdentifier} {suggestion.data.email} {suggestion.data.phone}
        </small>
        {!_.isNull(suggestion.data.nextOrder) && <><br /><small className="text-muted">další objednávka: {suggestion.data.nextOrder}</small></>}
      </div>
      <div className="btn-group">
        {suggestion.data.actions.map((action, index) => {
          return (
            <a
              target="_href"
              title={action.label}
              data-toggle="tooltip"
              data-placement="left"
              data-container="body"
              key={index}
              className="btn btn-outline-primary btn-sm"
              href={action.url}>
              <i className={returnIcon(action.id)}></i>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export const init = Autocomplete => {
  $(() => {
    let el = $('.componentAutocomplete');
    if (el.length == 0) return;
    el.each((index, item) => {
      if ($(item).children().length > 0) return;
      let data = {
        placeholder: $(item).data('placeholder'),
        sibling: $(item).data('sibling'),
        id: $(item).data('id'),
        value: $(item).data('value'),
        options: $(item).data('options'),
        required: false,
        name: $(item).data('name'),
      };
      if ($(item).attr('required')) {
        data.required = true;
      }
      let element = React.createElement(Autocomplete, data);
      ReactDOM.render(element, $(item)[0]);
    });
  });
};
