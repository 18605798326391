import { Laya, Container } from 'laya-js';

/**
 * OpenInNewTab - opens link in new browser tab
 * @extends Laya.ActionAjax
 */

class DownloadBase64 extends Laya.ActionAjax {
  constructor() {
    super();
  }

  render(requestResult) {
    this.requestResult = requestResult;
    const link = requestResult.data;
    const type = 'text/plain';
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = link;

    // Use download attribute to set set desired file name
    a.setAttribute('download', requestResult.params[0]);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    //console.log(link);
    super.render();
  }
}

Container.register('DownloadBase64', DownloadBase64);

export default DownloadBase64;
