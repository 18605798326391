import $ from "jquery";
import { Container } from "laya-js";

/**
 * Handles login effects
 *
 * @class Login
 */
class Login {
  constructor() {
    $(()=>{
        if ($(".v-login").length == 0) return;
        this.form = null;
        let Save = Container.getClass("Save");
        Save.on("componentPrepared", this.fadeOutForm.bind(this));
        let notification = Container.get("Notification");
        notification.on("notificationCreated", this.handleResponse.bind(this));

        setTimeout(() => {
          this.show();
        }, 500);
    });

  }
  /**
   * Show the contact form
   *
   * @returns {Object} this
   * @memberof Login
   */
  show() {
    $(".v-loginForm").addClass("v-loginForm-active");
    return this;
  }
  /**
   * Fadeouts form and starts the loading circle
   *
   * @param {Object} methods
   * @returns {Object} this
   * @memberof Login
   */
  fadeOutForm(methods, deferred) {
    if(deferred.state() !== "rejected") {
      this.form = methods._actions[0].params[0];
      if ($("#" + this.form).length) {
        $("#" + this.form)
          .removeClass("v-loginForm-fadeIn")
          .addClass("v-loginForm-fadeOut");
        $(".loader-circle").css("opacity", 1);
      }
    }
    return this;
  }
  /**
   * Displays a check or cross icon, listens to Laya.Notification
   *
   * @param {String} notifId
   * @param {String} messages
   * @param {String} type
   * @returns {Object} this
   * @memberof Login
   */
  handleResponse(notifId, messages, type) {
    if (type === "success") {
      $(".loader-circle").css("opacity", 0);
      $(".loader-check").addClass("loader-check-active");
      $(".v-loginForm").removeClass("v-loginForm-error");
      $(".v-loginForm").addClass("v-loginForm-success");
    } else {
      if ($(".v-loginForm-error").length == 0) {
        $(".v-loginForm").addClass("v-loginForm-error");
      }
      $(".loader-circle").css("opacity", 0);
      $(".loader-cross").addClass("loader-cross-active");
      setTimeout(() => {
        $(".loader-cross").removeClass("loader-cross-active");
        $("#" + this.form)
          .removeClass("v-loginForm-fadeOut")
          .addClass("v-loginForm-fadeIn");
          $(".v-loginForm").removeClass("v-loginForm-error");
      }, 1500);
    }
    return this;
  }
}

export default new Login;
