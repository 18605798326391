import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DateRange } from 'react-date-range';
import cs from 'date-fns/locale/cs';

import {
  serviceGroupFetchData,
  serviceFetchData,
  serviceResetData,
  officesFetchData,
  employeesFetchData,
} from './actions';

import { Container } from 'laya-js';

const Router = Container.get('Router');

import store from './store';

import $ from 'jquery';

import Panel from '../../handler/panel';

class BookingWidget extends React.Component {
  constructor(props) {
    super(props);
    this.port = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleOfficesPart = this.handleOfficesPart.bind(this);
    this.handleEmployeesPart = this.handleEmployeesPart.bind(this);
    window.addEventListener('message', this.initPort.bind(this));
    this.onClick = this.onClick.bind(this);
    this.selectRef = React.createRef();
    this.selectRef.current = {
      serviceGroup: [],
      service: [],
    };
    let secondPartValue = 'empty';
    if (!_.isUndefined(props.serviceId)) {
      secondPartValue = props.serviceId;
    }
    this.state = {
      firstPartValue: 'empty',
      secondPartValue: secondPartValue,
      offices: [],
      employees: [],
      show: props.show,
      fixedShow: props.show,
    };
  }
  handleOfficesPart(event, row) {
    event.preventDefault();
    let res = [...this.state.offices];
    if (res.indexOf(row.value) != -1) {
      res.splice(res.indexOf(row.value), 1);
    } else {
      res.push(row.value);
    }
    this.setState({
      offices: res,
    });
    return this;
  }
  handleEmployeesPart(event, row) {
    event.preventDefault();
    let res = [];
    if (row != false) {
      res = [...this.state.employees];
      if (res.indexOf(row.value) != -1) {
        res.splice(res.indexOf(row.value), 1);
      } else {
        res.push(row.value);
      }
    }
    this.setState({
      employees: res,
    });
    return this;
  }
  handleChange(event, target = null, value = null) {
    const type = !_.isNull(target) ? target : event.currentTarget.getAttribute('data-target');
    const val = !_.isNull(value) ? value : event.currentTarget.getAttribute('data-value');
    switch (type) {
      case 'serviceGroup':
        this.setState({
          firstPartValue: val,
          secondPartValue: 'empty',
        });
        //if(event.target.value == "empty") {
        this.props.resetServiceData();
        //}
        let url = this.props.serviceUrl.replace('__SERVICE_GROUP_ID__', val);
        this.props.fetchSecondPartData(url);

        break;
      case 'service':
        this.setState({ secondPartValue: val });
        setTimeout(() => {
          $('#datesPart')[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 300);

        break;
      default:
        break;
    }
  }
  componentDidMount() {
    this.props.fetchFirstPartData(this.props.serviceGroupUrl);
    this.props.fetchFilterData(this.props.officesUrl, this.props.employeesUrl);
  }
  initPort(e) {
    this.port = e.ports[0];
  }
  renderFirstPart() {
    if (!_.isUndefined(this.props.serviceGroupUrl)) {
      return (
        <div className="order-part" id="serviceGroupPart">
          <h4 className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-primary">1</span> {window.TRANSLATIONS.chooseCategory}
          </h4>
          <div>{this.renderFirstPartOptions()}</div>
        </div>
      );
    } else {
      return null;
    }
  }
  renderFirstPartOptions() {
    let rows = [];
    if (_.isNull(this.props.serviceGroup)) {
      rows.push(
        <div key="00" className="alert alert-warning">
          <i className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}
        </div>,
      );
    } else {
      _.each(this.props.serviceGroup.data, (row, key) => {
        let cssClass = 'btn mr-1 mb-1 btn-outline-primary btn-sm';
        if (row.value == this.state.firstPartValue) {
          cssClass = 'btn mr-1 mb-1 btn-primary btn-sm';
        }
        rows.push(
          <button
            title={row.description}
            data-toggle="tooltip"
            data-target="serviceGroup"
            onClick={this.handleChange}
            className={cssClass}
            key={key}
            type="button"
            data-value={row.value}>
            <i className="fas fa-tags"></i> {row.name}
          </button>,
        );
      });
    }
    return rows;
  }
  renderSecondPart() {
    if (!_.isNull(this.props.service)) {
      return (
        <div className="order-part">
          <h4 id="servicePart" className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-primary">2</span> {window.TRANSLATIONS.chooseService}
          </h4>
          {this.renderSecondPartOptions()}
        </div>
      );
    } else if (_.isNull(this.props.service) && this.state.firstPartValue != 'empty') {
      return (
        <div className="order-part">
          <h4 id="servicePart" className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-primary">2</span> {window.TRANSLATIONS.chooseService}
          </h4>
          <div className="alert alert-warning">
            <i className="fas fa-spin fa-spinner"></i> {window.TRANSLATIONS.loading}
          </div>
        </div>
      );
    } else if (!_.isUndefined(this.props.serviceId)) {
      return (
        <div className="order-part">
          <h4 id="servicePart" className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.chosenCategory}
          </h4>
          <button className="btn btn-primary" type="button">
            <i className="fas fa-tag"></i> {this.props.serviceName}
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
  renderEmployeesPart() {
    if (
      !_.isNull(this.props.employees) &&
      this.props.employees.hasOwnProperty('data') &&
      _.size(this.props.employees.data) > 1
    ) {
      let rows = [];
      _.each(this.props.employees.data, (row, index) => {
        let cssClass = 'btn mr-1 btn-outline-primary';
        if (this.state.employees.indexOf(row.value) != -1) {
          cssClass = 'btn mr-1 btn-primary';
        }
        rows.push(
          <button type="button" onClick={ev => this.handleEmployeesPart(ev, row)} className={cssClass} key={index}>
            <i className="fas fa-user-md"></i> {row.name}
          </button>,
        );
      });
      let cssClassDefault = 'btn mr-1 btn-outline-primary';
      if (_.isEmpty(this.state.employees)) {
        cssClassDefault = 'btn mr-1 btn-primary';
      }
      return (
        <div className="order-part">
          <h4 id="employeesPart" className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.chooseEmployee}
          </h4>
          <button
            type="button"
            onClick={ev => this.handleEmployeesPart(ev, false)}
            className={cssClassDefault}
            key="00">
            <i className="fas fa-user-md"></i> {window.TRANSLATIONS.all}
          </button>
          {rows}
        </div>
      );
    } else {
      return null;
    }
  }
  renderOfficePartOptions() {
    let rows = [];
    _.each(this.props.offices.data, (row, index) => {
      let cssClass = 'btn mr-1 mb-1 btn-outline-primary';
      if (this.state.offices.indexOf(row.value) != -1) {
        cssClass = 'btn mr-1 mb-1 btn-primary';
      }
      rows.push(
        <button onClick={ev => this.handleOfficesPart(ev, row)} className={cssClass} type="button" key={index}>
          <i className="fas fa-notes-medical"></i> {row.name}
        </button>,
      );
    });
    return rows;
  }
  renderOfficesPart() {
    if (
      !_.isNull(this.props.offices) &&
      this.props.offices.hasOwnProperty('data') &&
      _.size(this.props.offices.data) > 1
    ) {
      return (
        <div className="order-part">
          <h4 id="servicePart" className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-dark">+</span> {window.TRANSLATIONS.offices}
          </h4>
          {this.renderOfficePartOptions()}
        </div>
      );
    } else {
      return null;
    }
  }
  renderSecondPartOptions() {
    let rows = [];
    if (this.props.service.data == false) {
      rows.push(
        <div key="00" className="alert alert-warning">
          {window.TRANSLATIONS.noData}
        </div>,
      );
    } else {
      _.each(this.props.service.data, (row, key) => {
        let cssClass = 'btn mr-1 mb-1 btn-outline-primary btn-sm';
        if (row.value == this.state.secondPartValue) {
          cssClass = 'btn mr-1 mb-1 btn-primary btn-sm';
        }
        let premium = <i className="fas fa-tag"></i>;
        if (row.isPremium == true) {
          premium = (
            <span className="badge badge-primary">
              <i className="fas fa-star"></i> premium
            </span>
          );
        }
        rows.push(
          <button
            title={row.description}
            data-toggle="tooltip"
            data-target="service"
            onClick={this.handleChange}
            className={cssClass}
            key={key}
            type="button"
            data-value={row.value}>
            {premium} {row.name}
          </button>,
        );
      });
    }

    return rows;
  }
  renderThirdPart() {
    if (this.state.firstPartValue != 'empty' && this.state.secondPartValue != 'empty') {
      let url = this.props.companyDetailUrl + '?serviceId=' + this.state.secondPartValue;
      if (this.state.offices.length > 0) {
        url += '&officeId=' + this.state.offices;
      }
      if (this.state.employees.length > 0) {
        url += '&employeeId=' + this.state.employees;
      }

      return (
        <div className="order-part">
          <h4 id="datesPart" className="card-title card-title-big mb-2">
            <span className="badge badge-pill badge-primary">3</span> {window.TRANSLATIONS.chooseDate}
          </h4>
          <a href={url} target="_blank" className="btn btn-primary btn-sm">
            Zobrazit volné termíny
          </a>
        </div>
      );
    } else {
      return null;
    }
  }
  onClick(ev) {
    ev.preventDefault();
    let show = _.clone(this.state.show);
    this.setState({
      show: !show,
    });
    if (this.port) {
      this.port.postMessage({ show: !show });
    }
    return this;
  }
  render() {
    let imgUrl = window.STATIC_URL + 'dist/images/logo-widget.svg?v4';
    if (LANG == 'sk_SK.UTF-8') {
      imgUrl = window.STATIC_URL + 'dist/images/logo-widget-sk.svg?v4';
    }
    return (
      <Provider store={store}>
        {this.state.show == false && this.state.fixedShow == false && (
          <div className="bookingWidgetButton">
            <a onClick={this.onClick} href="#">
              <img src={imgUrl} height="60px" />
            </a>
          </div>
        )}
        {this.state.show == true && (
          <div className="bookingWidget card" style={{ minHeight: '100%' }}>
            {this.state.fixedShow == false && (
              <div className="card-header bookingWidget-header pb-3 bg-gradient-info d-flex justify-content-between align-items-center">
                <h4 className="card-title text-white">
                  <i className="fas fa-calendar"></i> {window.TRANSLATIONS.createOrder}
                </h4>
                <button onClick={this.onClick} type="button" className="btn btn-outline-secondary btn-sm">
                  <i className="fas fa-times"></i>
                </button>
              </div>
            )}
            <div className="card-body bookingWidget-body">
              <form className="laya-form-tested form-groupped">
                {this.renderFirstPart()}
                {this.renderSecondPart()}
                {this.renderOfficesPart()}
                {this.renderEmployeesPart()}
                {this.renderThirdPart()}
              </form>
            </div>
          </div>
        )}
      </Provider>
    );
  }
}
BookingWidget.contextTypes = { store: PropTypes.object };
BookingWidget.propTypes = {
  serviceGroup: PropTypes.any,
  serviceGroupUrl: PropTypes.string,
  serviceUrl: PropTypes.string,
  fetchFirstPartData: PropTypes.func.isRequired,
  fetchSecondPartData: PropTypes.func.isRequired,
};
const mapStateToProps = store => {
  return {
    serviceGroup: store.serviceGroup,
    service: store.service,
    offices: store.offices,
    employees: store.employees,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchFirstPartData: url => {
      $.getJSON(url).done(result => {
        dispatch(serviceGroupFetchData(result));
        $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
      });
    },

    fetchSecondPartData: url => {
      $.getJSON(url).done(result => {
        dispatch(serviceFetchData(result));
        $('#servicePart')[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
      });
    },
    fetchFilterData(officesUrl, employeesUrl) {
      $.getJSON(officesUrl).done(result => {
        dispatch(officesFetchData(result));
      });
      $.getJSON(employeesUrl).done(result => {
        dispatch(employeesFetchData(result));
      });
    },
    resetServiceData: () => {
      dispatch(serviceResetData());
    },
  };
};
const BookingConnected = connect(mapStateToProps, mapDispatchToProps)(BookingWidget);

class BookingContainerWidget extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BookingConnected {...this.props} />
      </Provider>
    );
  }
}

//Initializer
export const initBooking = () => {
  if ($('#componentBookingWidget').length) {
    const serviceGroupUrl = $('#componentBookingWidget').data('service-group-url');
    const serviceUrl = $('#componentBookingWidget').data('service-url');
    const companyDetailUrl = $('#componentBookingWidget').data('company-detail-url');
    const officesUrl = $('#componentBookingWidget').data('offices-url');
    const employeesUrl = $('#componentBookingWidget').data('employees-url');
    const show = $('#componentBookingWidget').data('show');
    ReactDOM.render(
      React.createElement(BookingContainerWidget, {
        serviceGroupUrl: serviceGroupUrl,
        serviceUrl: serviceUrl,
        officesUrl: officesUrl,
        employeesUrl: employeesUrl,
        show: show,
        companyDetailUrl: companyDetailUrl,
      }),
      $('#componentBookingWidget')[0],
    );
  }
};
$(() => {
  initBooking();
});
