import $ from 'jquery';

const activateButton = el => {
  el.removeClass('btn-outline-primary').addClass('btn-success');
  el.find('.fas').removeClass('fa-plus').addClass('fa-check');
};

const deactivateButton = el => {
  el.removeClass('btn-success').addClass('btn-outline-primary');
  el.find('.fas').removeClass('fa-check').addClass('fa-plus');
};

export const AddPackage = (() => {
  $(() => {
    const input = $('[data-package-id="true"]');
    $(document).on('click', '[data-add-package="true"]', ev => {
      const el = $(ev.currentTarget);
      //Reset
      $('[data-add-package="true"]').each((index, item) => {
        const itemEl = $(item);

        if (itemEl.data('id') != el.data('id') && itemEl.hasClass('btn-success')) {
          deactivateButton(itemEl);
        }
      });
      if (!el.hasClass('btn-success')) {
        activateButton(el);
        console.log(input);
        input.val(el.data('id'));
      } else {
        deactivateButton(el);
        input.val('');
      }
    });
  });
})();
