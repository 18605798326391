import { Laya, Container } from 'laya-js';



/**
 * HideInput - Hides input and its group
 * @extends Laya.ActionAjax
 */

class HideInput extends Laya.ActionAjax {
	constructor() {
		super();
    }
	render(requestResult) {
		this.requestResult = requestResult;
		const input = $(`#${requestResult.params[0]}`);
		input.parents(".form-group").hide();
		super.render(requestResult);
	}
}

Container.register('HideInput', HideInput);

export default HideInput;
