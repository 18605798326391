import $ from 'jquery';
import Autosuggest from 'react-autosuggest';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Load, SaveAndLoad, Modal } from 'laya-js';
import Panel from '../../../handler/panel'

import { getSuggestionValue, renderSuggestion, init } from './helpers';

const SEARCH_URL = '/search-for-customer/__AUTOCOMPLETE_REPLACE__?laya-json-requested=1';

export default class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  componentWillMount() {
    this.timeout = null;
    this.data = [];
    console.log(this.props.options);
    if (this.props.options) {
        this.setState({
            value: this.props.options.map((r)=>r.firstName + ' ' + r.lastName).join(","),
            id: this.props.value,
            suggestions: [],
        });
    }
    else {
        this.setState({
            value: '' + this.props.value,
            id: 0,
            suggestions: [],
        });
    }

  }
  componentDidUpdate() {
    $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip('dispose');
    $("[data-toggle='tooltip'], [data-tooltip='true']").tooltip();
  }
  componentDidRender() {}
  shouldRenderSuggestions() {
    return true;
  }
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };
  onBlur = event => {
    /*
        let el = $("#"+this.props.id);
        el.tooltip('hide');
        */
  };
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(this.timeout);
    if (value.length < 3) {
      this.handleTooltip(false);
    } else {
      let options = [];
      this.handleTooltip(true);
      this.timeout = setTimeout(() => {
        fetch(SEARCH_URL.replace('__AUTOCOMPLETE_REPLACE__', value))
          .then(response => response.json())
          .then(res => {
            if (res.hasOwnProperty('data')) {
              _.each(res.data, (item, k) => {
                options.push({
                  text: item.firstName + ' ' + item.lastName,
                  value: item.id,
                  data: item,
                });
              });
              this.setState({ suggestions: options });
            }
          });
      }, 100);
    }
  };
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    /*
        if (this.props.sibling == "1") {
            $("[data-autocomplete-sibling='" + this.props.id + "']").val(suggestion.name);
        }
        if(!_.isUndefined(this.props.setInputValue) && this.props.setInputValue.length > 0) {
            if($("#"+this.props.setInputValue).length === 0) return;
            $("#"+this.props.setInputValue).val(suggestion.value);
        }
        if(!_.isUndefined(this.props.customCareUrl)) {
            if(suggestion.hasOwnProperty("sendToSubmission")) {
                $("[data-autocomplete-customcare-sibling='" + this.props.id + "']").val(suggestion.sendToSubmission);
            }
            else {
                $("[data-autocomplete-customcare-sibling='" + this.props.id + "']").val("yes");
            }
            
        }
        */
    if (this.props.id == 'none') {
      if (!$(event.target).is('i') && !$(event.target).is('a')) {
        event.preventDefault();
        const action = _.find(suggestion.data.actions, { id: 'detail' });
        window.open(action.url, '_blank').focus();
      }
    }
    if (method == 'enter') {
      event.preventDefault();
    }
    this.setState({
      id: suggestion.value,
    });
    this.handleTooltip(true);
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  handleTooltip(opened) {
    let el = $('#' + this.props.id + 'autocomplete');
    if (!opened) {
      el.tooltip({
        placement: 'top',
        trigger: 'manual',
        title: 'Pro vyhledání zadejte min. 3 znaky',
      });
      el.tooltip('show');
    } else {
      $(".tooltip").tooltip('hide');
    }
  }
  reset = () => {
    this.setState({
      value: '',
      id: 0,
      suggestions: [],
    });
  };
  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    let inputProps = {
      placeholder: this.props.placeholder,
      name: this.props.name + 'autocomplete',
      id: this.props.id + 'autocomplete',
      required: this.props.required,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    // Handle validation rules differently
    if (this.props.ruleDiagnosis) {
      inputProps['data-rule-diagnosis'] = 'true';
    }

    // Finally, render it!
    return (
      <div className="react-autosuggest__wrapper">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {this.state.id > 0 && (
          <button type="button" onClick={this.reset} className="react-autosuggest__close">
            <i className="fas fa-times"></i>
          </button>
        )}
        <input ref={this.input} type="hidden" id={this.props.id} name={this.props.name} value={this.state.id} />
      </div>
    );
  }
}
Autocomplete.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.object,
  placeholder: PropTypes.string,
};

init(Autocomplete);

Load.on('componentRendered', () => {
  init(Autocomplete);
});
SaveAndLoad.on('componentRendered', () => {
  init(Autocomplete);
});
Modal.on('componentRendered', () => {
  init(Autocomplete);
});
Panel.on('componentRendered', () => {
    init(Autocomplete);
});
