import { Laya, Container } from 'laya-js';
import "bootstrap";


/**
 * ShowsDiv - Shows div
 * @extends Laya.ActionAjax
 */

class SetText extends Laya.ActionAjax {
	constructor() {
		super();
    }
	render(requestResult) {
		this.requestResult = requestResult;
        const el = $(`#${requestResult.params[0]}`);
        el.html(requestResult.data);
		super.render(requestResult);
	}
}

Container.register('SetText', SetText);

export default SetText;
