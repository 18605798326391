import { Laya, Container } from 'laya-js';
import "bootstrap";


/**
 * ShowsDiv - Shows div
 * @extends Laya.ActionAjax
 */

class ShowDiv extends Laya.ActionAjax {
	constructor() {
        super();
    }
	render(requestResult) {
        this.requestResult = requestResult;
        const div = $(`#${requestResult.params[0]}`);
        if(div.hasClass("collapse")) {
            div.collapse("show");
        }
        else {
            div.show();
        }
		
		super.render(requestResult);
	}
}

Container.register('ShowDiv', ShowDiv);

export default ShowDiv;
