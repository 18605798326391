import { Laya, Container } from 'laya-js';



/**
 * CloseModal - Closes modal
 * @extends Laya.ActionAjax
 */

class CloseModal extends Laya.ActionSimple {
	constructor() {
		super();
		this.render();
    }
	render() {
		$("#laya-modal").modal("hide");
	}
}

Container.register('CloseModal', CloseModal);

export default CloseModal;
