import { Laya, Container } from 'laya-js';
import _ from "lodash";


/**
 * @extends Laya.ActionAjax
 */
const TPL = `
    <div class="v-panel <%=type%>">
        <div class="v-panel-container">
            <div class="v-panel-content" id="v-panel-data">
                <%=body%>
            </div>
        </div>
    </div>
`;
const EL = ".v-panel";

const WORKLIST_ID = (id) => `[data-worklist-id="${id}"]`;

class Panel extends Laya.ActionAjax {
	constructor(...args) {
        super(...args);
        $(document).on("click", ".v-panel [data-panel='close']", this.close.bind(this));
    }
    prepareData() {

        _.merge(this.methods._actions[0], {
            getTpl: true,
            setTplTarget: "panel"
        });

        super.prepareData();
    }
	render(requestResult) {
        const compiled = _.template(TPL)
        if($(EL).length) {
            $("#v-panel-data").html(requestResult.data);
        }
        else {
            let type = "fade";
            if(!_.isEmpty(requestResult.params) && requestResult.params[0] == "worklist") {
                type = "worklist";
            }
            $("body").append(compiled({
                "body":requestResult.data,
                "type":type,
            }));
            const $el = $(EL);
            
            $el.show();
            $el[0].offsetHeight;
            if(type == "worklist") {
                $el.addClass("show-worklist");
                $(WORKLIST_ID(requestResult.params[1])).addClass("worklist-item-active");
            }
            else {
                $el.addClass("show");
            }
            
            $("body").addClass("v-panel-open");
        }
        super.render(requestResult);
        /*
        $(EL).find(".v-panel-content").addClass("element-has-context")
        .data("laya-context-queue-id",this.requeueId)
        .data("laya-context-main-queue-id", this.mainQueueId)
        .data("laya-action-instance", this)
        .data("laya-action-tpl-target", "panel");
        */
    }
    close(ev) {
        
        if(ev) {
            ev.preventDefault();
        }
        this.destroy();
        const $el = $(EL);
        $el.removeClass("show");
        $("body").removeClass("v-panel-open");
        if(!_.isEmpty(this.params) && this.params[0] == "worklist") {
            $(WORKLIST_ID(this.params[1])).removeClass("worklist-item-active");
        }
        //$el[0].offsetHeight;
        $el.remove();
        
    }
    destroy() {
        super.destroy();
        //this.close();
    }
}
Laya.Container.register('Panel', Panel);


export class ClosePanel extends Laya.ActionSimple {
    constructor(...args) {
        super(...args);
        this.render();
    }
    render() {
        const $el = $(EL);
        $el.removeClass("show");
        $("body").removeClass("v-panel-open");
        $el.remove();
    }
}

Laya.Container.register('ClosePanel', ClosePanel);

export default Panel;

