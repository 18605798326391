import $ from "jquery";

const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
const isOpera = typeof window.opr !== "undefined";
const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
const isIOSChrome = winNav.userAgent.match("CriOS");
export const browser = (() => {
    if(typeof window.DONOTTESTBROWSER != "undefined") {
        return;
    }
    $(()=>{
        if (isIOSChrome) {
        // is Google Chrome on IOS
        } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
        ) {
           
        } else { 
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) == false){    
                const opt = {
                    type: "warning",
                        position: "fixed",
                        delay: 0,
                        placement: {
                            from: "bottom",
                            align: "left"
                        },
                        z_index: 9999
                };
                $.notify({
                    message: "Aplikace funguje nejlépe v prohlížeči <strong>Google Chrome.</strong> Stáhněte si jej, prosím."
                }, opt);
            }
        }
    });
})();