import $ from "jquery";
import _ from "lodash";
const TABLE_EL = '[data-reschedule-orders-table="true"]';
const INPUT_EL = '[data-reschedule-orders-input="true"]';

export const rescheduleOrders = (() => {
    $(document).on("change", TABLE_EL + " input[type='checkbox']", (ev)=>{
        const formData = $(TABLE_EL).parents("form").serializeArray();
        const ids = _.map(formData, (row, key) => row.value);
        $(INPUT_EL).val(ids);
    });
})();