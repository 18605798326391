import $ from 'jquery';
export default (() => {
  $(function() {
    $('td .btn-group a').tooltip();
    $(document).on("click", "[data-table='check-all']", (ev) => {
      const target = $(ev.currentTarget).data("table-target");
      $("table tbody input[type='checkbox']").prop('checked', function(_, checked) {
        return !checked;
      }).trigger("change");
      $("#" + target + " input[type='checkbox']").prop('checked', function(_, checked) {
        
        return !checked;
      }).trigger("change");
    });
  });
})();
